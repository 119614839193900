<template>
    <div class="select-container">
        <select :class="class" @change="$emit('change', $event)" :disabled="disabled"><slot></slot></select>
    </div>
</template>
<script>
    export default{
        name:'Select',
        props:['class', 'disabled'],
    }
</script>
<style lang="scss" scoped>
    .select-container{
        position: relative;
        &:after{
            content: '';
            touch-action:none;
            pointer-events:none;
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            border-top: 8px solid var(--bs-gray-600);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            top: 50%;

            transform: translateX(-50%) translateY(-50%);
            
        }
    }
</style>