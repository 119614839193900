<template>
    <header class="page-title text-start">
        <h1><slot></slot></h1>
        <hr />
    </header>
  </template>
  
  <script>
  export default {
    name: 'PageTitle',
    props: {
    }
  }
  </script>
  <style scoped lang="scss">
    .page-title{
      h1{
        font-size: 1.45rem;
      }
    }
    @media (max-width: 992px){
      .page-title{
        h1{
          font-size: 1.15rem;
        }
      }
    }
  </style>
  