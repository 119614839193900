<template>
    <PageContent>
        <PageTitle>Customers <Help /></PageTitle>
        <NotReady v-if="!ready" />
        <div v-else>
          <input type="text" v-model="searchText" class="form-control d-block  d-lg-none  mb-2" placeholder="Search">
          <ScrollingTable  :scroll="true" :style="`position:sticky; top: 0; left: 0`"  data-title="Customers" data-intro="List of customers in the system.">
            <!-- <input type="text" v-model.lazy="searchText" /> -->

            <template v-slot:table-header>
                <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                    <thead>
                      <tr data-title="Table Sorting" data-intro="Use the arrow to sort each column alphabetically or numerically.">
                        <th width="80" class="text-white" style="background-color: var(--bs-gray-700);">
                          <small>No</small>
                          <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='No_'" :class="`${(filter.orderBy=='No_'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='No_'?'':'rotate')}`">
                        </th>
                        <th width="200" class="text-white" style="background-color: var(--bs-gray-700);">
                          <small>Name</small>
                          <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Name'" :class="`${(filter.orderBy=='Name'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Name'?'':'rotate')}`">
                        </th>
                        <th width="200" class="text-white" style="background-color: var(--bs-gray-700);">
                          <small>City</small>
                          <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='City'" :class="`${(filter.orderBy=='City'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='City'?'':'rotate')}`">
                        </th>
                        <th width="160" class="text-white" style="background-color: var(--bs-gray-700);">
                          <small>Phone</small>
                          <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Phone No_'" :class="`${(filter.orderBy=='Phone No_'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Phone No_'?'':'rotate')}`">
                        </th>
                        <th width="200" class="text-white" style="background-color: var(--bs-gray-700);"></th>
                      </tr>
                      <tr data-title="Table Filters" data-intro="Use these inputs and toggles to filter the results of the table.">
                        <th style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['No_']"></th>
                        <th style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Name']"></th>
                        <th style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['City']"></th>
                        <th style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Phone No_']"></th>
                        <th style="background-color:var(--bs-gray-500)"></th>
                      </tr>
                    </thead>
                </table>
            </template>
            <template v-slot:table-body>
              <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                  <tbody>
                    <tr v-for="(row, i) in (_.filter(_.orderBy(customers, filter.orderBy, filter.order), r=>{
                      return  (r['No_'].toLowerCase().startsWith(search['No_'].toLowerCase().trim())&&
                              new RegExp(search['Name'].toLowerCase().trim(), 'gi').test(r['Name'].toLowerCase())&&
                              new RegExp(search['City'].toLowerCase().trim(), 'gi').test(r['City'].toLowerCase())&&
                              r['Phone No_'].toLowerCase().startsWith(search['Phone No_'].toLowerCase().trim()))
                    }))">
                      <td width="80" class="text-start">{{row['No_']}}</td>
                      <td width="200" class="p-0 ps-1 pe-1 text-start">{{row['Name']}}</td>
                      <td width="200" class="p-0 ps-1 pe-1 text-start">{{row['City']}}</td>
                      <td width="160" class="p-0 ps-1 pe-1 text-start">{{row['Phone No_']}}</td>
                      <td width="200" class="p-0 ps-1 pe-1 text-end">
                        <a href="#" @click.prevent="impersonate(row)" class="me-1 mt-1 mb-1 btn btn-sm btn-primary" v-if="i==0" data-title="View As" data-intro="Impersonate a customer.">View As</a>
                        <a href="#" @click.prevent="impersonate(row)" class="me-1 mt-1 mb-1 btn btn-sm btn-primary" v-else>View As</a>
                        <a :href="`/customers/${row['No_']}/users/`" class="me-1 mt-1 mb-1  btn btn-sm btn-primary" v-if="i==0" data-title="Customer Users" data-intro="View all users for this customer.">Users</a>
                        <a :href="`/customers/${row['No_']}/users/`" class="me-1 mt-1 mb-1  btn btn-sm btn-primary" v-else>Users</a>
                        <a :href="`/customers/${row['No_']}/edit`" class="me-1  mt-1 mb-1 btn btn-sm btn-secondary" v-if="i==0" data-title="Edit Customer" data-intro="Edit the customer">Edit</a>
                        <a :href="`/customers/${row['No_']}/edit`" class="me-1  mt-1 mb-1 btn btn-sm btn-secondary" v-else>Edit</a>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </template>
        </ScrollingTable>
        </div>
        
    </PageContent>
  </template>
  
  <script>
  // @ is an alias to /src
    import ColumnChooser from '@/components/Items/ColumnChooser.vue';
    import PriceCodes from '@/components/Items/PriceCodes.vue';
    import ItemListCodeArea from '@/components/Items/ItemListCodeArea.vue';
    import Modal from '@/components/Modal.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ScrollingTable from '@/components/Table/ScrollingTable.vue';
    import Help from '@/components/Help.vue';
    import VueSimpleAlert from 'vue3-simple-alert';
    import axios from 'axios'

  export default {
    name: 'CustomersView',
    components: {Modal, PageContent, PageTitle, NotReady, PriceCodes, ItemListCodeArea, ColumnChooser, ScrollingTable, Help},
    data(){
      return {
        _:_,
        allCustomers:[],
        rows:[],
        filter:{
            orderBy:'Name',
            order:'asc'
        },
        ready:false,
        searchText:'',
        search:{
            searchString:['true'],
            'No_':'',
            'City':'',
            'Name':'',
            'Phone No_':''
        },
        pagination:{
            search:'',
            pages:0,
            per_page:20,
            page:0,
        },
      }
    },
    async mounted(){
      let res = await axios.get(`${this.$store.state.api}/customers?`);
      this.allCustomers = res;
      this.ready = true;
    },
    computed:{
      customers:function(){
        return _.filter(_.orderBy(this.allCustomers,this.filter.orderBy, this.filter.order), c=>{
          let searchText = this.searchText.replace(/\./gi, '').toLowerCase();
          let name = c['Name'].replace(/\./gi, '').toLowerCase().trim();
          let city = c['City'].replace(/\./gi, '').toLowerCase().trim();
          let no = (c['No_']).toString().trim();
          return no.startsWith(searchText)||name.startsWith(searchText)||city.startsWith(searchText);
        });
      }
    },
    methods:{
      async impersonate(customer){
        let req, res;
        sessionStorage.clear();
        localStorage.removeItem('shipTos');
        localStorage.removeItem('shipTo');
        localStorage.removeItem('openInvoices');
        localStorage.removeItem('account_status');
        
        sessionStorage.customer = JSON.stringify(customer);
        this.$store.state.sessionStorage.customer = customer;

        window.location = '/dashboard/';
      }
    },
    
  }
  </script>
  <style lang="scss" scoped>
  .is-responsive{
    @media (max-width:  992px){
      [data-title="Customers"]{
        .table-body-parent{
          table.table{
            // border: 5px solid red !important
            display:block;
            > tbody{
              display:block;
              tr{
                display:block;
                margin-bottom: 1rem;
                td{
                  display:block;
                  width: 100%;
                  &:nth-of-type(1){
                    &:before{
                      content:  'Customer No: ';
                      font-weight:bold;
                    }
                  }
                  &:nth-of-type(2){
                    &:before{
                      content:  'Customer Name: ';
                      font-weight:bold;
                    }
                  }
                  &:nth-of-type(3){
                    &:before{
                      content:  'City: ';
                      font-weight:bold;
                    }
                  }
                  &:nth-of-type(4){
                    &:before{
                      content:  'Phone: ';
                      font-weight:bold;
                    }
                  }
                  &:last-child{
                    .btn{
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
    
  </style>