<template>
    <!-- <div class="text-center"><img alt="Kitchen Fresh Foods" src="@/assets/loading.gif" class="mw-100" /></div> -->
    <div class="text-center"><img alt="Kitchen Fresh Foods" src="@/assets/loading.gif" class="mw-100" /></div>

</template>
  
  <script>
  export default {
    name: 'NotReady',
    components:{},
    props: [],
    watch:{
    },
  }
  </script>
  <style lang="scss" scoped>
    img{
      // width: 240px;
      // height: 240px;
      object-fit:scale-down;
      object-position: 50% 50%;
      // animation: run 2s linear forwards infinite;
    }
    @keyframes run{
      0%{
        transform: translateX(-30vw);
        opacity: 0;
      }
      50%{
        transform: translateX(0vw);
        opacity: 1;
      }
      100%{
        transform: translateX(30vw);
        opacity: 0;
      }
    }
  </style>
  