<template>
    <PageContent class="customers-new min-vh-100  d-flexp-3">
        <NotReady v-if="!ready" />
        <div v-else class="ps-1 pe-1">
            <PageTitle>Location Maintenance<br>Location: {{location['Location No_']}} <Help /></PageTitle>
            <form @submit.prevent.stop="Save" class="text-start">
                <fieldset :disabled="disabled">
                    <div class="form-group mt-2 mb-2 text-start">
                        <label>Location</label>    
                        <input autocomplete="off" autosuggest="off" class="form-control" v-model="location['Location No_']" readonly>
                    </div>
                    <div class="form-group mt-2 mb-2" data-intro="Select location route number">
                        <label>Route</label>    
                        <select class="form-control" v-model="location['Route No_']" id="Route No_">
                            <option v-for="(route,indx) in routes" :key="indx">{{route}}</option>
                        </select>
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label>Inactive</label>    
                        <div class="form-check form-switch">
                            <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Status]" type="checkbox" id="Status" true-value="1" false-value="0" v-model="location['Status']">
                        </div>
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label>Name</label>    
                        <input autocomplete="off" autosuggest="off" class="form-control" name="location[Name]" id="Name" v-model="location['Name']" maxlength="30">
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label>Customer POS</label>    
                        <input autocomplete="off" autosuggest="off" class="form-control" name="location[POS]" id="POS" v-model="location['POS']" maxlength="20" @input="location['POS']=(location['POS']).toUpperCase()">
                    </div>
                    <div class="form-group mt-2 mb-2" data-intro="Specify primary item list code for location">
                        <label>Item List Code</label>    
                        <select class="form-control" name="location[Item List Code]" id="ItemListCode" v-model="location['Item List Code']">
                            <option v-for="(lc,indx) in itemListCodes" :key="indx" :value="lc['item list code']">{{lc['item list code']==''?'Master':lc['item list code']}}</option>
                        </select>
                    </div>
                    <div class="form-group mt-2 mb-2" v-if="$root.isKfEmployee()">
                        <label>One item per tray</label>    
                        <div class="form-check form-switch">
                            <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[OneItemPerTray]" type="checkbox" id="OneItemPerTray" true-value="1" false-value="0" v-model="location['OneItemPerTray']">
                        </div>
                    </div>
                    <div class="form-group mt-2 mb-2" >
                        <label>Notes</label>    
                        <textarea class="form-control" name="location[Notes]" id="Notes" v-model="location.Notes" maxlength="250"></textarea>
                    </div>
                    
                    <div class="row" v-if="$root.isKfEmployee()||$store.state.localStorage.user['Location Days']" data-intro="Select days location is allowed to place orders.">
                        <div class="col ">
                            <label>Sun:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" name="location[Sunday Menu]" type="checkbox" id="Sunday Menu" true-value="1" false-value="0" v-model="location['Sunday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Mon:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" name="location[Monday Menu]" type="checkbox" id="Monday Menu" true-value="1" false-value="0" v-model="location['Monday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Tue:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" name="location[Tuesday Menu]" type="checkbox" id="Tuesday Menu" true-value="1" false-value="0" v-model="location['Tuesday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Wed:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" name="location[Wednesday Menu]" type="checkbox" id="Wednesday Menu" true-value="1" false-value="0" v-model="location['Wednesday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Thu:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" name="location[Thursday Menu]" type="checkbox" id="Thursday Menu" true-value="1" false-value="0" v-model="location['Thursday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Fri:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" name="location[Friday Menu]" type="checkbox" id="Friday Menu" true-value="1" false-value="0" v-model="location['Friday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Sat:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" name="location[Saturday Menu]" type="checkbox" id="Saturday Menu" true-value="1" false-value="0" v-model="location['Saturday Menu']">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary me-2" type="submit">Update</button>
                        <a href="#" @click.prevent.stop="$router.back" class="btn btn-secondary">Back</a>
                    </div>
                </fieldset>
            </form>
        </div>
    </PageContent>
  </template>
  
  <script>
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Help from '@/components/Help.vue';
import axios from 'axios';
  export default {
    name: 'LocationsEdit',
    components: {PageContent, PageTitle, NotReady, Help},
    data(){
        return {
            _:_,
            ready:false,
            disabled:false,
            location:{},
            routes:[],
            success:'',
            error:'',
            itemListCodes:[],
        }
    },
    async mounted(){
        let req, res;
        res = await axios.get(`${this.$store.state.api}/custlocations/?lccustno=${this.$root.getCustomerNumber()}&show_inactive=${this.txtinactive}&id=${this.$route.params.location_id}&lclocationno=${this.$route.query.lclocationno}&lcrouteno=${this.$route.query.lcrouteno}`);
        this.location = res;
        res = await axios.get(`${this.$store.state.api}/custroutes/?lccustno=${this.$root.getCustomerNumber()}`);
        this.routes = res.map(r=>r['Route No_']);
        res = await axios.get(`${this.$store.state.api}/itemlistcombobox?lccustno=${this.$root.getCustomerNumber()}${this.$root.getCustomerValue('Master').trim().length>0?`&Master=${this.$root.getCustomerValue('Master')}`:``}`);
        this.itemListCodes = _.uniqBy(res, 'item list code');
        console.log(this.itemListCodesCopdes)
        this.ready = true;
    },
    methods:{
        async Save(){
            this.success = '';
            this.error = '';
            this.disabled=true;
            let req, res;
            let data = {
                'Customer No_':this.location['Customer No_'],
                'Location No_':this.location['Location No_'],
                'Route No_':this.location['Route No_'],
                'Name':this.location['Name'],
                'Status':this.location['Status'],
                'Notes':this.location['Notes'],
                'Monday Menu':this.location['Monday Menu'],
                'Tuesday Menu':this.location['Tuesday Menu'],
                'Wednesday Menu':this.location['Wednesday Menu'],
                'Thursday Menu':this.location['Thursday Menu'],
                'Friday Menu':this.location['Friday Menu'],
                'Saturday Menu':this.location['Saturday Menu'],
                'Sunday Menu':this.location['Sunday Menu'],
                'Item List Code':this.location['Item List Code'],
                'Menu Cycle Code':this.location['Menu Cycle Code'],
                'Label Customer Override':this.location['Label Customer Override'],
                'OneItemPerTray':this.location['OneItemPerTray'],
                'POS':this.location['POS'],
            };
            
            let formData = new FormData();
            formData.append('location', JSON.stringify(_.omit(data)));
            res = await axios.post(`${this.$store.state.api}/custlocations?lccustno=${this.$root.getCustomerNumber()}&location_no=${this.location['Location No_']}&id=${this.$route.params.location_id}`, formData);
            this.$router.replace({name:this.$route.name, params:this.$route.params, query:{...this.$route.query, ...{lcrouteno:this.location['Route No_']}}});
            this.disabled = false;
            window.scrollTo(0,0);
        },
    },
  }
  </script>
  