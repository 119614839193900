<template>
    <PageContent>
        <PageTitle>Waste Tracking</PageTitle>
        <div class="container pt-1 pb-1 mb-3 bg-light border">
            <div class="row">
                <div class="col text-center">
                    <a class="btn btn-primary btn-sm m-1" href="/waste/">Enter</a>
                    <a class="btn btn-primary btn-sm m-1" href="/waste/review">Review</a>
                    <button class="btn btn-primary btn-sm m-1"  @click.prevent.stop="Post">Post</button>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <table :class="`table table-bordered table-striped table-lg min-w-auto`"  :style="`table-layout:fixed;`">
                        <thead>
                            <tr>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);">Location</th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);">Item</th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);">Qty</th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);">Tran Date</th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);">DEL</th>
                            </tr>
                            
                        </thead>
                        <tfoot>
                            <tr>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);"><b>Total</b></th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);"></th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);">{{ _.sumBy(history, h=>parseInt(h.qty)) }}</th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);"></th>
                                <th class="text-white" style=" background-color: var(--bs-gray-700);"></th>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr v-for="(row, idx) in  _.orderBy(history, h=>h.sessionid&&h.date.date, ['desc','desc'])" :key="idx">
                                <td class="text-start">{{row['location']}}</td>
                                <td class="text-start">{{ row['item'] }}</td>
                                <td class="text-start">{{ parseInt(row.qty) }}</td>
                                <td class="text-start">{{ $root.moment(row.date.date).format('MM/DD/YYYY') }}</td>
                                <td class="text-start"><a href="#" @click.prevent.stop="Delete(row)" class="text-danger fa-solid fa-trash"></a></td>
                            </tr>
                        </tbody>
                    </table> 
                </div>
            </div>
        </div>
    </PageContent>
  </template>
  <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/FlaU1LzVojc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
  <script>
    import Modal from '@/components/Modal.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import VueSimpleAlert from 'vue3-simple-alert';
  import axios from 'axios';

  export default {
    name: 'Waste',
    components: {Modal, PageContent, PageTitle, NotReady},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            _:_,
            ready:false,
            disabled:false,
            history:[],
        }
    },
    async mounted(){
        this.history = await this.getHistory();

        
    },
    methods:{
        // async getHistory(){
        //     return new Promise(async resolve=>{
        //         let req, res;
        //         this.history = [];
        //         // req = await fetch(`${this.$store.state.api}/wastedisplay?&lccustno=${this.$root.getCustomerNumber()}&usermappedid=${this.$store.state.localStorage.user['Mapped Route No']}&lcsessionid=${localStorage.sessionId}&lntranstodisplay=`);
        //         res = await req.json();
        //         resolve(res);
        //     });
        // },
        Post:_.debounce(async function(){
            let res;
            let formData = new FormData();
            res = await axios.get(`${this.$store.state.api}/wastepost?lccustno=${this.$root.getCustomerNumber()}`);
            if(res.message!=undefined&&res.message=='success') this.history = [];
        }, 300, {leading:true, trailing:false}),
        async getHistory(){
            return new Promise(async resolve=>{
                let res;
                this.history = [];
                let formData = new FormData();
                // formData.append('waste', JSON.stringify({
                    // :-1
                // }));
                res = await axios.post(`${this.$store.state.api}/wastedisplay?lccustno=${this.$root.getCustomerNumber()}&BTNSUBMIT=Save&session_id=${localStorage.sessionId}`);
                resolve(res);
            });
        },
        Delete:_.debounce(async function(item){
            this.history = [];
            let req, res;
            let formData = new FormData();
            req = await axios.get(`${this.$store.state.api}/wastereview?lccustno=${this.$root.getCustomerNumber()}&lctransid=${item.tranid}`);
            this.history = await this.getHistory();
            if(this.history.length==0) this.history = [];
        }, 300, {leading:true, trailing:false}),
    },
  }
  </script>
  <style lang="scss" scoped>
    table{
        font-size: 0.875rem;
        tbody, thead{
            font-size: 0.875rem;
        }
    }
  </style>