import { _ } from 'core-js';
import { createApp,nextTick } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SideBar from '@/components/SideBar.vue'
import MobileHeader from '@/components/MobileHeader.vue'
import VueSimpleAlert from "vue3-simple-alert";
import Toast from 'vue3-toast-single'
import 'vue3-toast-single/dist/toast.css'
import VueScrollingTable from 'vue-scrolling-table'
import '/node_modules/vue-scrolling-table/dist/style.css'
import '@/assets/css/stylesheet.scss'
import axios from 'axios'
function isSafari() {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('android') === -1;
  }
$(window).on('popstate', function() {
    location.reload(true);
});
const sw = null;
if(navigator.serviceWorker){
    // navigator.serviceWorker.register();
}

// router.beforeEach((to, from) => {
//     console.log(to);
//     console.log(from);
//     // if (to.name === 'admin' && !isAdmin()) {
//     //   next({ name: 'home' }) // Redirect to home page if user is not admin
//     // } else {
//     //   next() // Proceed to the requested route
//     // }
// })
const app = createApp({
    ...App,
    ...{
        components: {
            SideBar,MobileHeader
        },
        watch:{
            
        },
        data(){
            return {
                window:window,
                _:_,
                hash:'',
                moment:moment,
                cdefaultmethod:"MainMenu",
                csqlcompany:'Kitchen Fresh Foods',
                client_browser:navigator.userAgent,
                lllocalrequest:(localStorage.lllocalrequest==undefined?false:true),
                ee:new EventEmitter(),
                sessionStorage:undefined,
                localStorage:undefined,
                hash:'',
            }
        },
        beforeMount(){
            axios.interceptors.request.use((config) => {
                // Do something before request is sent
                localStorage.busy = 'true';
                config.params = {};
                config.params.token = localStorage.getItem('token')+'';
                if(localStorage.getItem('shipTo')!=undefined&&localStorage.getItem('shipTo')!='') config.params['shipTo'] = localStorage.getItem('shipTo');
                else config.params['shipTo'] = '';
                if(this.Impersonating()) config['Impersonate'] = '';

                
                return config;
                
              }, function (error) {
                return Promise.reject(error);
            });
            
            axios.interceptors.response.use(async (response) => {
                if(response.data.message=='timeout'){
                    let keys = Object.keys(localStorage);
                    keys.forEach(key=>{
                        localStorage.removeItem(key);
                    });
                    sessionStorage.clear();
                    this.$store.state.localStorage = {};
                    this.$store.state.sessionStorage = {};
                    location.reload()
                    return;
                }
                let className = [];
                let message = '';
                let canReturn = true;
                localStorage.busy = 'false';
                if(response.statusText == 'OK'||response.status==200){
                    if(response.data.message!=undefined&&response.data.message=='error'){
                        className = ['alert', 'alert-danger', 'bg-danger'];
                        message = response.data.messageTxt;
                    }
                    else if(response.data.message!=undefined&&response.data.message=='success'){
                        className = ['alert', 'alert-success', 'bg-success'];
                        message = response.data.messageTxt;
                    }
                    if(response.data.message!=undefined&&response.data.message=='ServerDown'){
                        className = [];
                        message = '';
                        canReturn = true;
                        if(this.$route.name!='Error'&&this.$route.name!='login'){
                            let keys = Object.keys(localStorage);
                            keys.forEach(key=>{
                                localStorage.removeItem(key);
                            });
                            sessionStorage.clear();
                            this.$store.state.localStorage = {};
                            this.$store.state.sessionStorage = {};
                            this.$store.state.localStorage.user = null;
                            this.$store.state.localStorage.customer = null;
                            this.$store.state.sessionStorage.customer = null;
                            this.$router.replace('/error');
                            return Promise.reject('Server Down');
                        }
                        else if(this.$route.name!='Error'&&this.$route.name=='login'){
                            this.$router.replace('/error');
                        }
                    }
                    else if(response.data.message!=undefined&&response.data.message=='ServerBackUp'){
                        className = [];
                        message = '';
                        canReturn = true;

                        this.$router.replace('/error');
                    }
                    else if(response.data.message==undefined){
                    }
                }
                else{
                    console.log(response)
                    className = ['alert', 'alert-danger', 'bg-danger'];
                    // className = className.jko
                    message = 'There was a problem communicating with the server.';
                    canReturn = false;
                }
                if(className.length>0&&message!=''){
                    this.showToast(className, message);
                }
                if(canReturn) return response.data;

              },  (error) => {
                let className = ['alert', 'alert-danger', 'bg-danger'];
                let message = error.data.messageTxt;
                this.showToast(className, message);
                return Promise.reject(error);
              });
        },
        async mounted(){
            if(isSafari()){
                $('body').addClass('safari');
            }
            else{
                $('body').addClass('not-safari');
            }
            this.moment.tz.setDefault('America/Chicago');
            window.onpageshow = function(event) {
                if (event.persisted) {
                  location.reload();
                }
              };
            try{
                if(this.getCustomerNumber()){
                    this.getShipTos();
                    let req, res;
                    let open_invoices = await this.getInvoices();
                    await this.determinAccountStatus(open_invoices);
                }
            } catch(err){}
            // console.log(this.getCustomerValue('Bill-to Customer No_'))
            // console.log(this.getCustomerValue('Master'))
            $(document).on('focus', '.scrolling-table input[type="text"], .scrolling-table input[type="number"], .scrolling-table input[type="email"], .scrolling-table input[type="tel"]', e=>{
                // console.log(e.targ)
                e.preventDefault();
                let parent = $(document.activeElement).closest('.scrolling-table');
                let scrollTo = $(document.activeElement).offset().top - parent.offset().top + parent.scrollTop();
                parent.animate({scrollTop:scrollTo - parent.find('.table-header-parent').height()}, 0);
                console.log('test')
            //     if($('#main-app-container>.row>[class*=col]:nth-of-type(2)').length ){
            //         // if(window.innerWidth >= 768) return;
            //         // if(navigator.userAgentData.brands.some(brand=>brand.brand.toLowerCase()=='safari'));

            //         // let distanceToScroll = ($(e.target).offset().top - $('#main-app-container>.row>[class*=col]:nth-of-type(2)').offset().top + $('#main-app-container>.row>[class*=col]:nth-of-type(2)').scrollTop());
                
            //         // $('#main-app-container>.row>[class*=col]:nth-of-type(2)').animate(
            //         //     { scrollTop:distanceToScroll  - ($('#main-app-container>.row>[class*=col]:nth-of-type(2)').offset().top+65)},
            //         //     0
            //         // );
            //     }
            })
        },
        methods:{
            btoa(string){
                return btoa(string);
            },
            showToast(className,message){
                if('length' in className) className = className.join(' ');
                this.$wkToast(`${message}`, {
                    className: className,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    duration: 3000,
                    transition: 'slide-right',
                    closeable:true,
                });
            },
            isKfEmployee(){
                return (this.$store.state.localStorage.user.Admin && this.$store.state.localStorage.customer==undefined)
            },
           customer(){
                // check if you are impersonating, if not look at localStorage
                if(this.$store.state.sessionStorage.customer==undefined){
                    return this.$store.state.localStorage.customer;
                }
                // if you are, return session
                else{
                    return this.$store.state.sessionStorage.customer;
                }
           },
            Impersonating(){
                if(this.$store.state.sessionStorage.customer!=undefined) return true;
                else return false;
            },
            isJSON(str){
                try{
                    JSON.parse(str);
                }
                catch(e){
                    return false;
                }
                return true;
            },
            getMasterAccount(){
                if(this.$store.state.sessionStorage.customer==undefined){
                    if(this.$store.state.localStorage.customer!=undefined) return this.$store.state.localStorage.customer['Master'].trim();
                    else return '';
                }
                else{
                    if(this.$store.state.sessionStorage.customer!=undefined) return this.$store.state.sessionStorage.customer['Master'].trim();
                    else return '';
                    
                }
            },
            getCustomerNumber(){
                if(this.$store.state.sessionStorage.customer==undefined){
                    if(this.$store.state.localStorage.customer!=undefined) return this.$store.state.localStorage.customer['No_'];
                    else return this.$store.state.localStorage.user['Customer No'];
                }
                else{
                    return this.$store.state.sessionStorage.customer['No_'];
                }
            },
            getCustomerValue(key){
                if(this.$store.state.sessionStorage.customer==undefined){
                    if(this.$store.state.localStorage.customer!=undefined) return this.$store.state.localStorage.customer[key];
                    else if(this.$store.state.localStorage.user[key]!=undefined) return this.$store.state.localStorage.user[key];
                    else return '';
                }
                else{
                    return this.$store.state.sessionStorage.customer[key];
                }
            },
            async logOut(){
              let keys = Object.keys(localStorage);
              keys.forEach(key=>{
                localStorage.removeItem(key);
              });
              this.localStorage = {};
              sessionStorage.clear();
              this.$store.state.localStorage = {};
              this.$store.state.sessionStorage = {};
              this.$router.replace('/login');
              window.location.reload();
            },
            async getipaddress(){
                let res = await axios.get(`https://api.ipify.org?format=json`);
                return res.ip;
            },
            async Subscribe(){
                // let lcCustno = this.localStorage.user['Customer No'];
                let lcmobile = "";
                let lcError = "";
                let lcTemp = "";
                let lcName = "";
                let lcEmail = "";
                let lcSeed = (Math.random()*1000).toFixed(3).toString();
            },
            async getopeninvoices(){
                let req, res;
                res = await axios.get(`${this.$store.state.api}/getopeninvoices`);
                this.$store.state.open_invoices = res;
            },
            async getbalance(){
                let req, res;
                res = await axios.get(`${this.$store.state.api}/getbalance`);
            },
            
            async getInvoices(){
                return new Promise(async resolve=>{
                    // console.log(this.$route)
                    // if(this.$route.path!='/dashboard/') return;
                    if(localStorage.getItem('openInvoices')==undefined){
                        let req, res;
                        if(this.$root.getCustomerValue('Bill-to Customer No_').trim().length==0){
                            res = await axios.get(`${this.$store.state.api}/open_invoices?lccustno=${this.$root.getCustomerNumber()}`);
                        }
                        else{
                            res = await axios.get(`${this.$store.state.api}/open_invoices?lccustno=${this.$root.getCustomerValue('Bill-to Customer No_')}`);
                        }
                        // res = await axios.get(`${this.$store.state.api}/open_invoices?lccustno=${this.$root.getCustomerNumber()}`);
                        let open_invoices = await res;
                        localStorage.setItem('openInvoices', JSON.stringify(open_invoices));
                        resolve(open_invoices);
                    }
                    else{
                        let open_invoices = JSON.parse(localStorage.getItem('openInvoices'));
                        resolve(open_invoices);
                    }
                });
            },
            async determinAccountStatus(open_invoices){
                return new Promise(async resolve=>{
                    let req, res;
                    
                    let past_due_invoices = open_invoices.filter(invoice=>moment(invoice['due date']['date']).isBefore(moment()));
                    if(past_due_invoices.length){
                        this.$store.state.account_status = 'Past Due';
                        localStorage.account_status = this.$store.state.account_status;
                        let balance;
                        let custno = '';    
                        if(this.getCustomerValue('Bill-to Customer No_').trim()!='') custno = this.getCustomerValue('Bill-to Customer No_');
                        else custno = this.getCustomerValue('Customer No_');
                        if(localStorage.balance == undefined)  balance = await axios.get(`${this.$store.state.api}/balance?lccustno=${custno}`);
                        else{
                            balance = JSON.parse(localStorage.balance);
                            localStorage.balance = JSON.stringify(balance);
                        }
                        if(balance.Balance==null&&balance.credit_limit==null){
                            this.$store.state.account_status = '';
                            localStorage.account_status = this.$store.state.account_status;
                        }
                        else{
                            if(eval('+balance.Balance > +balance.credit_limit && +balance.credit_limit != 0')){
                                this.$store.state.account_status = 'Credit Hold (Over Limit)';
                                localStorage.account_status = this.$store.state.account_status;
                            }
                            else{
                                // this.$store.state.account_status = 'Good';
                                // localStorage.account_status = this.$store.state.account_status;
                            }
                        }
                    }
                    else{
                        let balance;
                        let custno = '';    
                        if(this.getCustomerValue('Bill-to Customer No_').trim()!='') custno = this.getCustomerValue('Bill-to Customer No_');
                        else custno = this.getCustomerValue('Customer No_');
                        console.log(custno)
                        if(localStorage.balance == undefined)  balance = await axios.get(`${this.$store.state.api}/balance?lccustno=${custno}`);
                        else balance = JSON.parse(localStorage.balance);

                        localStorage.balance = JSON.stringify(balance);
                        if(balance.Balance==null&&balance.credit_limit==null){
                            this.$store.state.account_status = '';
                            localStorage.account_status = this.$store.state.account_status;
                        }
                        else{
                            if(eval('+balance.Balance > +balance.credit_limit && +balance.credit_limit != 0')){
                                this.$store.state.account_status = 'Credit Hold (Over Limit)';
                                localStorage.account_status = this.$store.state.account_status;
                            }
                            else{
                                this.$store.state.account_status = 'Good';
                                localStorage.account_status = this.$store.state.account_status;
                            }
                        }
                    }
                        
                    if(localStorage.account_status==undefined){
                        localStorage.account_status = 'Good';
                    }
                    resolve();
                });
            },
            async getShipTos(){
                return new Promise(async resolve=>{
                    if(localStorage.shipTos==undefined){
                        let req, res;
                        res = await axios.get(`${this.$store.state.api}/shiptos?&lccustno=${this.$root.getCustomerNumber()}`);
                        this.$store.state.shipTos = res;
                        localStorage.setItem('shipTos', JSON.stringify(res));
                        resolve();
                    }
                    else{
                        this.$store.state.shipTos = JSON.parse(localStorage.shipTos);
                        resolve();
                    }
                });
            },

    
        },
    }
}).use(store).use(VueSimpleAlert).use(Toast, { verticalPosition: "bottom", duration: 1500 }).use(router).component(VueScrollingTable.name, VueScrollingTable);
(async ()=>{
    
    if(localStorage.user==undefined){
        router.replace('/login');
        app.mount('#app');
    }
    else{
        // console.log(router.currentRoute._value);
        await Sleep(10);
        if(router.currentRoute._value.path=='/') router.replace('/dashboard');
        app.mount('#app');
    }
})();

