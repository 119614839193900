<template>
    <div class="position-relative ms-auto me-auto scrolling-table">
      <div class="w-100 overflow-scroll" :style="scroll?'max-height: 650px; overflow:scroll;':'overflow:scroll;'">
        <div class="table-header-parent border-danger" :style="`display:inline-block; ${style}; z-index:40; min-width: 100%;`">
          <slot name="table-header"></slot>
        </div>
        <div :class="'table-body-parent '+tablebodyid" style="display:inline-block; min-width: 100%;">
          <slot name="table-body"></slot>
        </div>
        <div class="scrolling-table-body-parent" style="display:inline-block; min-width: 100%;">
          <slot name="scrolling-table-body"></slot>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'ScrollingTable',
  components:{},
  props: ['style','scroll','tablebodyid'],
  watch:{
  },
  data(){
     return {
     }
  },
  async mounted(){
    let height = $(this.$el).find('.table-header-parent').css('height');
    // $(this.$el).css('padding-top', height);
  },
  methods:{
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .scrolling-table{
    @media (max-width: 992px){
      > div{
        &.overflow-scroll{
          max-height: initial !important;
        }
      } 
    }
    
  }
  .overflow-scroll{
    transform: translateZ(0);
  }
  table.table{
    tr{
      td, th{
        font-size: 0.2rem !important;
      }
    }
  }
  .table-header-parent{
    font-size: 0.85rem;
    .btn{
      font-size: 0.85rem;
    }
    &:empty{
      display:none !important;
    }
  }
  .table-body-parent{
    font-size: 0.85rem;
    .btn{
      font-size: 0.85rem;
    }
    &:empty{
      display:none !important;
    }
  }
  .table-scrolling-body-parent{
    font-size: 0.85rem;
    .btn{
      font-size: 0.85rem;
    }
    &:empty{
      display:none !important;
    }
  }
  .table-footer-parent{
    font-size: 0.85rem;
    .btn{
      font-size: 0.85rem;
    }
    &:empty{
      display:none !important;
    }
  }
  .overflow-x-scroll{
    transform: translateZ(0);
    width: 100%;
    table{
      overflow: auto;
    }
    thead{
      transform: translateZ(0);
      transition: all 0s;
      tr{
        th{
          position: sticky;
        }
      }
    }
  }
  ::-webkit-resizer
    {
    /* 7. CSS Styles */
    }
  // *::-webkit-scrollbar {
  //   width:1rem;
  //   height: 1rem;
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  
  // }
  
  // *::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }
  
  // *::-webkit-scrollbar-thumb {
  //   background-color:#224092;
  //   border-radius: 8px;
  // }
  table{
    min-width: 860px;
    input{
      font-size: .875em !important;
    }
    *{
        font-size: .875em !important;
    }
    thead{
      background-color:var(--bs-danger);
        tr{
            th{
                svg{
                    &.rotate{
                        transform: rotate(180deg);
                    }
                    path{
                      fill:#fff !important;
                      cursor:pointer;
                    }
                }
            }
        }
    }
    tbody{
        tr{
            td:nth-of-type(1){
                svg{
                    cursor:pointer;
                }
            }
        }
    }
}
table{
    font-size: 14px;
}
</style>
