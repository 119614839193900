<template>
    <PageContent>
        <PageTitle>Item Maintenance
            <Help />
        </PageTitle>
        <NotReady v-if="!ready" />

        <div v-else>
            <div class="text-end mb-1">
                <div class="dropdown d-inline-block ms-1">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="printList"
                        data-bs-toggle="dropdown" aria-expanded="false"
                        data-intro="Print out your item list as a CSV or PDF.">
                        Print List
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="printList">
                        <li><a target="_blank" class="dropdown-item"
                                :href="`${$store.state.api}/pdfs/itemmaintlist.php?lccustno=${$root.getCustomerNumber()}&CUSTPRICEGRP=${$root.getCustomerValue('Customer Price Group')}&RTLPRICEGRP=${$root.getCustomerValue('Retail Price Group')}&txtItemListCode=${txtItemListCode}&token=${$store.state.localStorage.token}`">PDF</a>
                        </li>
                        <li><a target="_blank" class="dropdown-item"
                                :href="`${$store.state.api}/csvs/itemmaintlist.php?lccustno=${$root.getCustomerNumber()}&CUSTPRICEGRP=${$root.getCustomerValue('Customer Price Group')}&RTLPRICEGRP=${$root.getCustomerValue('Retail Price Group')}&txtItemListCode=${txtItemListCode}&token=${$store.state.localStorage.token}`">CSV</a>
                        </li>
                    </ul>
                </div>
            </div>
            <ItemListCodeArea data-intro="Create, delete, or edit Item List Codes." :txtItemListCode="txtItemListCode"
                :itemListCodes="itemListCodes" v-on:changeListCode="txtItemListCode = $event"
                v-on:deleteListCode="deleteListCode" v-on:createListCode="createListCode" />
            <!-- located in src/components/items/ -->
            <PriceCodes
                data-intro="When adding price codes to items, they will show up here. You can then input the value for these price codes."
                v-on:disabledPriceCodes="disabledPriceCodes = $event" :txtItemListCode="txtItemListCode"
                :disabledPriceCodes="disabledPriceCodes" :priceCodes="priceCodes" v-if="priceCodes.length"
                :classes="`${priceCodes.length > 24 ? 'cant-be-sticky' : 'can-be-sticky'}`" />
            <div class="text-start mt-2 mb-2">
                <ColumnChooser data-intro="Toggle the table columns to show or hide them." />
            </div>
            <fieldset>
                <ScrollingTable :scroll="true" :style="`position:sticky; top: 0; left: 0`"
                    data-intro="List of items to edit.">
                    <template v-slot:table-header>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                            <thead>
                                <tr :classes="``"
                                    data-intro="Use the arrow to sort each column alphabetically or numerically.">
                                    <th width="50" class="text-white" style="background-color: var(--bs-gray-700);">
                                    </th>
                                    <th width="80" v-if="$store.state.localStorage.user['ItmLst_Vis_Image']"
                                        class="text-white" style="background-color: var(--bs-gray-700);"></th>
                                    <th width="70" title="Item #" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Item #</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Item No_'"
                                            :class="`${(filter.orderBy == 'Item No_' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Item No_' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="140" v-if="$store.state.localStorage.user['ItmLst_Vis_Description']"
                                        title="Description" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Description</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Description from Item'"
                                            :class="`${(filter.orderBy == 'Description from Item' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Description from Item' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="100" v-if="$store.state.localStorage.user['ItmLst_Vis_PriceCode']"
                                        title="Price Code" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Price Code</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Price Code'"
                                            :class="`${(filter.orderBy == 'Price Code' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Price Code' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="80"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_MSRP'] && $store.state.localStorage.user['Admin']"
                                        title="MSRP" class="text-white" style="background-color: var(--bs-gray-700);">
                                        <span><small>MSRP</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Suggested Retail Price'"
                                            :class="`${(filter.orderBy == 'Suggested Retail Price' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Suggested Retail Price' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="100" v-if="$store.state.localStorage.user['ItmLst_Vis_UserDef']"
                                        title="User Defined" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>User Def</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'User Defined Code'"
                                            :class="`${(filter.orderBy == 'User Defined Code' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'User Defined Code' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="70" v-if="$store.state.localStorage.user['ItmLst_Vis_Healthy']"
                                        title="Healthy" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Healthy</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Healthy'"
                                            :class="`${(filter.orderBy == 'Healthy' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Healthy' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="120"  v-if="$store.state.localStorage.user['ItmLst_Vis_ShowOnMenu']" title="Show On Menu" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Show On Menu</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Show on Menu'"
                                            :class="`${(filter.orderBy == 'Show on Menu' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Show on Menu' ? '' : 'rotate')}`">

                                    </th>
                                    <th width="80"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Restricted'] && $root.isKfEmployee()"
                                        title="Restricted" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Restricted</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Restricted Menu Item'"
                                            :class="`${(filter.orderBy == 'Restricted Menu Item' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Restricted Menu Item' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="70" v-if="$store.state.localStorage.user['ItmLst_Vis_Lenten']"
                                        title="Lenten" class="text-white" style="background-color: var(--bs-gray-700);">
                                        <span><small>Lenten</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Lenten'"
                                            :class="`${(filter.orderBy == 'Lenten' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Lenten' ? '' : 'rotate')}`">

                                    </th>
                                    <th width="90" v-if="$store.state.localStorage.user['ItmLst_Vis_Vegetarian']"
                                        title="Vegetarian" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Vegetarian</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Vegetarian'"
                                            :class="`${(filter.orderBy == 'Vegetarian' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Vegetarian' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="70" v-if="$store.state.localStorage.user['ItmLst_Vis_Vegan']"
                                        title="Vegan" class="text-white" style="background-color: var(--bs-gray-700);">
                                        <span><small>Vegan</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Vegan'"
                                            :class="`${(filter.orderBy == 'Vegan' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Vegan' ? '' : 'rotate')}`">

                                    </th>
                                    <th width="80" v-if="$store.state.localStorage.user['ItmLst_Vis_GlutenFree']"
                                        title="Gluten Free" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Gluten Free</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Glutenfree'"
                                            :class="`${(filter.orderBy == 'Glutenfree' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Glutenfree' ? '' : 'rotate')}`">

                                    </th>
                                    <th width="80" v-if="$store.state.localStorage.user['ItmLst_Vis_Pork']" title="Pork"
                                        class="text-white" style="background-color: var(--bs-gray-700);">
                                        <span><small>No Pork</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Glutenfree'"
                                            :class="`${(filter.orderBy == 'Pork' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Pork' ? '' : 'rotate')}`">

                                    </th>
                                    <th width="100"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Authorized'] && $root.isKfEmployee()"
                                        title="Authorized" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Authorized</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Authorized Menu Item'"
                                            :class="`${(filter.orderBy == 'Authorized Menu Item' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Authorized Menu Item' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="100"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_BaseCost'] && $store.state.localStorage.user['Admin']"
                                        title="Base Cost" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>Base Cost</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Base Cost'"
                                            :class="`${(filter.orderBy == 'Base Cost' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Base Cost' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="80" title="UPC Code" class="text-white"
                                        style="background-color: var(--bs-gray-700);"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_ShelfLife']">
                                        <span><small>Shelf Life</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'Shelf Life (Days) from Item'"
                                            :class="`${(filter.orderBy == 'Shelf Life (Days) from Item' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'Shelf Life (Days) from Item' ? '' : 'rotate')}`">
                                    </th>
                                    <th width="120" v-if="$store.state.localStorage.user['ItmLst_Vis_UpcCode']"
                                        title="UPC Code" class="text-white"
                                        style="background-color: var(--bs-gray-700);">
                                        <span><small>UPC Code</small></span>
                                        <img src="@/assets/chevron-down.svg" width="15"
                                            @click="filter.order = (filter.order == 'asc' ? 'desc' : 'asc'); pagination.page = 0; filter.orderBy = 'UpcCode'"
                                            :class="`${(filter.orderBy == 'UpcCode' ? '' : 'opacity-50')} ${(filter.order == 'asc' && filter.orderBy == 'UpcCode' ? '' : 'rotate')}`">
                                    </th>
                                </tr>
                                <tr data-intro="Use these inputs and toggles to filter the results of the table.">
                                    <th style="background-color:var(--bs-gray-500);"></th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Image']"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th style="background-color:var(--bs-gray-500);">
                                        <input @keydown="nextField" tabindex="1" autocomplete="off" autosuggest="off"
                                            placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text"
                                            v-model="search['Item No_']">
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Description']"
                                        style="background-color:var(--bs-gray-500);">
                                        <input @keydown="nextField" tabindex="10" autocomplete="off" autosuggest="off"
                                            placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text"
                                            v-model="search['Description from Item']">
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_PriceCode']"
                                        style="background-color:var(--bs-gray-500);">
                                        <input @keydown="nextField" tabindex="20" autocomplete="off" autosuggest="off"
                                            placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text"
                                            v-model="search['Price Code']">
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_MSRP']"
                                        style="background-color:var(--bs-gray-500);">
                                        <input @keydown="nextField" tabindex="30" autocomplete="off" autosuggest="off"
                                            placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text"
                                            v-model="search['Suggested Retail Price']">
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_UserDef']"
                                        style="background-color:var(--bs-gray-500);">
                                        <input @keydown="nextField" tabindex="40" autocomplete="off" autosuggest="off"
                                            placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text"
                                            v-model="search['User Defined Code']">
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Healthy']"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th style="background-color:var(--bs-gray-500);" v-if="$store.state.localStorage.user['ItmLst_Vis_ShowOnMenu']">
                                        <div class="form-check form-switch">
                                            <!-- <input autocomplete="off" autosuggest="off" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" :true-value="1" :false-value="0"  @change="toggleAllShowOnMenu" tabindex="0"> -->
                                        </div>
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Restricted'] && $root.isKfEmployee()"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Lenten']"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Vegetarian']"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Vegan']"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_GlutenFree']"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Pork']"
                                        style="background-color:var(--bs-gray-500);"></th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_Authorized'] && $root.isKfEmployee()"
                                        style="background-color:var(--bs-gray-500);">
                                        <div class="form-check form-switch">
                                            <!-- <input autocomplete="off" autosuggest="off" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" :true-value="1" :false-value="0"  @change="toggleAllAuthorized" tabindex="0"> -->
                                        </div>
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_BaseCost'] && $store.state.localStorage.user['Admin']"
                                        style="background-color:var(--bs-gray-500);">
                                        <input @keydown="nextField" tabindex="50" autocomplete="off" autosuggest="off"
                                            placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text"
                                            v-model="search['Base Cost']">
                                    </th>
                                    <th style="background-color:var(--bs-gray-500);"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_ShelfLife']">
                                    </th>
                                    <th v-if="$store.state.localStorage.user['ItmLst_Vis_UpcCode']"
                                        style="background-color:var(--bs-gray-500);">
                                        <input @keydown="nextField" tabindex="60" autocomplete="off" autosuggest="off"
                                            placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text"
                                            v-model="search['UpcCode']">
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </template>
                    <template v-slot:table-body>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm"
                            data-title="items" style="">
                            <tbody>
                                <tr v-for="(item, i) in allItems" :key="i" v-show="(i => {
                                    return new RegExp(search['Description from Item'].toLowerCase().trim(), 'gi').test(i['Description from Item'].toLowerCase()) &&
                                        i['Item No_'].toString().toLowerCase().startsWith(search['Item No_'].toString().toLowerCase().trim()) &&
                                        i['Price Code'].toString().toLowerCase().startsWith(search['Price Code'].toString().toLowerCase().trim()) &&
                                        i['Suggested Retail Price'].toString().toLowerCase().startsWith(search['Suggested Retail Price'].toString().toLowerCase().trim()) &&
                                        i['User Defined Code'].toString().toLowerCase().startsWith(search['User Defined Code'].toString().toLowerCase().trim()) &&
                                        i['Base Cost'].toString().toLowerCase().startsWith(search['Base Cost'].toString().toLowerCase().trim()) &&
                                        i['UpcCode'].toString().toLowerCase().startsWith(search['UpcCode'].toString().toLowerCase().trim())
                                })(item)" :class="`
                                        ${$store.state.localStorage.user['ItmLst_Vis_Image'] ? 'has-item-image' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_Description'] ? 'has-item-description' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_PriceCode'] ? 'has-item-pricecode' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_MSRP'] ? 'has-item-msrp' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_UserDef'] ? 'has-item-userdef' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_Healthy'] ? 'has-item-healthy' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_Restricted'] ? 'has-item-restricted' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_Lenten'] ? 'has-item-lenten' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_Vegetarian'] ? 'has-item-vegetarian' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_Vegan'] ? 'has-item-vegan' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_GlutenFree'] ? 'has-item-glutenfree' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_Authorized'] ? 'has-item-authorized' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_BaseCost'] ? 'has-item-basecost' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_ShelfLife'] ? 'has-item-shelflife' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_UpcCode'] ? 'has-item-upccode' : ''}
                                        ${$store.state.localStorage.user['ItmLst_Vis_ShowOnMenu'] ? 'has-item-showonmenu' : ''}
                                    `">
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-plus" width="50">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                            style="width: 20px; cursor:pointer;"
                                            @click.prevent.stop="launchEditItem(item)">
                                            <path style="fill:var(--bs-success)"
                                                d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                                        </svg>

                                    </td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-image"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Image']" width="80">
                                        <a target="_blank"
                                            :href="`https://kitchenfreshfoods.com/product-images/${item['Item No_']}.jpg`"
                                            :download="`${item['Item No_']}.jpg`"><img
                                                :src="`https://kitchenfreshfoods.com/product-images/thumbnails/${item['Item No_']}.jpg`"
                                                width="70"></a>
                                    </td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-item-no" width="70">
                                        {{ item['Item No_'] }}</td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-description"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Description']" width="140"><a
                                            :href="`${$store.state.api}/pdfs/iteminfo.php?item_no=${item['Item No_']}&lccustno=${$root.getCustomerNumber()}&priceCode=${txtItemListCode}`"
                                            target="_blank">{{ item['Custom'] }}</a></td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-pricecode"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_PriceCode']" width="100">
                                        <input :tabindex="(100 + i)" :name="`price_code[${i}]`" autocomplete="off"
                                            autosuggest="off" @blur="saveItem($event, item)" type="text"
                                            class="form-control price-code-field p-0 ps-2 pe-1"
                                            v-model="item['Price Code']" maxlength="5" @keydown="nextField"
                                            @focus="$event.target.select()">
                                    </td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-msrp"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_MSRP']" width="80">
                                        {{ (item['Suggested Retail Price'] == '0.00' ? '' : item['Suggested Retail Price']) }}
                                    </td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-userdef"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_UserDef']" width="100">
                                        <input :tabindex="(700 + i)" autocomplete="off" autosuggest="off" maxlength="4"
                                            type="text" class="form-control p-0 ps-2 pe-1"
                                            v-model="item['User Defined Code']" @blur="saveItem($event, item)"
                                            :name="`user_def[${i}]`" @keydown="nextField">
                                    </td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-healthy"
                                        v-html="isHealthy(item)"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Healthy']" width="70"></td>
                                    <td v-if="$store.state.localStorage.user['ItmLst_Vis_ShowOnMenu']" class="p-0 ps-1 pe-1 align-middle text-center item-show-on-menu" width="120">
                                        <div class="form-check form-switch d-inline-block">
                                            <input autocomplete="off" autosuggest="off" :id="`item[${i}][Show on Menu]`"
                                                class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch"
                                                type="checkbox" v-model="item['Show on Menu']" :true-value="1"
                                                :false-value="0" tabindex="-1" @change="saveItem($event, item)">
                                        </div>
                                    </td>
                                    <td class="p-0 ps-1 pe-1 align-middle text-center item-restricted"
                                        v-html="isRestricted(item)"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Restricted'] && $root.isKfEmployee()"
                                        width="80"></td>
                                    <td width="70" class="p-0 ps-1 pe-1 align-middle text-center item-lenten"
                                        v-html="isLenten(item)"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Lenten']"></td>
                                    <td width="90" class="p-0 ps-1 pe-1 align-middle text-center item-vegetarian"
                                        v-html="isVegetarian(item)"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Vegetarian']"></td>
                                    <td width="70" class="p-0 ps-1 pe-1 align-middle text-center item-vegan"
                                        v-html="isVegan(item)"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Vegan']"></td>
                                    <td width="80" class="p-0 ps-1 pe-1 align-middle text-center item-gluten-free"
                                        v-html="isGlutenFree(item)"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_GlutenFree']"></td>
                                    <td width="80" class="p-0 ps-1 pe-1 align-middle text-center item-pork"
                                        v-html="isPork(item)" v-if="$store.state.localStorage.user['ItmLst_Vis_Pork']">
                                    </td>

                                    <td width="100" class="p-0 ps-1 pe-1 align-middle  text-center item-authorized"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_Authorized'] && $root.isKfEmployee()">
                                        <div class="form-check form-switch d-inline-block" v-if="item['Restricted Menu Item']">
                                            <input autocomplete="off" autosuggest="off"
                                                :id="`item[${i}][Authorized Menu Item]`"
                                                class="form-check-input  ps-4 pe-3 pt-3 pb-1" tabindex="-1"
                                                role="switch" type="checkbox" v-model="item['Authorized Menu Item']"
                                                :true-value="1" :false-value="0" @change="saveItem($event, item)">
                                        </div>
                                        <div class="text-muted" v-else>N/A</div>
                                    </td>
                                    
                                    <td width="100" class="p-0 ps-1 pe-1 align-middle text-center item-base-cost"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_BaseCost']">
                                        {{ parseFloat(item['Base Cost']).toFixed(2) }}</td>

                                    <td width="80" class="p-0 ps-1 pe-1 align-middle text-center item-shelf-life"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_ShelfLife']">
                                        {{ +item['Shelf Life (Days) from Item'] }}
                                        <!-- {{+item['Shelf Life (Days)'] == 0  || isNaN(+item['Shelf Life (Days)'])? +item['Shelf Life (Days) from Item']:+item['Shelf Life (Days)'] }} -->
                                    </td>

                                    <td width="120" class="p-0 ps-1 pe-1 align-middle text-center item-upc-code"
                                        v-if="$store.state.localStorage.user['ItmLst_Vis_UpcCode']">{{ item['UpcCode']
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </ScrollingTable>

            </fieldset>

        </div>
        <div class="modal fade modal-lg" :id="`editItem`">
            <div class="modal-dialog">
                <div class="modal-content" v-if="editItem">
                    <div class="modal-header">
                        <h4 class="modal-title w-100">Edit Item #{{ editItem['Item No_']}}<br><small>{{ editItem['Description from Item']}}</small></h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body text-start">
                        <form>
                            <fieldset>
                                <div class="row">
                                    <div class="col-lg-6"
                                        v-if="editItem['Customer No_'] != '' && $root.getMasterAccount().length == 0">
                                        <label>Customer Item #</label>
                                        <input autocomplete="off" autosuggest="off" type="text" class="form-control"
                                            v-model="editItem['Customer Item No_']" maxlength="10">
                                    </div>

                                    <div class="col-lg-6"
                                        v-if="editItem['Customer No_'] != '' && $root.getMasterAccount().length == 0">
                                        <label>Shelf Life (Days)</label>
                                        <input autocomplete="off" autosuggest="off" type="number" class="form-control"
                                            v-model="editItem['Shelf Life (Days)']" @blur="checkShelfLife(editItem)"
                                            maxlength="3">

                                    </div>
                                </div>
                                <div class="form-group"
                                    v-if="editItem['Customer No_'] != '' && $root.getMasterAccount().length == 0">
                                    <label>Short Description</label>
                                    <input autocomplete="off" autosuggest="off" type="text" class="form-control"
                                        v-model="editItem['Description Short']" maxlength="30">
                                </div>
                                <div class="form-group">
                                    <label>Menu Section</label>
                                    <input autocomplete="off" autosuggest="off" type="text" class="form-control"
                                        v-model="editItem['Menu Section']" maxlength="30">
                                </div>
                                <div class="form-group"
                                    v-if="editItem['Customer No_'] != '' && $root.getMasterAccount().length == 0">
                                    <label>Label Line 1</label>
                                    <input autocomplete="off" autosuggest="off" type="text" class="form-control"
                                        v-model="editItem['Label Line 1']" :readonly="editItem['Inspection Legend'] > 1"
                                        maxlength="50">
                                </div>
                                <div class="form-group"
                                    v-if="editItem['Customer No_'] != '' && $root.getMasterAccount().length == 0">
                                    <label>Label Line 2</label>
                                    <input autocomplete="off" autosuggest="off" type="text" class="form-control"
                                        v-model="editItem['Label Line 2']" :readonly="editItem['Inspection Legend'] > 1"
                                        maxlength="80">
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click.stop="restoreDefaults(editItem['Item No_'])">Close</button>
                        <button type="button" id="confirm" class="btn btn-primary" data-bs-dismiss="modal"
                            @click="saveItemModal($event, editItem)">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </PageContent>
</template>

<script>
import ColumnChooser from '@/components/Items/ColumnChooser.vue';
import PriceCodes from '@/components/Items/PriceCodes.vue';
import ItemListCodeArea from '@/components/Items/ItemListCodeArea.vue';
import Modal from '@/components/Modal.vue';
import PageContent from '@/components/PageContent.vue';
import NotReady from '@/components/NotReady.vue';
import PageTitle from '@/components/PageTitle.vue';
import Table from '@/components/Table/Table.vue';
import TableRow from '@/components/Table/TableRow.vue';
import TableHead from '@/components/Table/TableHead.vue';
import TableCell from '@/components/Table/TableCell.vue';
import VueSimpleAlert from 'vue3-simple-alert';
import ScrollingTable from '@/components/Table/ScrollingTable.vue';
import Help from '@/components/Help.vue';

import axios from 'axios';


export default {
    name: 'Items',
    components: { Modal, PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead, PriceCodes, ItemListCodeArea, ColumnChooser, ScrollingTable, Help },
    data() {
        return {
            // UserForm, CustomerForm, UserForm
            _: _,
            ready: false,
            disabled: false,
            disabledPriceCodes: true,
            rows: [],
            search: {
                searchString: ['true'],
                'Item No_': '',
                'Description from Item': '',
                'Price Code': '',
                'Suggested Retail Price': '',
                'User Defined Code': '',
                'Base Cost': '',
                'UpcCode': '',
            },
            filter: {
                orderBy: 'Item No_',
                order: 'asc'
            },
            pagination: {
                search: '',
                pages: 0,
                per_page: 20,
                page: 0,
            },
            allItems: [],
            allItemsunWatched: [],
            allItemsUnchanged: [],
            disabled: false,
            txtItemListCode: '',
            itemListCodes: [],
            ItmListChgMENULIST: [],
            ItmListChgNUMLISTS: [],
            priceCodes: [],
            requests: [],
            cachedItem: {},
            editItem: undefined,
            editModal: undefined
        }
    },

    watch: {
        // 'allItems':{
        //     deep:true,
        //     handler(newVal, oldVal){
        //         if(oldVal.length&&newVal.length){
        //             if(JSON.stringify(this.allItems)!=JSON.stringify(this.allItemsUnchanged)){

        //             }
        //         }
        //     },
        // },
        '$store.state.localStorage.user': {
            deep: true,
            handler: _.debounce(async function (newVal) {
                let req, res;
                $('#item-maint-column-chooser').find('fieldset').attr('disabled', 'disabled');
                let formData = new FormData();
                formData.append('user', JSON.stringify(this.$store.state.localStorage.user));

                res = await axios.post(`${this.$store.state.api}/update_item_columns?lccustno=${this.$root.getCustomerNumber()}`, formData);
                localStorage.setItem('user', JSON.stringify(newVal));
                $('#item-maint-column-chooser').find('fieldset').removeAttr('disabled');
            }, 1000, { leading: false, trailing: true }),
        },
        'txtItemListCode': async function (newVal, oldVal) {
            this.ready = false;
            this.allItems = [];
            this.allItemsUnchanged = [];
            await this.getItems();
            this.allItemsunWatched = this.allItems;
            this.ready = true;
            this.pagination.page = 0;
        },
        filter: {
            deep: true,
            handler(newVal, oldVal) {
                this.allItems = _.orderBy(this.allItems, [this.filter.orderBy], [this.filter.order]);
            }
        },
    },
    async mounted() {
        await this.getListCodes();
        if (!this.$root.getCustomerNumber()) {
            this.txtItemListCode = this.itemListCodes[0]['item list code'];
        }
        else {
            // this.txtItemListCode = this.itemListCodes[0]['item list code'];
            await this.getItems();
            this.ready = true;
        }

        this.pagination.page = 1;
        await Sleep(10);

        this.editModal = $(`#editItem`);

        this.editModal.modal({ backdrop: 'static', keyboard: false, show: true, });
        this.editModal.on('hidden.bs.modal', async (e) => {
            await Sleep(150);
            this.editItem = undefined;
        })
    },
    methods: {
        launchEditItem: async function (item) {
            this.editItem = { ...item };
            await Sleep(1);
            this.editModal.modal('show');
        },
        filteredItems() {

            // allItemsWatched
        },
        isVisible(menu, el) {
            const menuHeight = menu.offsetHeight;
            const menuScrollOffset = menu.scrollTop;

            const elemTop = el.offsetTop - menu.offsetTop;
            const elemBottom = elemTop + el.offsetHeight;
            return (elemTop >= menuScrollOffset &&
                elemBottom <= menuScrollOffset + menuHeight);
        },
        async restoreDefaults(itemno) {
            let currentItem = this.allItems.findIndex(i => i['Item No_'] == itemno);
            let oldItem = this.allItemsUnchanged.find(i => i['Item No_'] == itemno);
            // console.log(currentItem,oldItem)
            // console.log(this.allItems[currentItem])
            // await Sleep(10);
            this.allItems[currentItem] = { ...oldItem };
            this.rows[currentItem] = { ...oldItem };
        },
        async toggleAllShowOnMenu(e) {
            if (e.target.checked) {
                for (let i = 0; i < this.allItems.length; i++) {
                    let item = this.allItems[i];
                    this.allItems[i]['Show on Menu'] = 1;
                }
            }
            else {
                for (let i = 0; i < this.allItems.length; i++) {
                    let item = this.allItems[i];
                    this.allItems[i]['Show on Menu'] = 0;
                }
            }
            let allItems = this.allItems.map(i => ({ 'Authorized Menu Item': i['Authorized Menu Item'], 'Item No_': i['Item No_'], 'Show on Menu': i['Show on Menu'] }));
            let formData = new FormData();
            formData.append('items', JSON.stringify(allItems));
            let res = await axios.post(`${this.$store.state.api}/allItems?lccustno=${this.$root.getCustomerNumber()}&lcitemlistcode=${this.txtItemListCode}`, formData);
        },
        async toggleAllAuthorized(e) {
            if (e.target.checked) {
                for (let i = 0; i < this.allItems.length; i++) {
                    let item = this.allItems[i];
                    this.allItems[i]['Authorized Menu Item'] = 1;
                }
            }
            else {
                for (let i = 0; i < this.allItems.length; i++) {
                    let item = this.allItems[i];
                    this.allItems[i]['Authorized Menu Item'] = 0;
                }
            }
            let allItems = this.allItems.map(i => ({ 'Authorized Menu Item': i['Authorized Menu Item'], 'Item No_': i['Item No_'], 'Show on Menu': i['Show on Menu'] }));
            let formData = new FormData();
            formData.append('items', JSON.stringify(allItems));
            let res = await axios.post(`${this.$store.state.api}/allItems?lccustno=${this.$root.getCustomerNumber()}&lcitemlistcode=${this.txtItemListCode}`, formData);
        },
        async nextField(e) {
            let target = e.target;
            // let list = $('.scrolling-table [tabindex]:not([tabindex="-1"]):visible');
            let list = [];
            list = $('.scrolling-table thead tr:nth-of-type(2) th input:visible, .scrolling-table .price-code-field:visible, .scrolling-table td.item-userdef input:visible');
            list.sort((a, b) => a.getAttribute('tabindex') - b.getAttribute('tabindex'));
            let activeIndex = [...list].findIndex(input => input == document.activeElement);
            switch (e.key) {
                case 'ArrowDown':
                case 'Enter':
                case 'Tab':
                    if (activeIndex != -1 && list[activeIndex + 1]) {
                        e.preventDefault();
                        list[activeIndex + 1].focus();
                    }
                    break;
                case 'ArrowUp':
                    if (activeIndex != -1 && list[activeIndex - 1]) {
                        e.preventDefault();
                        list[activeIndex - 1].focus();
                    }
                    else {
                        // e.preventDefault();
                        // $(e.target).parents('.scrolling-table').prev().find('tr').last().find('th').last().find('input').focus();
                    }
                    break;
            }
        },
        async checkShelfLife(item) {
            if (item['Shelf Life (Days)'] > parseInt(item['Shelf Life (Days) from Item'])) {
                item['Shelf Life (Days)'] = parseInt(item['Shelf Life (Days) from Item']);
                this.$wkToast(`Shelf life (days) cannot be greater than ${parseInt(item['Shelf Life (Days) from Item'])}!`, {
                    className: ['alert', 'alert-danger', 'bg-danger'],
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    duration: 3000,
                    transition: 'slide-right'
                });
            }
        },
        async getListCodes() {
            return new Promise(async resolve => {
                this.$store.state.localStorage.user['item list hidden cols'] = this.$store.state.localStorage.user['item list hidden cols'] == '' ? this.default_hidden_columns : this.$store.state.localStorage.user['item list hidden cols'];

                let res = await axios.get(`${this.$store.state.api}/itemlistcodes?CUSTPRICEGRP=${this.$root.getCustomerValue('Customer Price Group')}&RTLPRICEGRP=${this.$root.getCustomerValue('Retail Price Group')}&txtItemListCode=${this.txtItemListCode}&lccustno=${this.$root.getCustomerNumber()}&orderby&order${this.$root.getCustomerValue('Master').trim().length > 0 ? `&Master=${this.$root.getCustomerValue('Master')}` : ``}`);
                this.itemListCodes = _.uniqBy(res, 'item list code');
                resolve();
            })
        },
        async getPriceCodes() {
            this.priceCodes = _.uniqBy(this.allItemsUnchanged.map(i => ({ 'Price Code': i['Price Code'], 'Price Code Retail Value': i['Price Code Retail Value'] })), 'Price Code');
            this.priceCodes = _.filter(this.priceCodes, pc => pc['Price Code'] != '');
            this.priceCodes = _.sortBy(this.priceCodes, pc => pc['Price Code']);
            this.priceCodes = _.map(this.priceCodes, pc => ({ 'Price Code': pc['Price Code'].trim(), 'Price Code Retail Value': parseFloat(pc['Price Code Retail Value']).toFixed(2) }));
            this.priceCodes = _.map(_.groupBy(this.priceCodes, obj => `${obj['Price Code']}`), group => group[0]);
            this.priceCodes = this.priceCodes.map(pc => ({ ...pc, ...{ 'Price Code Retail Value': parseFloat(pc['Price Code Retail Value']).toFixed(2) } }));
        },
        async getItems() {
            return new Promise(async resolve => {
                let res = await axios.get(`${this.$store.state.api}/itemmaint_new?&CUSTPRICEGRP=${this.$root.getCustomerValue('Customer Price Group')}&RTLPRICEGRP=${this.$root.getCustomerValue('Retail Price Group')}&txtItemListCode=${this.txtItemListCode}&lccustno=${this.$root.getCustomerNumber()}&orderby&order`);


                if (res.message != undefined && res.message == 'error') {
                    this.allItems = [];
                }
                else this.allItems = JSON.parse(JSON.stringify(_.sortBy(res.map(i => ({ ...i, ...{ 'Item No_': parseInt(i['Item No_']), 'Shelf Life (Days)': (+i['Shelf Life (Days)'] == 0 ? '' : +i['Shelf Life (Days)']), 'Shelf Life (Days) from Item': +i['Shelf Life (Days) from Item'], 'Suggested Retail Price': (i['Suggested Retail Price'].startsWith('.') ? '0.00' : Number(i['Suggested Retail Price']).toFixed(2)), 'Base Cost': (i['Base Cost'].startsWith('.') ? '0.00' : Number(i['Base Cost']).toFixed(2)) } })), 'Item No_')));
                this.allItems = this.allItems.map(i => ({ ...i, ...{ open: false } }));
                this.allItemsUnchanged = JSON.parse(JSON.stringify(this.allItems));
                this.getPriceCodes();
                this.disabledPriceCodes = false;
                resolve();
            })
        },
        filterInput(e) {
            this.pagination.page = 0;
        },
        async loadMore() {
            this.pagination.page += 1;
        },
        async generatePDF(item) {
            let req, res;
        },
        isVegan(item) {
            if (item.Vegan) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else return '';
        },
        isVegetarian(item) {
            if (item.Vegetarian) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else return '';
        },
        isGlutenFree(item) {
            if (item.Glutenfree) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else return '';
        },
        isPork(item) {
            if (item.Pork) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else return '';
        },
        isHealthy(item) {
            if (item.Healthy) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else return '';
        },
        isRestricted(item) {
            if (item['Restricted Menu Item']) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else return '';
        },
        isLenten(item) {
            if (item['Lenten']) return "<svg width='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><style>path{fill:var(--bs-green);}</style><path d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/></svg>";
            else return '';
        },
        saveItem: _.debounce(async function (e, item) {
            // return;
            let existingItem = this.allItemsUnchanged.find(i => i['Item No_'] == item['Item No_']);
            let existingItemIdx = this.allItemsUnchanged.findIndex(i => i['Item No_'] == item['Item No_']);
            let areTheyEqual = (_.isEqual(existingItem, item)); //check if they are equal
            if (areTheyEqual) return; //if they are equal, no change necessary
            this.disabledPriceCodes = true;
            let formData = new FormData();
            formData.append('item', JSON.stringify(item));
            let res = await axios.post(`${this.$store.state.api}/ItemMaintItemSave?lccustno=${this.$root.getCustomerNumber()}&ItemListCode=${this.txtItemListCode}&oldpcode=${existingItem['Price Code']}&newpcode=${item['Price Code']}&olduserdef=${existingItem['User Defined Code']}&newuserdef=${item['User Defined Code']}&citmno=${item['Customer Item No_']}&oldcitmno=${existingItem['Customer Item No_']}`, formData);
            this.disabledPriceCodes = false;
            this.allItemsUnchanged[existingItemIdx] = { ...item };

            this.getPriceCodes();

        }, 0, { leading: true, trailing: false }),
        saveItemModal: _.debounce(async function (e, item) {
            // return;
            let editItem = { ...this.editItem };
            let existingItem, existingItemIdx;
            existingItem = (this.allItems.find(i => i['Item No_'] == editItem['Item No_']));
            existingItemIdx = (this.allItems.findIndex(i => i['Item No_'] == editItem['Item No_']));
            let areTheyEqual = (_.isEqual(existingItem, editItem)); //check if they are equal
            if (areTheyEqual) return; //if they are equal, no change necessary

            let formData = new FormData();
            formData.append('item', JSON.stringify(editItem));
            let res = await axios.post(`${this.$store.state.api}/ItemMaintItemSave?lccustno=${this.$root.getCustomerNumber()}&ItemListCode=${this.txtItemListCode}&oldpcode=${existingItem['Price Code']}&newpcode=${item['Price Code']}&olduserdef=${existingItem['User Defined Code']}&newuserdef=${item['User Defined Code']}&citmno=${item['Customer Item No_']}&oldcitmno=${existingItem['Customer Item No_']}`, formData);
            this.disabledPriceCodes = false;
            this.allItems[existingItemIdx] = editItem;
            this.allItemsUnchanged[existingItemIdx] = editItem;


        }, 0, { leading: true, trailing: false }),
        async createListCode(result) {
            this.ready = false;
            let res = await axios.get(`${this.$store.state.api}/itemmaint_new?&CUSTPRICEGRP=${this.$root.getCustomerValue('Customer Price Group')}&RTLPRICEGRP=${this.$root.getCustomerValue('Retail Price Group')}&txtItemListCode=${result}&lccustno=${this.$root.getCustomerNumber()}&lcbuttonsubmit=Save`);
            this.txtItemListCode = result;
            await this.getListCodes();
        },
        async deleteListCode(value) {

            let result;
            try {
                result = await VueSimpleAlert.confirm(`Delete List  Code ${this.txtItemListCode}?`, `Delete List Code`);
            } catch (err) {
                result = false;
            }
            if (!result) return;
            try {
                result = await VueSimpleAlert.confirm(`Are you sure you want to delete this item list?`, `Delete List Code Confirm`);
            } catch (err) {

            }
            if (!result) return;
            this.ready = false;
            let res = await axios.get(`${this.$store.state.api}/itemmaint_new?&CUSTPRICEGRP=${this.$root.getCustomerValue('Customer Price Group')}&RTLPRICEGRP=${this.$root.getCustomerValue('Retail Price Group')}&txtItemListCode=${this.txtItemListCode}&lccustno=${this.$root.getCustomerNumber()}&lcbuttonsubmit=Delete List`);
            await this.getListCodes();

            this.ready = true;
            this.txtItemListCode = '';

        },

    },
    computed: {

    },
}
</script>
<style lang="scss" scoped></style>