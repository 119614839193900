<template>
    <PageContent>
        <PageTitle>Integration</PageTitle>
        <NotReady v-if="!ready" />
        <div v-else>
            <!-- <i class="fa-solid fa-check"></i> -->
            
            <div class="bg-light mt-3 mb-3 rounded p-1 ps-2 pe-2 border" id="token"><b>Token:</b> <span>{{ $store.state.localStorage.user.APIToken }}</span><i @click="copyToClipboard" class="fa-solid fa-clipboard" title="Copy token to clipboard"></i></div>
            <div class="bg-light mt-3 mb-3 rounded p-1 ps-2 pe-2 border" id="token"><b>Secret:</b> <span>{{ $store.state.localStorage.user.APISecret }}</span><i @click="copyToClipboard" class="fa-solid fa-clipboard" title="Copy token to clipboard"></i></div>

            <div class="bg-light mb-2 rounded p-1 ps-2 pe-2 border">
                <div><b>Host</b>:{{$store.state.api}}</div>
                <div><b>Authorization</b> <br>Type: Basic Auth <br>Value: &lt;Token&gt;|&lt;Secret&gt;</div>
            </div>
            <!-- <div class="bg-light mb-2 rounded p-1 ps-2 pe-2 border">
                <b>Days Key:</b><br>
                <i><u>M</u></i> - Monday<br>
                <i><u>T</u></i> - Tuesday<br>
                <i><u>W</u></i> - Wednesday<br>
                <i><u>R</u></i> - Thursday<br>
                <i><u>F</u></i> - Friday<br>
            </div> -->
            <h5 class="border-bottom pb-1 mt-4"><b>Enterprise Ordering</b></h5>

            <div>
                <h6><b>Path</b></h6>
                <pre class="bg-white p-1 border mb-2">{POST} /enterprise_order_request</pre>
                <h6 class="mt-3"><b>Parameters</b></h6>
                <div>date <i class="text-muted caption">(format: mm/dd/yyyy)</i></div>
                <div>used_on_day <i class="text-muted caption">(format: Monday|Tuesday|Wednesday|Thursday|Friday) (optional) - defaults to the date provided</i></div>
                <pre class="bg-white p-1 border mb-2">{{ $store.state.api }}/enterprise_order_request?date=01/01/{{ $root.moment().format('YYYY') }}&used_on_day=Monday</pre>
            </div>
            
            <div>
                <h6 class="mt-3"><b>&lt;body(string)&gt;</b></h6>
                <pre class="bg-white p-1 border mb-2">{
    &lt;Location Number(string)&gt;":[
        {"item":"&lt;Item Number(string)&gt;", "qty":&lt;Quantity(int)&gt;}
    ]
}</pre>
            </div>

            <h6 class="mt-3"><b>Formatted Example </b></h6>
            <pre class="bg-white p-1 border mb-2">{
    "000001":[
        {"item":"001", "qty":1},
        {"item":"002", "qty":2}
    ],
    "000002":[
        {"item":"001", "qty":1}
    ]
}</pre>


<div>
                <h6 class="mt-3"><b>&lt;output(object)&gt; </b></h6>
                <pre class="bg-white p-1 border mb-2">{
    "invalid_locations":&lt;array&gt;
    "locations_cant_order":&lt;object&gt;
    "locations_items_cant_order":&lt;object&gt;
    "locations_items_ordered":&lt;object&gt;
}</pre>
            </div>
<div>
    <h6><b>Code Examples</b></h6>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="curl-tab" data-bs-toggle="tab" data-bs-target="#curl" type="button" role="tab" aria-controls="curl" aria-selected="true">cURL</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link " id="javascript-tab" data-bs-toggle="tab" data-bs-target="#javascript" type="button" role="tab" aria-controls="javascript" aria-selected="true">JavaScript - Fetch</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="php-tab" data-bs-toggle="tab" data-bs-target="#php" type="button" role="tab" aria-controls="php" aria-selected="false">PHP - cURL</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="python-tab" data-bs-toggle="tab" data-bs-target="#python" type="button" role="tab" aria-controls="python" aria-selected="false">Python - http.client</button>
        </li>
    </ul>
    <div class="tab-content border-start border-end border-bottom" id="myTabContent">
        <div class="tab-pane fade show active" id="curl" role="tabpanel" aria-labelledby="curl-tab">
            <pre class="p-3 ps-4 pe-4">
curl --location '{{ $store.state.api }}/enterprise_order_request?date=05/01/{{$root.moment().format('yyyy')}}&lcusedonday=Monday' \
--header 'Authorization: Basic {{ $root.btoa($store.state.localStorage.user.APIToken+':'+$store.state.localStorage.user.APISecret) }}' \
--form 'locations="{\"000003\":[{\"item\":\"7022227\", \"qty\":2}]}"'
            </pre>
        </div>
        <div class="tab-pane fade show " id="javascript" role="tabpanel" aria-labelledby="javascript-tab">
            <pre class="p-3 ps-4 pe-4">
const myHeaders = new Headers();
myHeaders.append("Authorization", "Basic {{ $root.btoa($store.state.localStorage.user.APIToken+':'+$store.state.localStorage.user.APISecret) }});

const formdata = new FormData();
formdata.append("locations", "{\"000003\":[{\"item\":\"7022227\", \"qty\":2}]}");

const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
};

fetch("{{$store.state.api}}?date=01/01{{$root.moment().format('yyyy')}}&lcusedonday=Monday", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
            </pre>
            
        </div>
        <div class="tab-pane fade" id="php" role="tabpanel" aria-labelledby="php-tab">
            <pre class="p-3 ps-4 pe-4">
$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => '{{$store.state.api}}/enterprise_order_request?date=01/01{{$root.moment().format('yyyy')}}&lcusedonday=Monday',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS => array('locations' => '{"000003":[{"item":"7022227", "qty":2}]}'),
  CURLOPT_HTTPHEADER => array(
    'Authorization: Basic {{ $root.btoa($store.state.localStorage.user.APIToken+':'+$store.state.localStorage.user.APISecret) }}'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;
            </pre>
        </div>
        <div class="tab-pane fade" id="python" role="tabpanel" aria-labelledby="python-tab">
            <pre class="p-3 ps-4 pe-4">
import http.client
import mimetypes
from codecs import encode

conn = http.client.HTTPSConnection("{{ $store.state.url.replace('https://','') }}")
dataList = []
boundary = 'wL36Yn8afVp8Ag7AmP8qZ0SA4n1v9T'
dataList.append(encode('--' + boundary))
dataList.append(encode('Content-Disposition: form-data; name=locations;'))

dataList.append(encode('Content-Type: {}'.format('text/plain')))
dataList.append(encode(''))

dataList.append(encode("{\"000003\":[{\"item\":\"7022227\", \"qty\":2}]}"))
dataList.append(encode('--'+boundary+'--'))
dataList.append(encode(''))
body = b'\r\n'.join(dataList)
payload = body
headers = {
  'Authorization': 'Basic {{ $root.btoa($store.state.localStorage.user.APIToken+':'+$store.state.localStorage.user.APISecret) }}',
  'Content-type': 'multipart/form-data; boundary={}'.format(boundary)
}
conn.request("POST", "/api/enterprise_order_request?date=05/06/2024&lcusedonday=Thursday", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
            </pre>
        </div>
    </div>
</div>
        </div>
    </PageContent>
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';

  export default {
    name: 'Integration',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            token:localStorage.getItem('token')
        }
    },
    async mounted(){
        this.ready = true;
    },
    methods:{
        async copyToClipboard(e){
            await navigator.clipboard.writeText(e.target.previousSibling.innerText);
            e.target.classList.remove('fa-clipboard');
            e.target.classList.add('fa-check');
            e.target.classList.add('bg-success');
            e.target.classList.add('text-white');
            await Sleep(2000);
            e.target.classList.add('fa-clipboard');
            e.target.classList.remove('fa-check');
            e.target.classList.remove('bg-success');
            e.target.classList.remove('text-white');
        }
    },
  }
  </script>
  <style scoped lang="scss">
    i.tab{
        width: 15px;
        display:inline-block;
    }
    i.caption{
        font-size: 0.75rem;
        font-style:italic;
    }
    #token{
        white-space:nowrap;
        position:relative;
        b{
            vertical-align:middle;
            width: 70px;
            display:inline-block;
        };
        span{
            vertical-align:middle;
            width: 60% !important;
            white-space:nowrap;
            overflow:hidden;
            display:inline-block;
            text-overflow: ellipsis;
        }
        i{
            vertical-align:middle;
            cursor:pointer;
            display:inline-block;
            width: 30px;
            height: 100%;
            font-size: 1.4rem;
            text-align:center;
            display:inline-flex;
            align-items:center;
            justify-content:center;
            z-index: 9;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: var(--bs-gray-400);
            border: 1px solid var(--bs-gray-500);
        }
    }
  </style>