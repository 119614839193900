<template>
    <PageContent classes="dashboard">
        <PageTitle>Subscribe to Text Alerts</PageTitle>
        <NotReady v-if="!ready" />
        <div v-else>
            <p>Kitchen Fresh is pleased to begin offering a new communication option: Direct Texting/Texting Alerts. Our goal is to provide timely information to everyone on the list. As an example, we would have texted a short note about the snow storm this past winter, and our inability to ship that day. If you want to be included, enter your name, mobile phone number and email address below. You will get a confirmation text from us. Also, It is easy to drop out. Just text the word "stop" at any time. We value your business and understand that timely communication is an important part of our service. We hope you and your team will join our texting community. We would also like to encourage upline communications. If you text back, it is not broadcast to the group, but sent only to a few key managers here at Kitchen Fresh Foods.</p>
            <form @submit.prevent.stop="subscribe">
                <fieldset :disabled="disabled">
                    <div class="form-group">
                        <label>Full Name</label>
                        <input autocomplete="off" autosuggest="off" class="form-control" name="txtName" v-model="txtName" maxlength="70" placeholder="Ex: John Doe" />
                    </div>
                    <div class="form-group">
                        <label>Email Address</label>
                        <input autocomplete="off" autosuggest="off" class="form-control" name="txtName" v-model="txtEmail" maxlength="70" placeholder="Ex: john.doe@somewhere.com" />
                    </div>
                    <div class="form-group">
                        <label>Mobile Phone Number</label>
                        <input autocomplete="off" autosuggest="off" class="form-control" name="txtName" v-model="txtmobile" maxlength="12" placeholder="xxx-xxx-xxxx" />
                    </div>
                    <button class="btn btn-primary me-1" type="submit">Yes, Sign Me Up!</button>
                </fieldset>
            </form>
        </div>
    </PageContent>
  </template>
  
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  export default {
    name: 'SubscribeToTextAlerts',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            content:'',
            disabled:false,
            txtName:'',
            txtEmail:'',
            txtmobile:''
        }
    },
    async mounted(){
        this.ready = true;
    },
    methods:{
        async subscribe(){
            this.disabled = true;
            let res, req;
            let formData = new FormData();
            formData.append('txtName', this.txtName);
            formData.append('txtEmail', this.txtEmail);
            formData.append('txtmobile', this.txtmobile);

            req = await fetch(`${this.$store.state.api}/subscribe-to-text-alerts`, {
                method:'POST',
                headers:{},
                body:formData,
            })
            res = await req.json();
            let className = [];
            let message = '';
            if(res.hasOwnProperty('error')) {
                className = ['alert', 'alert-danger', 'bg-danger'];
                message = res.error;
                this.disabled = false;
            }
            if(res.hasOwnProperty('success')){
                className = ['alert', 'alert-success', 'bg-success'];
                message = res.success;
                this.txtName = '';
                this.txtEmail = '';
                this.txtmobile = '';
            }
            this.$root.showToast(className, message);

        },
    },
  }
  </script>
  <style lang="scss">
  </style>
  