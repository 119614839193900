<template>
  <div v-if="$store.state.is_dev" class="text-danger text-center fs-5 position-fixed end-0 bg-warning p-1 shadow" style="z-index:5;">*** User Testing System - TEST ONLY ***</div>
  <div class="bg-light">
    <MobileHeader  v-if="$store.state.localStorage.user" />
    <div class="container-fluid" id="main-app-container" v-if="$store.state.localStorage.user">
      <div class="row">
        <div class="col-xl-3 col-lg-4 d-none d-lg-block pb-2 pt-2">
          <SideBar />
        </div>
        <div :class="`col-xl-9 col-lg-8 pt-2 pb-2 ${$store.state.localStorage.user['Responsive View']==1?'is-responsive':''}`">
          <div :class="`bg-white shadow border rounded p-3 p-lg-5 pt-lg-4 min-vh-100`">
            <router-view :key="(new Date().getTime())" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light" v-else><router-view/></div>
  </div>
</template>

<style lang="scss">
  @import 'assets/css/block-library.scss';
  @import 'assets/css/coblocks.scss';
</style>
