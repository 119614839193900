<template>
    <PageContent>
        <PageTitle>Customer Reports</PageTitle>
        <div class="list-group" v-if="
            $store.state.localStorage.user.Reports==1||
            $store.state.localStorage.user.Impersonate==1
        ">
            <a href="/reports/menu" class="list-group-item bg-secondary text-white rounded-2 mt-1 mb-1">Menu</a>
            <a href="/reports/waste" class="list-group-item bg-secondary text-white rounded-2 mt-1 mb-1">Waste</a>
            <a href="/reports/order-summary-data-file" class="list-group-item bg-secondary text-white rounded-2 mt-1 mb-1">Order Summary Data File</a>
            <a href="/reports/purchase-history" class="list-group-item bg-secondary text-white rounded-2 mt-1 mb-1">Purchase History (Summarized)</a>
            <a href="/reports/open-invoices" class="list-group-item bg-secondary text-white rounded-2 mt-1 mb-1">Open Invoices</a>
        </div>
    </PageContent>
    
  </template>
  <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/FlaU1LzVojc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  export default {
    name: 'TrainingVideos',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            ready:false,
            disabled:false,
        }
    },
    async mounted(){
        this.ready = true;
    },
    methods:{
       
    },
  }
  </script>
  <style lang="scss" scoped>
    
  </style>