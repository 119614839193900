<template>
    <PageContent>
        <PageTitle>Waste Report</PageTitle>
        <div class="container">
            <div class="row">
                <div class=" col-xl-6 offset-xl-3 border p-3">
                    <form @submit.prevent.stop="buildReport">
                        <div class="form-group">
                            <label for="">Order Date</label>
                            <div class="row mt-1">
                                <div class="col-lg-6">
                                    <label>From</label>
                                    <input type="text" v-model="txtsdate" data-model="txtsdate" class="form-control datepicker" required readonly>
                                </div>
                                <div class="col-lg-6">
                                    <label for="">To</label>
                                    <input type="text" v-model="txtedate" data-model="txtedate" class="form-control datepicker" required readonly>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Type</label>
                            <select class="form-control" v-model="txtrpttype">
                                <option value="Company by Route">Company by Route</option>
                                <option value="Company by Price Point">Company by Price Point</option>
                                <option value="Company by Item">Company by Item</option>
                                <option value="ShipTo by Route">Ship-To by Route</option>
                                <option value="ShipTo by Price Point">Ship-To by Price Point</option>
                                <option value="ShipTo by Item">Ship-To by Item</option>
                                <option value="Route by Price Point">Route by Price Point</option>
                                <option value="Route by Item">Route by Item</option>
                                <option value="Route by Location">Route by Location</option>
                                <option value="Location by Item">Location by Item</option>
                                <option value="Location by Price Point">Location by Price Point</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">Sort</label>
                            <select class="form-control" v-model="txtsort">
                                <option value="Order Qty">Order Qty</option>
                                <option value="Waste Qty">Waste Qty</option>
                                <option value="Waste %">Waste %</option>
                                <option value="Descript">Description</option>
                                <option value="Detail">Detail</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">Display Cost</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" v-model="dispcost" name="dispcost" type="checkbox" id="dispcost" :true-value="'on'" :false-value="''">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">File Type</label>
                            <select class="form-control" v-model="txtfiletype">
                                <option value="PDF">PDF</option>
                                <option value="CSV">CSV</option>
                            </select>
                        </div>
                        <div class="mt-3">
                            <a class="btn btn-secondary" :href="`${$store.state.api}/pdfs/WasteReport-${txtfiletype}.php?&txtsdate=${txtsdate}&txtedate=${txtedate}&func=REPORT&txtinclude=${txtinclude}&txtrpttype=${txtrpttype}&txtsort=${txtsort}&dispcost=${dispcost}&token=${$store.state.localStorage.token}&lccustno=${$root.getCustomerNumber()}${(shipTo!=null?'&ShipTo='+shipTo:'')}`" target="_blank">Submit</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
    </PageContent>
    
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import axios from 'axios';
  export default {
    name: 'Waste',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            disabled:false,
            txtinclude:'No',
            txtsdate:this.$root.moment().format('MM/DD/YYYY'),
            txtedate:this.$root.moment().add(1,'weeks').format('MM/DD/YYYY'),
            txtrpttype:'Company by Route',
            txtsort:'Order Qty',
            dispcost:'on',
            txtfiletype:'PDF',
            shipTo:localStorage.getItem('shipTo')
        }
    },
    async mounted(){
        // if(localStorage.getItem('shipTo')!=undefined&&localStorage.getItem('shipTo')!='') config.params['shipTo'] = localStorage.getItem('shipTo');
                // else config.params['shipTo'] = '';
        this.ready = true;
        await Sleep(10);
        $('.datepicker').datepicker(
            {
                onSelect:async (date,target)=>{
                    let model = target.input[0].getAttribute('data-model');
                    this[model] = date;
                },
            }
        );
    },
    methods:{
       async buildReport(){
        // let formData = new FormData();
        // formData.append('txtinclude',this.txtinclude);
        // formData.append('txtsdate',this.txtsdate);
        // formData.append('txtedate',this.txtedate);
        // formData.append('txtrpttype',this.txtrpttype);
        // formData.append('txtsort',this.txtsort);
        // if(this.dispcost=='on') formData.append('dispcost',this.dispcost);
        // formData.append('txtfiletype',this.txtfiletype);
        // formData.append('submit','submit');

        // let res = await axios.post(`${this.$store.state.api}/reports/WasteReport?&func=REPORT&lccustno=${this.$root.getCustomerNumber()}`, formData);
        // console.log(res)



       }
    },
  }
  </script>
  <style lang="scss" scoped>
    
  </style>