<template>
      <div v-if="/height/.test(style)" :style="style" class="w-auto d-inline-block">
        <table
            :scroll-horizontal="true"
		        :scroll-vertical="true"
            :id="id"  :class="`table table-bordered table-striped table-sm ${classes}`" :style="`table-layout:fixed;`">
            <slot></slot>
        </table>
      </div>
      <div style="overflow-y:scroll; overflow-x:hidden;" v-else class="w-auto d-inline-block">
          <table
              :scroll-horizontal="true"
              :scroll-vertical="true"
              :id="id"  :class="`table table-bordered table-striped table-sm ${classes}`" :style="`table-layout:fixed;`">
              <slot></slot>
          </table>
      </div>
</template>
  
  <script>
    import VueScrollingTable from 'vue-scrolling-table'
    import TableHead from '@/components/Table/TableHead.vue';

  export default {
    name: 'Table',
    components:{VueScrollingTable},
    props: ['id','classes', 'style'],
    watch:{
    },
    data(){
       return {
       }
    },
    async mounted(){
      
      // // var scroll = new SmoothScroll('.'+[...this.$el.classList].join('.'));
      // var scroll = new SmoothScroll(this.$el);
      // console.log(scroll)
      // scroll.addEventListener('scrollStart', ()=>{
      //   console.log('test')
      // }, false);
      // let bs = new BetterScroll(`#${this.id}`, {
      //         movable: true,
      //         zoom: true
      //       })
      // var myScroll = new IScroll(this.$el);

    },
    methods:{
        scroll(e){
            
        }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .overflow-x-scroll{
      transform: translateZ(0);
      width: 100%;
      table{
        overflow: auto;
      }
      thead{
        transform: translateZ(0);
        transition: all 0s;
        tr{
          th{
            position: sticky;
          }
        }
      }
    }
    ::-webkit-resizer
      {
      /* 7. CSS Styles */
      }
    // *::-webkit-scrollbar {
    //   width:1rem;
    //   height: 1rem;
    //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    
    // }
    
    // *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // }
    
    // *::-webkit-scrollbar-thumb {
    //   background-color:#224092;
    //   border-radius: 8px;
    // }
    table{
      min-width: 860px;
      input{
        font-size: .875em !important;
      }
      *{
          font-size: .875em !important;
      }
      thead{
        background-color:var(--bs-danger);
          tr{
              th{
                  svg{
                      &.rotate{
                          transform: rotate(180deg);
                      }
                      path{
                        fill:#fff !important;
                        cursor:pointer;
                      }
                  }
              }
          }
      }
      tbody{
          tr{
              td:nth-of-type(1){
                  svg{
                      cursor:pointer;
                  }
              }
          }
      }
  }
  table{
      font-size: 14px;
  }
  </style>
  