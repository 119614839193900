<template>
    <PageContent>
        <PageTitle>Order Summary Data File</PageTitle>
        <div class="container">
            <div class="row">
                <div class=" col-xl-6 offset-xl-3 border p-3">
                    <form @submit.prevent.stop="">
                        <fieldset :disabled="busy">
                            <div class="form-group">
                                <label for="">Order Date</label>
                                <div class="row mt-1">
                                    <div class="col-lg-6">
                                        <label>From</label>
                                        <input type="text" name="txtsdate" v-model="txtsdate" class="form-control datepicker" required>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="">To</label>
                                        <input type="text" name="txtedate" v-model="txtedate" class="form-control datepicker" required>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Level</label>
                                <select size="1" name="level" v-model="level" class="form-control">
                                    <option value="TOTAL">Total</option>
                                    <option value="ROUTE">Route</option>
                                    <option value="LOCATION">Location</option>
                                    <option value="SHIP-TO, TOTAL">Ship-To, Total</option>
                                    <option value="SHIP-TO, ROUTE">Ship-To, Route</option>
                                    <option value="SHIP-TO, LOCATION">Ship-To, Location</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="">Detail</label>
                                <select v-model="detail" class="form-control">
                                    <option value="ITEM">Item</option>
                                    <option value="PRICE CODE">Price Code</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="">Code Display Source</label>
                                <select class="form-control" v-model="DisplayCode">
                                    <option value="LABEL CODE">LABEL CODE</option>
                                    <option value="MSRP      ">MSRP      </option>
                                    <option value="MSRP CODE ">MSRP CODE </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="">Show Customer Item Number</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" name="customerItemNumber" type="checkbox" id="customerItemNumber" true-value="ON" false-value="OFF" v-model="customerItemNumber">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Include Waste</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" name="WasteHist" type="checkbox" id="waste" true-value="ON" false-value="OFF" v-model="WasteHist">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Remove Dashes from UPC</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" name="UPCDashes" type="checkbox" id="UPCDashes" true-value="ON" false-value="OFF" v-model="UPCDashes">
                                </div>
                            </div>
                            <div class="form-group" v-if="filetype=='CSV'">
                                <label for="">Show Used On Day in CSV</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" name="usedon" type="checkbox" id="usedon" true-value="ON" false-value="OFF" v-model="usedon">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">File Type</label>
                                <select class="form-control" v-model="filetype">
                                    <option value="PDF">PDF</option>
                                    <option value="CSV">CSV</option>
                                    <option value="XLSX">XLSX</option>
                                </select>
                            </div>
                            <div class="mt-3">
                                <!-- <div v-if="$store.state.localStorage.user['User Code']=='CALEB'||$store.state.localStorage.user['User Code']=='BGB'||$store.state.localStorage.user['User Code']=='BGB'||$root.getCustomerNumber()=='101105'"> -->
                                    
                                    <a class="btn btn-secondary" :href="`${$store.state.url}/api/pdfs/OrderSummaryFile-${filetype}.php?&custno=${$root.getCustomerNumber()}&startdate=${txtsdate}&enddate=${txtedate}&level=${level}&detail=${detail}&waste=${WasteHist}&shipto=${shipTo}&displaycode=${DisplayCode}&token=${$store.state.localStorage.token}&UPCDashes=${UPCDashes}&usedon=${filetype=='CSV'?usedon:'OFF'}&customerItemNumber=${customerItemNumber}`" target="_blank">Submit</a>
                                <!-- </div>
                                <div v-else>
                                    <a class="btn btn-secondary" v-if="!busy" @click.prevent.stop="getFile" :href="`http://www.kffoods.com/wconnect/OrderSummaryFileGenerate.kf?&custno=${$root.getCustomerNumber()}&filetype=${filetype}&startdate=${txtsdate}&enddate=${txtedate}&level=${level.toUpperCase()}&detail=${detail.toUpperCase()}&waste=${WasteHist}&shipto${shipTo.length?'='+shipTo:''}&displaycode=${DisplayCode}&UPCDashes=${UPCDashes}&usedon=${filetype=='CSV'?usedon:'OFF'}`" target="_blank">Submit</a>
                                </div>
                                 -->

                                <!-- kffoods url start -->
                                <!-- <a class="btn btn-secondary" v-if="!busy" @click.prevent.stop="getFile" :href="`http://www.kffoods.com/wconnect/OrderSummaryFileGenerate.kf?&custno=${$root.getCustomerNumber()}&filetype=${filetype}&startdate=${txtsdate}&enddate=${txtedate}&level=${level.toUpperCase()}&detail=${detail.toUpperCase()}&waste=${WasteHist}&shipto${shipTo.length?'='+shipTo:''}&displaycode=${DisplayCode}&UPCDashes=${UPCDashes}&usedon=${filetype=='CSV'?usedon:'OFF'}`" target="_blank">Submit</a> -->
                                <!-- kffoods url end -->
                                <!-- <a class="btn btn-secondary" v-if="!busy" @click.prevent.stop="getDevFile" :href="'#'" target="_blank">Submit</a> -->
                                <div v-if="!fileUrl">
                                    
                                    <div v-if="busy" class="d-flex align-items-center justify-content-start">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        Please wait
                                    </div>

                                </div>
                                <div v-else class="text-center mt-3">
                                    <a :href="fileUrl" target="_blank" class="btn btn-primary" :download="$root.getCustomerNumber()+'OrderSummary.'+filetype.toLowerCase()">Download File</a>
                                </div>
                            </div>
                        </fieldset>
                        
                    </form>
                </div>
            </div>
        </div>
    </PageContent>
  </template>
  <script>
  import axios from 'axios';
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  export default {
    name: 'OrderSummaryDataFile',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            busy:false,
            ready:false,
            disabled:false,
            locations:[],
            LOCFILTER:'',
            WasteHist:'OFF',
            usedon: 'OFF',
            retry:1,
            txtsdate:this.$root.moment().startOf('week').format('MM/DD/YYYY'),
            txtedate:this.$root.moment().endOf('week').format('MM/DD/YYYY'),
            level:'TOTAL',
            detail:'PRICE CODE',
            filetype:'PDF',
            DisplayCode:'LABEL CODE',
            waste:'OFF',
            shipTo:localStorage.getItem('shipTo')!=null?localStorage.getItem('shipTo'):'',
            fileUrl:'',
            UPCDashes:'false',
            customerItemNumber:'OFF'
        }
    },
    async mounted(){
        this.ready = true;
        await Sleep(10);
        $( ".datepicker" ).datepicker({
            onSelect:async (date,target)=>{
                this[target.input.attr('name')] = date;
            }
        });

        
    },
    methods:{
       async getDevFile(){
        let res = await axios.get(`${this.$store.state.api}/`);
       },
       async getFile(e){
        this.fileUrl = '';
        this.busy = true;
        console.log(e.target.href)
        let res = await axios.get(`${this.$store.state.api}/ordersummary?url=${btoa(e.target.href)}`);
        this.fileUrl = (res.replace(new RegExp(/"/g),''))
        // console.log(e.target.href)
        // this.fileUrl = ($(res).attr('href').replace("javascript:void window.open('", '').replace("');",''))
        this.busy = false;
       }
    },
  }
  </script>
  <style lang="scss" scoped>
    
  </style>
