<template>
    <div class="container-fluid pt-1 pb-1 mb-1 bg-light border" v-if="$root.isKfEmployee()||$root.customer()['Web Allowed Item List Code Cnt']>0">
        <div class="row d-flex align-items-end">
            <div class="col-12 pt-1 pb-1">
                <div class="row mb-1">
                    <div class="col-lg-12 d-flex flex-wrap align-items-center">
                        <label for="" class="me-lg-2 w-100">Item List Code</label>
                        <select class="form-control d-inline-block pe-5 w-auto" v-model="txtItemListCode" name="txtItemListCode" id="txtItemListCode" @change="$emit('changeListCode', txtItemListCode)">
                            <!-- <option  :selected="txtItemListCode==''" value="" v-if="$root.isKfEmployee()">Master</option> -->
                            <option v-for="(code,i) in itemListCodes" :key="i" :value="code['item list code']">{{code['item list code'].trim()==''?'Master':code['item list code']}}</option>
                        </select>
                        <div class="d-inline-block ms-2 p-1">
                            <!-- <button class="btn btn-danger btn-sm" @click="$emit('deleteListCode')" v-if="(txtItemListCode)!=''&&$root.getCustomerNumber()!=''">Delete List Code</button> -->
                            <button class="btn btn-danger btn-sm" @click="$emit('deleteListCode')" v-if="$root.isKfEmployee()">Delete List Code</button>
                        </div>
                        <div class="d-inline-block me-2 p-1 border-lg-start border-dark">
                            <button v-if="($root.isKfEmployee()&&!($store.state.localStorage.customer||$store.state.sessionStorage.customer))||($store.state.localStorage.customer||$store.state.sessionStorage.customer)&&((($root.customer()['Web Allowed Item List Code Cnt'])-parseInt(itemListCodes.length-1)) > 0)" class="btn btn-secondary btn-sm" @click="createListCode">New List Name</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </template>
  <script>
  import VueSimpleAlert from 'vue3-simple-alert';
  export default {
    name: 'ItemListCodeArea',
    props: ['txtItemListCode','itemListCodes'],
    components:{},
    watch:{
    },
    computed:{
    },
    data(){
        return {
        }
    },
    async mounted(){
    },
    methods:{
        async createListCode(){
            let result;
            try{
                result = await VueSimpleAlert.prompt(`New List Name`, '','Create New List Name', '', {
                    confirmButtonText:'CREATE',
                    inputAttributes:{
                        maxlength:20,
                        autocomplete:'off',
                        autocorrect:'off',
                        autocapitalize:'off'
                    }
                });
            } catch(err){ result = false; }
            if(result==false||result.length==0) return;
            result = result.toUpperCase();
            this.$emit('createListCode', result);

        },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    
  </style>
  