<template>
    <PageContent>
        <NotReady v-if="!ready" />
        <div v-else>
            <PageTitle>Edit Customer No: {{$route.params['customer_no']}}<br>
            Customer Name: {{customer['Name']}}</PageTitle>
            <div v-if="!ready">
                <div class="text-center"><img alt="Kitchen Fresh Foods" src="@/assets/loading.gif" class="mw-100" /></div>
            </div>
            <div class="container text-start" v-else>
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col">
                        <form @submit.prevent.stop="Save">
                            <fieldset :disabled="disabled">
                                <div class="form-group">
                                    <label for="">Orders Allowed</label>
                                    <select class="form-control" v-model="customer['Web Orders Allowed Count']">
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option v-if="customer['Web Orders Allowed Count'] > 3">{{ customer['Web Orders Allowed Count'] }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="">Item Lists</label>
                                    <input autocomplete="off" autosuggest="off" type="number" class="form-control" v-model="customer['Web Allowed Item List Code Cnt']">
                                </div>
                                <div class="form-group mb-3">
                                    <label for="">POS Enabled</label>
                                    <div class="form-check form-switch">
                                        <input autocomplete="off" autosuggest="off" class="form-check-input ps-4 pe-4 pt-3 pb-2" type="checkbox" true-value="1" false-value="0" v-model="customer['POS Enabled']">
                                    </div>
                                </div>
                                
                                <div class="form-group mt-2">
                                    <button class="btn btn-primary me-2">Update</button>
                                    <a href="#" @click.prevent.stop="$router.back" class="btn btn-secondary">Back</a>
                                </div>

                            </fieldset>
                        </form>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </PageContent>
  </template>
  
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';

  export default {
    name: 'CustomersEdit',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            disabled:false,
            customer:{},
            error:'',
            success:''
        }
    },
    async mounted(){
        let res = await axios.get(`${this.$store.state.api}/customer?lccustno=${this.$route.params.customer_no}`)
        this.customer = res;
        this.ready = true;
    },
    methods:{
        async Save(){
            this.error = '';
            this.success = '';
            this.disabled = true;

            let formData = new FormData();
            formData.append(`customer`, JSON.stringify(this.customer));
            let res = await axios.post(`${this.$store.state.api}/customer?lccustno=${this.$route.params.customer_no}`, formData);
            this.disabled = false;
        },
    },
  }
  </script>
  