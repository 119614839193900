<template>
    <PageContent>
        <div v-if="!ready">
          <div class="text-center"><img alt="Kitchen Fresh Foods" src="@/assets/loading.gif" class="mw-100" /></div>
        </div>
        <div v-else>
            <PageTitle>Select Order for location: Rt/Loc: {{$route.query['route']}}-{{$route.params['location_no']}}-{{atob(($route.query['name']))}}</PageTitle>
            <div class="text-end mb-2">
                <a href="" @click.prevent.stop="$router.back" class="btn btn-secondary">Back</a>
            </div>
            <calendar-view
                :show-date="showDate"
                :items="items"
                @click-date="onClickDate"
                @click-item="onClickItem"

                class="theme-default">
                <template #header="{ headerProps }">
                    <calendar-view-header
                        :header-props="headerProps"
                        @input="setShowDate" />
                </template>
                <template #dayContent="{day}">
                    <div class=" w-100" v-html="listEvents(day)"></div>
                </template>
            </calendar-view>
        </div>
        <teleport to="body"  v-if="showDayView">
            <div class="modal show" id="myModal" style="display:block;">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Orders for <span>{{$root.moment(showDayView.startDate.startDate).format('MM/DD/YYYY')}}</span></h4>
                        <button type="button" class="btn-close"  @click.prevent.stop="showDayView=false"></button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="text-center p-3" v-if="!(showDayView.orders)" style="line-height:1.2">
                            <div class="mb-3 text-start">
                                <small>
                                    <b>Fetching Orders </b><br>
                                </small>
                            </div>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mb-3">
                                <small>
                                    <b>Orders for: </b><br><span>{{$root.moment(showDayView.startDate.startDate).format('MM/DD/YYYY')}}</span><br>
                                    Location Number: {{$route.params['location_no']}}<br>
                                    Route Number: {{$route.query['route']}}<br>
                                </small>
                            </div>
                            <Table v-if="showDayView.orders.length" classes="min-w-auto">
                                <thead>
                                    <TableRow>
                                <!-- /orders/:route_no/:location_no/:document_no/edit -->
                                        <th title="Doc #" class="text-white" style=" background-color: var(--bs-gray-700);">
                                            <span>Doc #</span>
                                        </th>
                                        <th title="Used On Day" class="text-white" style=" background-color: var(--bs-gray-700);">
                                            <span>Used On Day</span>
                                        </th>
                                        <th title="Qty" class="text-white" style=" background-color: var(--bs-gray-700);">
                                            <span>Qty</span>
                                        </th>
                                        <th title="" class="text-white text-end" style=" width:75px; background-color: var(--bs-gray-700);">
                                            <span></span>
                                        </th>
                                    </TableRow>
                                </thead>
                                <tbody>
                                    <TableRow v-for="(order,j) in showDayView.orders" :key="j">
                                        <td class="align-middle"><a class="text-dark text-decoration-none" :href="`/orders/${$route.query['route']}/${$route.params['location_no']}/${order['document no_']}/edit`">{{order['document no_']}}</a></td>
                                        <td class="align-middle"><a class="text-dark text-decoration-none" :href="`/orders/${$route.query['route']}/${$route.params['location_no']}/${order['document no_']}/edit`">{{order['used on day']}}</a></td>
                                        <td class="align-middle"><a class="text-dark text-decoration-none" :href="`/orders/${$route.query['route']}/${$route.params['location_no']}/${order['document no_']}/edit`">{{order['qty']}}</a></td>
                                        <td class="text-end align-middle">
                                            <a :href="`/orders/${$route.query['route']}/${$route.params['location_no']}/${order['document no_']}/edit?FirstDate=${$root.moment(order['document date']['date']).format('MM/DD/YYYY')}`" class="btn btn-secondary btn-sm">{{ showDayView.startDate.originalItem.past_add_cut == 'false'?'Edit':'View' }}</a>
                                        </td>
                                    </TableRow> 
                                </tbody>
                            </Table>
                            <div class="mt-2 text-center"
                                v-if="showDayView.startDate.originalItem.past_add_cut=='false'"
                            >
                                <div v-if="$root.getCustomerValue('Web Orders Allowed Count')>showDayView.orders.length">
                                    <a class="btn btn-secondary btn-sm" :href="`/orders/${$route.query['route']}/${$route.params['location_no']}/create/edit?FirstDate=${$root.moment(showDayView.startDate.startDate).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create New Order</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent.stop="showDayView=false">Close</button>
                    </div>

                    </div>
                </div>
            </div>
            <div class="modal-backdrop show"></div>
        </teleport>
    </PageContent>
  </template>
  
  <script>
    import {reactive} from 'vue';
    import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import TableHead from '@/components/Table/TableHead.vue';
    import TableCell from '@/components/Table/TableCell.vue';
    import "../../../node_modules/vue-simple-calendar/dist/style.css"
    import "../../../node_modules/vue-simple-calendar/static/css/default.css"
import axios from 'axios';
    export default {
        name: 'SelectOrder',
        components: {PageTitle, CalendarView, CalendarViewHeader,PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead},
        watch:{
            'showDate':async function(newDate){
                await this.getMonthOrders();
                this.ready = true;
            },
            showDayView:async function(newVal, oldVal){
                if(newVal != false && !newVal.orders){
                    let res = await axios.get(`${this.$store.state.api}/ordersbyday?lccustno=${this.$root.getCustomerNumber()}&location_no=${this.$route.params['location_no']}&orderdate=${this.$root.moment(newVal.startDate.startDate).format('MM/DD/YYYY')}`);
                    newVal.orders = res;
                }
            }
        },
        data(){
            return {
                // UserForm, CustomerForm, UserForm
                showDayView:false,
                selectionStart:null,
                selectionEnd:null,
                message:'',
                ready:false,
                disabled:false,
                error:'',
                txtinactive:'',
                orders:[],
                showDate: new Date(this.$root.moment().startOf('month').subtract(0, 'month')),
                month:{},
                items:[]
            }
        },
        async mounted(){
            if(this.$route.query.month!=undefined){
                this.showDate = new Date(this.$root.moment(this.$route.query.month).startOf('month').subtract(0, 'month'));
            }
            else{
                // this.showDate = new Date(this.$root.moment().startOf('month').subtract(0, 'month')); 
                // router.push({ query: { userId } });
                
                await this.getMonthOrders();
            }
            this.ready = true;
            this.$router.replace({query:{...this.$route.query, ...{month:this.$root.moment(this.showDate).format('MM/DD/YYYY')}}});


            $(document).on('click', 'a.order-day', e=>{
                this.checkAccountStatus(e);
                e.preventDefault();
            })
        },
        methods:{
            onClickDate(date){
                if($($('.day-lightbox-trigger').get(0)).is(":visible")){
                    let day_no = this.$root.moment(date).date();
                    let day_orders  = this.month[`day_${day_no}_count`];
                    let past_add_cut = this.month['past_add_cut'][`day_${day_no}`];
                    if(day_orders.length||past_add_cut=='false') this.showDayView = {orders:undefined, startDate:{originalItem:{past_add_cut:past_add_cut},startDate:date}};
                }
            },
            listEvents(day){
                let output = ``;
                let mobileOverlayTrigger = ``;
                if(this.$root.moment(this.$route.query.month).month()==this.$root.moment(day).month()){
                    let day_no = this.$root.moment(day).date();

                    let past_add_cut = this.month['past_add_cut'][`day_${day_no}`];
                    mobileOverlayTrigger += `<div data-day_no="${day_no}" class="d-lg-none position-absolute bg-white start-0 top-0 w-100 h-100 text-center text-decoration-none text-black d-flex  align-items-center justify-content-center day-lightbox-trigger">`;
                    if(this.checkAccountStatusBool()){
                        if(this.month[`day_${day_no}_count`].length < +this.$root.getCustomerValue('Web Orders Allowed Count') && past_add_cut=='false'){
                            
                            output += `<a href="/orders/${this.$route.query.route}/${this.$route.params.location_no}/create/edit?FirstDate=${this.$root.moment(day).format('MM/DD/YYYY')}" class="order-day">Create</a>`;

                            if(this.month[`day_${day_no}_count`].length > 0){
                                mobileOverlayTrigger += `View / Edit<br>${this.month[`day_${day_no}_count`].length} Order(s)`;
                            }
                            else{
                                mobileOverlayTrigger += `Create Order(s)`;
                            }
                        }
                        else{
                            if(this.month[`day_${day_no}_count`].length) mobileOverlayTrigger += `View<br>${this.month[`day_${day_no}_count`].length} Order(s)`;
                        }
                    }
                    
                    if(this.month[`day_${day_no}_count`].length>0){
                        this.month[`day_${day_no}_count`].forEach(item=>{
                            output += `<a href="/orders/${this.$route.query.route}/${this.$route.params.location_no}/${item.doc}/edit?FirstDate=${this.$root.moment(day).format('MM/DD/YYYY')}" class="order-day">#${item.doc} (Qty: ${item.qty}) <small>${item.day}</small></a>`;
                        })
                    }
                    
                    mobileOverlayTrigger+=`</div>`;
                    return mobileOverlayTrigger+output;
                }
                
            },
            
            btoa(str){ return window.btoa(str); },
            atob(str){ return window.atob(str); },
            async checkAccountStatusBool(){
                // let url = e.target.href;
                if(localStorage.account_status!='Credit Hold (Over Limit)'){
                    return true;
                }
                else{
                    return false;
                }
            },
            async checkAccountStatus(e){
                let url = e.target.href;
                if(localStorage.account_status!='Credit Hold (Over Limit)'){
                    this.$router.push(url.replace('http://localhost:8080','').replace('http://localhost:8081','').replace('https://orders.kffoods.com','').replace('https://dev.kffoods.com',''));
                }
                else{
                    this.$root.showToast(['alert', 'alert-danger', 'bg-danger'], 'Credit Hold: You cannot place an order at this time.');
                    // await Sleep(300);
                    // this.$router.go(-1);
                    e.preventDefault();
                }
            },
            async getMonthOrders(){
                this.ready = false;
                this.items = [];
                let res = await axios.get(`${this.$store.state.api}/ordersummaryMONTH?&lccustno=${this.$root.getCustomerNumber()}&FirstDate=${this.$root.moment(this.showDate).format('MM/DD/YYYY')}&LastDate=${this.$root.moment(this.showDate).endOf('month').format('MM/DD/YYYY')}&lclocation=${this.$route.params.location_no}`);
                this.month = res;
            
            },
            setShowDate(d) {
                this.showDate = d;
                this.$router.replace({query:{...this.$route.query, ...{month:this.$root.moment(this.showDate).format('MM/DD/YYYY')}}});

            },
            async onClickDay(day){
                
            
            },
            async onClickItem(item){
                if(item.originalItem.title!='Create') this.showDayView={orders:undefined, startDate:item};
                else{
                    this.checkAccountStatus({target:{href:`/orders/${this.$route.query.route}/${this.$route.params.location_no}/create/edit?FirstDate=${this.$root.moment(item.startDate).format('MM/DD/YYYY')}`}});
                }
                
            },
            thisMonth(o,s,n){
            }
        },
    }
  </script>
  <style lang="scss">
  .day-lightbox-trigger{
            display:none !important;
    }
  .is-responsive{
    .cv-wrapper{
        
        @media (max-width: 992px){
            .day-lightbox-trigger{
                display:flex !important;
            }
            // margin-left: -1rem;
            // margin-right: -1rem;
            height: 460px !important;
            .cv-day{
                font-size: 0.7rem;
                padding: 0;
                overflow:hidden;
                .cv-day-number{
                    font-size: 0.7rem;
                    z-index:99;
                    border-right: 1px solid #DDDDDD;
                    border-bottom: 1px solid #DDDDDD;
                    background-color:#ddd;
                    position:relative;
                }
                .day-lightbox-trigger{
                    position:absolute;
                    z-index:5;
                }
                 >div.w-100{
                    padding: 0 !important;
                    > a{
                        font-size: 0.6rem;
                        display:block;
                    }
                 }
            }
        }
        @media (min-width: 992px){
        }
    }
  }
        .cv-wrapper{
            height: 700px;
            .cv-header{
                flex-direction:column !important;
                justify-content:center;
                align-items:center;
                background-color:var(--bs-gray-300);
                padding: 5px;
                > div{
                    text-align:center;
                    width: 100%;
                    flex-basis: 100%;
                    &.cv-header-nav{
                        border-bottom: 1px solid #ccc;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        
                        button{
                            border: 1px solid var(--bs-gray-600);
                            background-color: var(--bs-gray-400);
                            border-radius: 6px;
                            color:var(--bs-black);
                            margin-left: 0.25rem;
                            margin-right: 0.25rem;
                            
                        }
                    }
                    &.periodLabel{
                        color:var(--bs-black);
                        text-align:center !important;
                        display:block;
                    }
                }
            }
            .cv-header-days{
                > div.cv-header-day{
                    background-color: var(--bs-gray-600);
                    color: var(--bs-white);
                }
            }
        }
        .cv-day{
            display:block !important;
            overflow-y:scroll;
            padding: 5px;
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }
            &::-webkit-scrollbar-track {
                background: #FAFAFA;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {  
                background: #555;
            }
            *{
                white-space: initial;
            }
            .order-day{
                font-size: 0.8rem;
                display:block;
                border-bottom: 1px solid var(--bs-gray-300);
                margin-top: 2px; 
                margin-bottom: 2px;
                &:nth-of-type(1){
                    margin-top: 3px;
                }
            }
        }
        .cv-item{
            cursor:pointer;
            text-decoration:none !important;
            transition: all 0.2s;
            background-color:#F7B15C !important;
            border-color:#F7B15C;
            transform:translateZ(0);
            font-size: 0.8rem !important;
            &:Hover{
                // transform: scale(1.125) translateZ(0);
                text-decoration:none !important;
            }
        }
        .cv-day-number{
            // background-color: var(--bs-gray-600);
            font-weight:bold;
            display:inline-block;
        }
        .cv-event { white-space: normal !important; }
        .theme-default .cv-day.outsideOfMonth {
            background-color: #f7f7f7;
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }
            &::-webkit-scrollbar-track {
                background: #f7f7f7;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {  
                background: #555;
            }
        }
  </style>