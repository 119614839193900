<template>
    <tr :class="classes + ''">
        <slot></slot>
    </tr>
</template>
  
  <script>
  export default {
    name: 'TableRow',
    components:{},
    props: ['classes'],
    watch:{
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    tr{
      
    }
  </style>
  