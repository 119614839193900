<template>
    <PageContent>
        <PageTitle>Customer Locations<Help /></PageTitle>
        <NotReady v-if="!ready" />
        <div v-else>
            <div class="pb-3  d-flex align-items-center">
                <div class="d-inline-block  me-2"><b>Show Inactive: </b> </div>
                <div class="form-check form-switch">
                    <input autocomplete="off" autosuggest="off" class="form-check-input" name="txtinactive" type="checkbox" id="txtinactive" v-model="txtinactive" false-value="" true-value="checked">
                </div>
            </div>
            <div class="text-end mb-3">
                <a :href="`/locations/create`" class="btn btn-primary" v-if="$store.state.localStorage.user['Location Maint']||$root.isKfEmployee()">New Location</a>
            </div>
            <input type="text" v-model="searchText" :class="`form-control d-block   mb-2  ${+$store.state.localStorage.user['Responsive View']?'d-lg-none':'d-none'}`" placeholder="Search">
            
            <ScrollingTable :scroll="1"   :style="`position:sticky; top: 0; left: 0`" data-title="locations" data-intro="List of locations to edit.">
                <template v-slot:table-header>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <thead>
                            <tr data-title="Table Sorting" data-intro="Use the arrow to sort each column alphabetically or numerically.">
                                <th width="120" class="text-white" style="background-color: var(--bs-gray-700);">
                                    Route
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Route No_'" :class="`${(filter.orderBy=='Route No_'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Route No_'?'':'rotate')}`">
                                </th>
                                <th width="120" class="text-white" style="background-color: var(--bs-gray-700)">Location
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Location No_'" :class="`${(filter.orderBy=='Location No_'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Location No_'?'':'rotate')}`">
                                </th>
                                <th width="120" class="text-white" style="background-color: var(--bs-gray-700);">Ship to
                                    <img src="@/assets/chevron-down.svg" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Ship-to Code'" :class="`${(filter.orderBy=='Ship-to Code'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Ship-to Code'?'':'rotate')}`" width="15">
                                </th>
                                <th width="120" class="text-white" style="background-color: var(--bs-gray-700);">Name
                                    <img src="@/assets/chevron-down.svg" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Name'" :class="`${(filter.orderBy=='Name'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Name'?'':'rotate')}`" width="15">
                                </th>
                                <th width="220" class="text-white" style="background-color: var(--bs-gray-700);"></th>
                            </tr>
                            <tr data-title="Table Filters" data-intro="Use these inputs and toggles to filter the results of the table.">
                                <th  style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Route No_']"></th>
                                <th  style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Location No_']"></th>
                                <th  style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Ship-to Code']"></th>
                                <th  style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Name']"></th>
                                <th  style="background-color:var(--bs-gray-500)"></th>
                            </tr>
                        </thead>
                    </table>
                </template>
                <template v-slot:table-body>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <tbody>
                            <tr v-for="(row, i) in _.filter(locations, r=>{
                                return   r['Route No_'].toLowerCase().startsWith(search['Route No_'].toLowerCase().trim())&&
                                r['Location No_'].toLowerCase().startsWith(search['Location No_'].toLowerCase().trim())&&
                                r['Ship-to Code'].toLowerCase().startsWith(search['Ship-to Code'].toLowerCase().trim())&&
                                r['Name'].toLowerCase().startsWith(search['Name'].toLowerCase().trim())
                            })" :key="i">
                                <td width="120">
                                    <a v-if="$store.state.localStorage.user['Location Maint']" :href="`/locations/${row['id']}/edit?lclocationno=${row['Location No_']}&lcrouteno=${row['Route No_']}`" class=" me-1 btn btn-primary btn-sm d-none d-lg-inline-block">Edit</a> 
                                     <span :class="(row.Status==1?'opacity-50 text-muted':'')"><span>{{row['Route No_']}}</span><br><span v-if="row['Ship-to Customer No_']">(Ship-To: {{ row['Ship-to Customer No_'] }})</span></span>
                                </td>
                                <td width="120"><span :class="(row.Status==1?'opacity-50 text-muted':'')">{{row['Location No_']}}</span></td>
                                <td width="120"><span :class="(row.Status==1?'opacity-50 text-muted':'')">{{row['Ship-to Code']}}</span></td>
                                <td width="120"><span :class="(row.Status==1?'opacity-50 text-muted':'')">{{row['Name']}}</span></td>
                                <td width="220" class="text-end">
                                    <a v-if="$store.state.localStorage.user['Location Maint']" :href="`/locations/${row['id']}/edit?lclocationno=${row['Location No_']}&lcrouteno=${row['Route No_']}`" class=" me-1 btn btn-primary btn-sm d-inline-block d-lg-none">Edit</a> 
                                    <a v-if="i==0" :href="`/locations/${row['Location No_']}/orders?&route=${row['Route No_']}&name=${btoa((row['Name']))}`" class=" me-1 btn btn-primary btn-sm" data-title="Order" data-intro="Place order for this location.">Order</a>
                                    <a v-if="i!=0" :href="`/locations/${row['Location No_']}/orders?route=${row['Route No_']}&name=${btoa((row['Name']))}`" class=" me-1 btn btn-primary btn-sm">Order</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </ScrollingTable>
        </div>
        
    </PageContent>
  </template>
  
  <script>

    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ScrollingTable from '@/components/Table/ScrollingTable.vue';
    import Help from '@/components/Help.vue';

import axios from 'axios';

    export default {
        name: 'Locations',
        components: {PageContent, PageTitle, ScrollingTable, NotReady, Help},
    
        watch:{
            txtinactive:async function(newVal){
                await this.getLocations();
                this.pagination.page = 0;
                this.rows = [];
            },
        },
        data(){
            return {
                // UserForm, CustomerForm, UserForm
                _:_,
                ready:false,
                disabled:false,
                error:'',
                txtinactive:'',
                allLocations:[],
                routes:[],
                searchText:'',
                search:{
                    searchString:['true'],
                    'Route No_':'',
                    'Location No_':'',
                    'Name':'',
                    'Ship-to Code':'',
                },
                filter:{
                    orderBy:'Route No_',
                    order:'asc'
                },
                pagination:{
                    search:'',
                    pages:0,
                    per_page:20,
                    page:0,
                },
                rows:[],
                
            }
        },
        async mounted(){
            await this.getLocations();
            this.ready = true;
        },
        computed:{
            locations:function(){
                return _.filter(_.orderBy(this.allLocations,this.filter.orderBy, this.filter.order), c=>{
                    let searchText = this.searchText.replace(/\./gi, '').toLowerCase();

                    let customer_no = c['Customer No_'].replace(/\./gi, '').toLowerCase().trim();
                    let location_no = c['Location No_'].replace(/\./gi, '').toLowerCase().trim();
                    let route_no = (c['Route No_']).toString().trim().toLowerCase();
                    let name = (c['Name']).toString().trim().toLowerCase();
                    let shipToCode = (c['Ship-to Code']).toString().trim().toLowerCase();
                    return customer_no.startsWith(searchText)||location_no.startsWith(searchText)||route_no.startsWith(searchText)||name.startsWith(searchText)||shipToCode.startsWith(searchText);
                });
            }   
        },
        methods:{
            btoa(str){
                try{
                    return window.btoa(str); 
                }
                catch(err){
                    return str;
                }
            },
            async getLocations(){
                return new Promise(async resolve=>{
                    let res = await axios.get(`${this.$store.state.api}/custlocations?lccustno=${this.$root.getCustomerNumber()}&show_inactive=${this.txtinactive}`);
                    // console.log(res.map(l=>l['Customer No_'] + ' - ' + l['POS']))
                    // console.log(res.find(l=>l['POS']=='FE738F019B72E91180D05CF9DDF94843'))
                    console.log(res)
                    this.allLocations = res;
                    this.rows = this.allLocations;
                    resolve();
                })
            },
        
        },
    }
  </script>
  <style lang="scss" scoped>
  .is-responsive{
    @media (max-width:  992px){
      [data-title="locations"]{
        .table-body-parent{
          table.table{
            // border: 5px solid red !important
            display:block;
            > tbody{
              display:block;
              tr{
                display:block;
                margin-bottom: 1rem;
                td{
                  display:block;
                  width: 100%;
                  padding-left: 0.75rem;
                  padding-right: 0.75rem;
                  &:first-child{
                    padding-top: 0.75rem !important;
                  }
                  &:last-child{
                    padding-bottom: 0.75rem;
                  }
                  &:nth-of-type(1){
                    display:flex;
                    flex-wrap:wrap;
                    padding-top: 0;
                    padding-bottom: 0;
                    > span{
                        display:block;
                        width: 100%;

                        &:nth-of-type(1){
                            order: 1;
                            display:block;
                            &:before{
                                content:  'Route: ';
                                font-weight:bold;
                            }
                        }
                        &:nth-of-type(3){
                            order: 2;
                        }
                    }
                  }
                  &:nth-of-type(2){
                    padding-top: 0;
                    padding-bottom: 0;
                    &:before{
                      content:  'Location: ';
                      font-weight:bold;
                    }
                  }
                  &:nth-of-type(3){
                    padding-top: 0;
                    padding-bottom: 0;
                    &:before{
                      content:  'Ship to: ';
                      font-weight:bold;
                    }
                  }
                  &:nth-of-type(4){
                    padding-top: 0;
                    padding-bottom: 0;
                    &:before{
                      content:  'Name: ';
                      font-weight:bold;
                    }
                  }
                  &:last-child{
                    .btn{
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
    
</style>