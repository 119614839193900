<template>
    <div :class="`container-fluid pt-3 pb-3 mb-1 bg-light border ${classes}`"  id="PriceCodesArea">
      <Form>
        <fieldset :disabled="disabledPriceCodes">
            <div class="row">
              <div class="col-12"><h6>Price Codes</h6></div>
              <div class="col-xl-2  pt-1 pb-1 col-lg-3 col-md-4 col-sm-6 col-6" v-for="(priceCode, indx) in priceCodes">
                  <div class="input-group-sm input-group">
                      <span class="input-group-text" style="width: 50px;">{{priceCode['Price Code']}}</span>
                      <Field class="form-control" type="number" :name="`priceCodes[${indx}][Price Code Retail Value]`" :rules="'required'" @blur=" priceCode['Price Code Retail Value']= convertAndRound(priceCode['Price Code Retail Value']); savePriceCode(priceCode, indx)" v-model="priceCode['Price Code Retail Value']" maxlength="4" />
                      <ErrorMessage class="field-error text-danger" :name="`priceCodes[${indx}][Price Code Retail Value]`" />
                  </div>
              </div>
            </div>
        </fieldset>
      </Form>
    </div>
    
  </template>
  <script>

  import axios from 'axios';
import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
  defineRule('required', (value, [target], ctx) => {
    if(!value) return 'Value is required';
    return true;
  });

  export default {
    name: 'PriceCodes',
    components:{Field ,Form, ErrorMessage},
    props: ['priceCodes', 'disabledPriceCodes', 'txtItemListCode', 'classes'],
    watch:{
      'classes':function(newVal){
        if(newVal=='can-be-sticky'){
          // $("#PriceCodesArea").sticky({topSpacing:0, zIndex:99});
        }
        else{
          // $("#PriceCodesArea").unstick();
        }
      }
    },
    computed:{
    },
    data(){
        return {
          allPriceCodes:[]
        }
    },
    async mounted(){
      this.getAllPriceCodes();
      await Sleep(10);
      // $("#PriceCodesArea.can-be-sticky").sticky({topSpacing:0, zIndex:99});
    },
    methods:{
      getAllPriceCodes(){
        this.allPriceCodes = JSON.parse(JSON.stringify(this.priceCodes));
        this.allPriceCodes = this.allPriceCodes.map(pc=>({...pc, ...{'Price Code Retail Value':parseFloat(pc['Price Code Retail Value']).toFixed(2)}}));
        this.allPriceCodes = JSON.parse(JSON.stringify(this.allPriceCodes));
      },
      convertAndRound(value){
        if(!value.length) return '0.00';
        else {
          return parseFloat(value).toFixed(2);
        }
      },
      savePriceCode: _.debounce(async function(value, indx){
        // if(this.allPriceCodes[indx]==undefined||(this.allPriceCodes[indx]['Price Code Retail Value'] == value['Price Code Retail Value'])) return;

        this.$emit('disabledPriceCodes', true);
        this.getAllPriceCodes();
        let pc = this.allPriceCodes[indx];
        let res = await axios.get(`${this.$store.state.api}/itemmaintpcodesave?&lccustno=${this.$root.getCustomerNumber()}&oldpcode=${pc['Price Code Retail Value']}&newpcode=${value['Price Code Retail Value']}&name=${value['Price Code'].trim()}&listcode=${this.txtItemListCode}`);
        if(res.message=='error'){
            if(this.allPriceCodes[indx] == undefined){
              this.allPriceCodes.push(value);
            }
            else {
              this.allPriceCodes[indx] = value;
            }
        }
        else{
           
        }
        this.$emit('disabledPriceCodes', false);
      }, 300, {leading:true,trailing:false})
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .field-error{
      font-size: 0.75rem;
    }
    #PriceCodesArea{
      // z-index:999;
    }
  </style>
  