<template>
    <PageContent classes="p-5">
        <PageTitle>Family Express XML Enterprise Ordering</PageTitle>
        <div class="text-center" v-if="$root.getCustomerValue('No_')=='125600'">
            <router-link to="/enterprise-upload" class=" ms-1 me-1 btn btn-light">CSV</router-link>
            <router-link to="/enterprise-upload-xml-family-express" class=" ms-1 me-1 btn btn-primary">XML</router-link>
            <hr>
        </div>
        <!-- {{ $root.getCustomerNumber() }} -->
        <div v-if="!ordersDone">
            <div v-if="!Object.keys(csvData).length">
                <div class="alert alert-danger" v-if="error" v-html="error"></div>
                <form>
                    <div class="form-group">
                        <label for="">Upload XML</label>
                        <input type="file" class="form-control" @change.prevent.stop="uploadFile" accept=".xml">
                    </div>
                </form>
            </div>
            <div v-else>
                <!-- {{ csvData }} -->
                <h5>Order Confirmation</h5>
                <div class="alert alert-danger" v-if="error" v-html="error"></div>
                <div class="alert alert-info">Please look over the following information to verify your order.</div>
                <div class="alert alert-info" v-if="startIdx>0&&maxIdx>0">{{ (startIdx) }} out of {{ maxIdx }} orders processed</div>
                <div v-for="(row,key, index) in csvData" :class="`order-review-table border p-2 mb-3`" :data-order-day="`${key}`" :id="`date_${btoa(key).replace('==','')}`">
                    <!-- <div class="mb-2 text-center fs-5"><strong></div> -->
                    <div v-for="(order, day,index2) in row">
                        <div class="mt-2 mb-1 bg-dark text-white p-1"><span>Order #{{ (index2+index+1) }}</span> <span class="float-end">{{ key }}</span></div>
                        <div class="mb-2" v-for="(items, location,index3) in order"  :data-order-day-location="`${key}-${day}-${location}`" :id="`date_${btoa(key).replace('==','')}_${location}`">
                            <div class="mb-1 d-inline-block"><strong>Location: </strong> {{ location }}</div>
                            
                            <table class="table table-sm table-bordered table-striped mb-2">
                                <thead>
                                    <tr>
                                        <th>Item Number</th>
                                        <th>Quantity</th>
                                        <th>Used on Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items">
                                        <td>{{ item['Item Number'] }}</td>
                                        <td>{{ item['Quantity'] }}</td>
                                        <td>
                                            <span v-if="item['Used on Day']==''">{{ $root.moment.moment(item['Used on Day']).format('dddd') }}</span>
                                            <span v-else>{{ item['Used on Day'] }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="alert alert-info" v-if="startIdx>0&&maxIdx>0">{{ (startIdx) }} out of {{ maxIdx }} orders processed</div>
                <br>
                <button  class="btn btn-primary"  @click.prevent.stop="sendOrdersByDate" id="confirmButton">CONFIRM</button>
            </div>
        </div>
        <div v-else>
            <!-- {{ ordersResponse }} -->
            <div v-for="order in ordersResponse" class="mb-4 p-3 border bg-white shadow">
                <!-- {{ order.response.invalid_dates[order.date] }} -->
                <h2 class="fs-5"><b>{{ order.date }}</b></h2>
                <hr />
                
                <div v-if="order.response.invalid_dates.hasOwnProperty(order.date)">
                    <div class="alert alert-info">{{ order.response.invalid_dates[order.date] }}</div>
                </div>
                <div v-else>
                    
                    <h3 class="fs-6"><b>Order(s)</b></h3>
                    <div v-if="Object.keys(order.response.locations_items_ordered).length">
                        <div v-for="(items, location) in order.response.locations_items_ordered">
                            <h4 class="fs-6 mb-0 " v-if="items.length"><b>Used on Day:</b> {{ $root.moment().day(items[0].used_on_day).format('dddd') }}</h4>
                            <h4 class="fs-6" ><b>Location</b>:{{ location }}<br><small><b>Ordered Location Items</b>:</small></h4>
                            <table class="table table-sm table-bordered table-striped" style="font-size: 0.85rem;">
                                <thead>
                                    <tr>
                                        <th style="padding: 2px 4px; width: 100px;">Item Number</th>
                                        <th style="padding: 2px 4px;">Item Desc</th>
                                        <th style="padding: 2px 4px;">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items">
                                        <td style="padding: 2px 4px;">{{ item.item }}</td>
                                        <td style="padding: 2px 4px;" v-html="item.desc"></td>
                                        <td style="padding: 2px 4px;">{{ item.qty }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="text-danger" v-for="(msg,location) in order.response.locations_cant_order" v-if="Object.keys(order.response.locations_cant_order).length"><b>{{ location }}</b>: {{ msg }}</div>

                    <div v-if="Object.keys(order.response.locations_items_cant_order).length" class=" mt-2 mb-2">
                        <h3 class="fs-6"><b>Invalid Item(s)</b></h3>
                        <div class="text-danger" v-for="(items,location) in order.response.locations_items_cant_order" >
                            <b>{{ location }}</b>: 
                            <div v-for="item in items"> - {{ item.item }}</div>
                        </div>
                    </div>

                    <div v-if="order.response.invalid_locations.length" class="mt-2 mb-2">
                        <h3 class="fs-6"><b>Invalid Location(s)</b></h3>
                        <div class="text-danger" v-for="location in order.response.invalid_locations"> - {{ location }}</div>
                    </div>
                </div>
                

            </div>
            <hr />
        </div>
    </PageContent>
    
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import VueSimpleAlert from "vue3-simple-alert";
  import axios from 'axios';
  import store from '@/store';
  export default {
    name: 'UploadXMLFamilyExpress',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            disabled:false,
            ready:false,
            error:'',
            headerError:'',
            csvData:[],
            ordersDone:false,
            ordersResponse:[],
            startIdx:0,
            maxIdx:0,
            days:['Monday','Tuesday','Wendesday','Thursday','Friday'],
            items:[],
            locations:[]
        }
    },
    async mounted(){
        this.items = await axios.get(`${this.$store.state.api}/itemmaint_new?&CUSTPRICEGRP=${this.$root.getCustomerValue('Customer Price Group')}&RTLPRICEGRP=${this.$root.getCustomerValue('Retail Price Group')}&txtItemListCode&lccustno=${this.$root.getCustomerNumber()}&orderby&order`);
        this.items = (this.items.filter(i=>i['Customer Item No_']!=''))
        this.locations = await this.getLocations();
        // console.log(this.locations)
    },
    methods:{
        btoa:function(string){
            return btoa(string);
        },
        async updateLocation(key,day,location){
            this.csvData[key][day][location] = this.csvData[key][day][location].map(item=>{
                item['Location Number'] = this.csvData[key][day][location][0]['Location Number'];
                return item;
            })
            this.csvData[key][day]=(_.groupBy(_.flatten(Object.values(this.csvData[key][day])),'Location Number'));
        },
        async getLocations(){
            return new Promise(async resolve=>{
                let res = await axios.get(`${this.$store.state.api}/custlocations?lccustno=${this.$root.getCustomerNumber()}&show_inactive=${this.txtinactive}`);
                resolve(res);
            })
        },
        async uploadFile(e){
            this.error = '';
            let file = e.target.files[0];
            let fileType = file.fileType;
            if(file.type!='text/xml'){
                this.error  = 'An XML file is required';
                return;
            }
            let reader = new FileReader();
            reader.onload = async e=>{
                let xmlDoc = $.parseXML(e.target.result)
                let $xml = $( xmlDoc )
                let $purchaseOrder = $xml.find('PurchaseOrder');
                this.csvData = [];
                let invalidItems = [];
                for(let i = 0; i < $purchaseOrder.length; i++){

                    let $po = $($purchaseOrder.get(i));
                    let location = _.padStart($po.find('ShipTo').attr('ident'),6,'0');
                    // location = (this.locations.find(l=>location==+l['Location No_']))
                    let $date =  $po.find('Dates OrderDate');
                    let date = moment($date.text(), 'YYYY-MM-DD').format('MM/DD/YYYY');
                    let $detail = $po.find('PurchaseOrderDetail');
                    let $items = $detail.find('LineItem');
                    for(let j = 0;j < $items.length; j++){
                        let  item = $items.get(j);
                        let $item = $(item);
                        // let matchedItem = 
                        // let count = $item.attr('count')
                        let count = +$item.find('PurchaseOrderUnitQty').text();
                        let customerItemNumber = $item.find('PurchaseOrderUnitId').attr('ident');
                        let matchedItem = this.items.find(i=>i['Customer Item No_']==customerItemNumber);
                        if(matchedItem==undefined){
                            invalidItems.push(customerItemNumber);
                        }
                        else{
                            this.csvData.push({
                                'Date':date,
                                'Item Number':matchedItem['Item No_'],
                                'Location Number':location,
                                'Quantity':count,
                                'Used on Day':moment($date.text(), 'YYYY-MM-DD').format('dddd'),
                            })
                        }
                        // console.log(matchedItem)
                        // console.log(item)
                        
                    }
                    
                }
                this.error = `${_.uniq(invalidItems).map(i=>`<div>Invalid item #: ${i}</div>`).join('')}`;
                this.csvData = _.groupBy(_.orderBy(this.csvData, ['Date','Location Number','Item Number'],['asc','asc','asc']), 'Date');
                    for(let day in this.csvData){
                        for(let item of this.csvData[day]){
                            let result;
                            if(+item.Quantity>300){
                                try{
                                    result = await VueSimpleAlert.confirm(`Are you sure you want to order more than a quantity of 300 for item number ${item['Item Number']} for location ${item['Location Number']} on ${item['Date']}?`, `Quantity Alert`);
                                }
                                catch(err){
                                    result = false;
                                }
                            }
                            else result = true;
                            if(!result){
                                this.csvData[day].splice(this.csvData[day].findIndex(i=>i['Item Number']==item['Item Number']), 1);
                            }
                            else{
                            }
                        }
                    }
                    for(let date in this.csvData){
                        this.csvData[date] = _.groupBy(this.csvData[date], 'Used on Day');
                        for(let usedOnDay in this.csvData[date]){
                            this.csvData[date][usedOnDay] = _.groupBy(this.csvData[date][usedOnDay], 'Location Number');
                        }   
                    }
            }
            reader.readAsText(file);
        },
        async sendOrdersByDate(){
            let hasunchosenLocation=false;
            let days = _.values(this.csvData);
            for(let i =0; i < days.length; i++){
                let locations = _.values(days[i]);
                for(let j = 0; j < locations.length; j++){
                    let location = Object.keys(locations[j])[0];
                    if(location.startsWith('location_')) hasunchosenLocation = true;
                    console.log(location);
                }
            }
            // return;
            if(hasunchosenLocation) {
                await VueSimpleAlert.alert(`A location must be chosen for every order`, `Location Alert`);
                return;
            }
            let req, res;
            if($('#confirmButton').length) $('#confirmButton').remove();
            this.maxIdx = ((_.flatten(_.flatten(_.map(_.values(this.csvData), c=>_.map(_.values(c), l=>_.values(l))))))).length;
            for(let date in this.csvData){
                let dayOrder = this.csvData[date];
                for(let usedOnDay in dayOrder){
                    let usedOnDayOrder = dayOrder[usedOnDay];
                    for(let location in usedOnDayOrder){
                        let items = usedOnDayOrder[location];
                       
                        let $orderContainer = $(`.order-review-table [data-order-day-location="${date}-${usedOnDay}-${location}"]`);
                        
                        // document.querySelector(`#main-app-container > .row > [class*=col]:nth-of-type(2) [data-order-day-location="${date}-${location}"]`).scrollIntoView({behavior:'smooth'});
                        $orderContainer.addClass('disabled');
                        $orderContainer.append(`<div class="loading-box"><div class="mb-3">Uploading Order for <b>${date}</b> Location: <b>${location}</b></div><div class="ms-3 spinner-border fs-5" role="status"><span class="visually-hidden">Loading...</span></div></div>`);

                        let formData = new FormData();
                        formData.append('locations', JSON.stringify({[location]:usedOnDayOrder[location].map(i=>({qty:i['Quantity'],item:i['Item Number']}))}));
                    
                        req = await fetch(`${this.$store.state.api}/enterprise_order?date=${date}&custno=${this.$root.getCustomerNumber()}&lcusedonday=${usedOnDay}`, {
                            body:formData, method:'POST', headers:{
                                Authorization:`Basic ${btoa(`${this.$store.state.localStorage.user.APIToken}:${this.$store.state.localStorage.user.APISecret}`)}`
                            }
                        });
                        this.startIdx += 1;
                        res = await req.json();
                        
                        $orderContainer.find('.loading-box').html(`<div class="mb-3">Order for <b>${date}</b> Location <b>${location}<b> Complete!</div><div><i class="text-primary fs-3 fa-solid fa-check"></i></div>`);
                        this.ordersResponse.push({date:date, response:res});
                        
                    }
                }
                
            }
            this.ordersDone = true;
            document.querySelector(`#main-app-container > .row > [class*=col]:nth-of-type(2)`).scrollTo(0,0);
        },
        detectDelimiter(csvData) {
            // Split the first row of the CSV data to check for different delimiters
            const firstRow = csvData.split(/\r\n|\n/)[0];
            const delimiters = [',', ';', '\t']; // Add other possible delimiters if needed

            // Count the occurrences of each delimiter in the first row
            const delimiterCounts = delimiters.map(delimiter => ({
                delimiter,
                count: (firstRow.match(new RegExp(delimiter, 'g')) || []).length
            }));

            // Sort the delimiters by their occurrence count in descending order
            delimiterCounts.sort((a, b) => b.count - a.count);

            // Return the most likely delimiter (comma if a tie or no other delimiter found)
            return delimiterCounts.length > 0 ? delimiterCounts[0].delimiter : ',';
        },
        isDateValid(date){
            let parsedDate = moment(date, 'MM/DD/YYYY').isValid()||moment(date, 'M/D/YYYY').isValid()||moment(date, 'M/DD/YYYY').isValid()||moment(date, 'YYYY-MM-DD').isValid();
            return parsedDate;
        }
    },
  }
  </script>
  <style lang="scss" >
    .order-review-table{
        .disabled{
            position:relative;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display:block;
                z-index:10;
                background-color:rgb(#fff, 0.75);
            }
            
        }
        [data-order-day-location]{
            position:relative;
            min-height: 220px;
        }
        .loading-box{
                background-color:var(--bs-white);
                display:block;
                z-index:11;
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translateX(-50%)  translateY(20%);
                box-shadow: 0 0 5px rgba(#000,0.75);
                border-radius: 8px;
                padding: 1rem;
                text-align:center;
            }
    }
  </style>