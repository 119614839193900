<template>
  <!-- {{ header.laqtyhist }} -->
    <ScrollingTable :scroll="false">
      <template v-slot:table-header>
        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
            <thead>
                <tr>
                  <th class="text-white" style="background-color: var(--bs-gray-700);"><small>Price Code</small></th>
                  <th class="text-white" style="background-color: var(--bs-gray-700);"><small>Order Qty</small></th>
                  <th class="text-white" style="background-color: var(--bs-gray-700);" v-for="hist in header.laqtyhist"><small>{{ hist[0] }}</small> <small>{{ $root.moment(hist[1]).format('MM/DD') }}</small></th>
                  <th class="text-white" style="background-color: var(--bs-gray-700);"><small>Hst Ttl</small></th>
                  <th v-if="$store.state.localStorage.user['Waste System']" class="text-white" style="background-color: var(--bs-gray-700);"><small>Waste %</small></th>
                </tr>
            </thead>
            <tfoot>
            <tr>
                <th class="text-white" style="background-color: var(--bs-gray-700);"><small>TTL</small></th>
                <th class="text-white" style="background-color: var(--bs-gray-700);"><small>{{ _.sumBy(items, i=>+i.qty) }}</small></th>
                <th class="text-white" style="background-color: var(--bs-gray-700);" v-for="(hist,j) in header.laqtyhist">
                  <span v-if="_.sumBy(_.flatten(_.values(groups)),i=>+i[`OrderQty${j+1}`])>0||_.sumBy(_.flatten(_.values(groups)),i=>+i[`WasteQty${j+1}`])>0">
                    {{ _.sumBy(_.flatten(_.values(groups)),i=>+i[`OrderQty${j+1}`]) }}<span v-if="$store.state.localStorage.user['Waste System']">/{{ _.sumBy(_.flatten(_.values(groups)),i=>+i[`WasteQty${j+1}`]) }}</span>
                  </span>
                </th>
    
                <th class="text-white" style="background-color: var(--bs-gray-700);">
                  {{pcTotals(groups)}}
                </th>
                <th  v-if="$store.state.localStorage.user['Waste System']" class="text-white" style="background-color: var(--bs-gray-700);">{{wastePercs((groups))}}</th>
              </tr>
          </tfoot>
          <tbody>
            <tr v-for="(group, pricecode) in groups">
                <td>{{ pricecode }}</td>
                <td>{{ _.sumBy(_.filter(items, i=>i.pricecode==pricecode), i=>+i.qty)>0?_.sumBy(_.filter(items, i=>i.pricecode==pricecode), i=>+i.qty):'' }}</td>
                <td v-for="(hist,i) in header.laqtyhist">
                  <div v-if="(wasteQty(group,i)>0||orderQty(group,i)>0)">
                    <span>{{ orderQty(group,i) }}</span>
                    <span v-if="$store.state.localStorage.user['Waste System']"> / {{ wasteQty(group,i) }}</span>
                  </div>
                </td>
                <td>{{pcTotal(group)}}</td>
                <td v-if="$store.state.localStorage.user['Waste System']">{{wastePerc(group)}}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </ScrollingTable>
</template>
  <script>
    import Header from '@/components/Orders/Header.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ScrollingTable from '@/components/Table/ScrollingTable.vue'
  export default {
    name: 'History',
    components: {PageContent, PageTitle, Header, ScrollingTable},
    props: ['allItems','items','header'],
    data(){
        return {
          _:_,
          groups:[],
        }
    },
    async mounted(){
        let items = _.orderBy(this.allItems, 'pricecode');
        this.groups = _.groupBy(items, 'pricecode');
    },
    methods:{
      orderQty(group,n){
        n = n+1;
       return  _.sumBy(group, i=>+i[`OrderQty${n}`]);
      },  
      wasteQty(group,n){
        n = n+1;
       return  _.sumBy(group, i=>+i[`WasteQty${n}`]);
      },  
      pcTotal(group){
        let total = _.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'OrderQty'))))),i=>+i)));
        let waste = _.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'WasteQty'))))),i=>+i)));
        return total > 0 && !isNaN(total) ? `${total}${this.$store.state.localStorage.user['Waste System']&&total>0?`/${waste}`:``}`:'';
      },
      pcTotals(groups){
        let total = 0;
        let waste = 0;
        for(let group in groups){
          group = groups[group];
          total += _.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'OrderQty'))))),i=>+i)));
          waste += _.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'WasteQty'))))),i=>+i)));
        }
        
        return total > 0 && !isNaN(total) ? `${total}${this.$store.state.localStorage.user['Waste System']&&total>0?`/${waste}`:``}`:'';
      },
      wastePerc(group){
        let totalQty = 0;
        let totalWaste = 0;

        totalQty = (_.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'OrderQty'))))),i=>+i))));
        totalWaste = (_.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'WasteQty'))))),i=>+i))));

        if(isNaN(totalQty)) totalQty = 0;
        if(isNaN(totalWaste)) totalWaste = 0;
        if(totalQty == 0 && totalWaste == 0||totalWaste == 0) return '';
        let grandTotalWastePerc = ((totalWaste / totalQty) * 100);
        if(!isFinite(grandTotalWastePerc))  grandTotalWastePerc = '';
        else grandTotalWastePerc = grandTotalWastePerc.toFixed(1);
        return grandTotalWastePerc;
        // return ((totalWaste / totalQty) * 100).toFixed(1);

      },
      wastePercs(groups){
        let totalQty = 0;
        let totalWaste = 0;
        for(let group in groups){
          group = groups[group];
          totalQty += (_.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'OrderQty'))))),i=>+i))));
          totalWaste += (_.sum(_.flatten(_.map(_.flatten(_.map(group, g=>_.values(_.pickBy(g, (val,key)=>_.startsWith(key,'WasteQty'))))),i=>+i))));
        }
        
        if(isNaN(totalQty)) totalQty = 0;
        if(isNaN(totalWaste)) totalWaste = 0;
        if(totalQty == 0 && totalWaste == 0||totalWaste == 0) return '';
        return ((totalWaste / totalQty) * 100).toFixed(1);

      },
    },
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
   
  </style>
  