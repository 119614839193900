<template>
    <PageContent classes="p-5">
        <NotReady v-if="!ready" />
        <div v-else>
            <PageTitle>Order System Down</PageTitle>
            <p>The Ordering System is currently experiencing technical issues.</p>
            <p>Our Information Technology Team is working diligently to correct the issues.</p>
            <p>If this is <u><b>urgent</b></u>, please contact us at <a href="tel:800-236-4700">(800) 236-4700</a></p>
        </div>
    </PageContent>
    
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
  export default {
    name: 'Error',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            ready:false,
            disabled:false,
            ready:false
        }
    },
    async mounted(){
        let res = await axios.get(`${this.$store.state.api}/checkstatusofsystem`);
        if(res.message=='ServerBackUp'){
            this.$router.push('/dashboard');
        }
        else{
            this.ready = true;
        }
    },
    methods:{
       
    },
  }
  </script>
  <style lang="scss" scoped>
    
  </style>