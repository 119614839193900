<template>
    <td :class="class" :style="style">
        <slot></slot>
    </td>
</template>
  
  <script>
  export default {
    name: 'TableCell',
    components:{},
    props: ['class', 'style'],
    watch:{
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    
  </style>
  