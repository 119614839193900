import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Login from '../views/Login.vue'
import Error from '../views/Error.vue'
import NotFound from '../views/404.vue'
import Dashboard from '../views/Dashboard.vue';
import UploadCSV from '../views/UploadCSV.vue';
import RoundysCSV from '../views/RoundysCSV.vue';
import UploadXMLFamilyExpress from '../views/UploadXML/FamilyExpress.vue';
import TrainingVideos from '../views/TrainingVideos.vue';
import DeliveryInstructions from '../views/DeliveryInstructions.vue';
import Password from '../views/Password.vue';
import Waste from '../views/Waste.vue';
import SubscribeToTextAlerts from '../views/SubscribeToTextAlerts.vue';



import WasteReview from '../views/Waste/Review.vue';
import Users from '../views/Users.vue';
import UsersEdit from '../views/Users/Edit.vue';
import UsersCreate from '../views/Users/Create.vue';
/*========LOCATIONS START==========*/
import Locations from '../views/Locations.vue';
import LocationsEdit from '../views/Locations/Edit.vue';
import LocationsCreate from '../views/Locations/Create.vue';
import LocationsOrders from '../views/Locations/Orders.vue';
/*========LOCATIONS END==========*/
/*========REPORTS START==========*/
import Reports from '../views/Reports.vue';
import ReportsMenu from '../views/Reports/Menu.vue';
import ReportsWaste from '../views/Reports/Waste.vue';
import ReportsOpenInvoices from '../views/Reports/OpenInvoices.vue';
import ReportsOrderSummaryDataFile from '../views/Reports/OrderSummaryDataFile.vue';
import ReportsOrderInvoiceSummary from '../views/Reports/OrderInvoiceSummary.vue';
import ReportsPurchaseHistory from '../views/Reports/PurchaseHistory.vue';
/*========REPORTS END==========*/
/*========CUSTOMERS START==========*/
import Customers from '../views/Customers.vue';
  import CustomersEdit from '../views/Customers/Edit.vue';
  import CustomersCreate from '../views/Customers/Create.vue';

  import CustomersUsers from '../views/Customers/Users.vue';
  import CustomersUsersEdit from '../views/Customers/Users/Edit.vue';
  import CustomersUsersCreate from '../views/Customers/Users/Create.vue';
/*========CUSTOMERS END==========*/
/*========ITEMS START==========*/
import Items from '../views/Items.vue';
/*========ITEMS END==========*/
import OrderAddCut from '../views/OrderAddCut.vue'
import OrderSummary from '../views/OrderSummary.vue';
import OrdersEdit from '../views/Orders/Edit.vue';
/*========API START==========*/
import Integration from '../views/Integration.vue';
/*========API END==========*/

const routes = [
  { path: '/:pathMatch(.*)', component: NotFound },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/enterprise-upload',
    name: 'UploadCSV',
    component: UploadCSV
  },
  {
    path: '/roundys-upload',
    name: 'RoundysCSV',
    component: RoundysCSV
  },
  {
    path: '/enterprise-upload-xml-family-express',
    name: 'UploadXMLFamilyExpress',
    component: UploadXMLFamilyExpress
  },
  {
    path: '/trainingvideos',
    name: 'TrainingVideos',
    component: TrainingVideos
  },
  {
    path: '/delivery-instructions',
    name: 'DeliveryInstructions',
    component: DeliveryInstructions
  },
  {
    path: '/waste',
    name: 'Waste',
    component: Waste
  },
    {
      path: '/waste/review',
      name: 'WasteReview',
      component: WasteReview
    },
  {
    path: '/subscribe-to-text-alerts',
    name: 'SubscribeToTextAlerts',
    component: SubscribeToTextAlerts
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations
  },
    {
      path: '/locations/:location_id/edit',
      name: 'LocationsEdit',
      component: LocationsEdit
    },
    {
      path: '/locations/create',
      name: 'LocationsCreate',
      component: LocationsCreate
    },
    {
      path: '/locations/:location_no/orders',
      name: 'LocationsOrders',
      component: LocationsOrders
    },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
    {
      path: '/users/:name/edit',
      name: 'UsersEdit',
      component: UsersEdit
    },
    {
      path: '/users/create',
      name: 'UsersCreate',
      component: UsersCreate
    },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
    {
      path: '/reports/menu',
      name: 'ReportsMenu',
      component: ReportsMenu
    },
    {
      path: '/reports/waste',
      name: 'ReportsWaste',
      component: ReportsWaste
    },
    {
      path: '/reports/open-invoices',
      name: 'ReportsOpenInvoices',
      component: ReportsOpenInvoices
    },
    {
      path: '/reports/order-summary-data-file',
      name: 'ReportsOrderSummaryDataFile',
      component: ReportsOrderSummaryDataFile
    },
    {
      path: '/reports/order-invoice-summary',
      name: 'ReportsOrderInvoiceSummary',
      component: ReportsOrderInvoiceSummary
    },
    {
      path: '/reports/purchase-history',
      name: 'ReportsPurchaseHistory',
      component: ReportsPurchaseHistory
    },
  {
    path: '/update-password',
    name: 'Password',
    component: Password
  },
  {
    path: '/items',
    name: 'Items',
    component: Items
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
    {
      path: '/customers/:customer_no/edit',
      name: 'CustomersEdit',
      component: CustomersEdit
    },
    {
      path: '/customers/create',
      name: 'CustomersCreate',
      component: CustomersCreate
    },
    {
      path: '/customers/:customer_no/users',
      name: 'CustomersUsers',
      component: CustomersUsers
    },
    {
      path: '/customers/:customer_no/users/:name/edit',
      name: 'CustomersUsersEdit',
      component: CustomersUsersEdit
    },
    {
      path: '/customers/:customer_no/users/create',
      name: 'CustomersUsersCreate',
      component: CustomersUsersCreate
    },
  {
    path: '/orderaddcut',
    name: 'orderaddcut',
    component: OrderAddCut
  },
  {
    path: '/OrderSummary',
    name: 'OrderSummary',
    component: OrderSummary
  },
  {
    path: '/orders/:route_no/:location_no/:document_no/edit',
    name: 'OrdersEdit',
    component: OrdersEdit
  },
  {
    path: '/integration',
    name: 'Integration',
    component: Integration
  },
];
function isKfEmployee(){
  return (store.state.localStorage.user.Admin && store.state.localStorage.customer==undefined);
}
//if they are logged in as customer remove specific pages
if(store.state.localStorage.customer!=undefined){
  routes.splice(_.findIndex(routes, r=>r.path=='/users'), 1);
  routes.splice(_.findIndex(routes, r=>r.path=='/orderaddcut'), 1);
  
  if(!store.state.localStorage.user['Location Maint']&&!isKfEmployee()) {
    routes.splice(_.findIndex(routes, r=>r.path=='/locations/:location_id/edit'), 1);
    routes.splice(_.findIndex(routes, r=>r.path=='/locations/create'), 1);
  }
  if(!isKfEmployee()&&!store.state.localStorage.user['Edit Users']) {
    //only kf employees should be able to view customers
    routes.splice(_.findIndex(routes, r=>r.path=='/customers'), 1);
    routes.splice(_.findIndex(routes, r=>r.path=='/customers/:customer_no/edit'), 1);
    routes.splice(_.findIndex(routes, r=>r.path=='/customers/create'), 1);
    routes.splice(_.findIndex(routes, r=>r.path=='/customers/:customer_no/users'), 1);
    routes.splice(_.findIndex(routes, r=>r.path=='/customers/:customer_no/users/:name/edit'), 1);
    routes.splice(_.findIndex(routes, r=>r.path=='/customers/:customer_no/users/create'), 1);
  }
  
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  root:  '/'
})

export default router
