<template>
    <PageContent>
        <NotReady v-if="!ready"></NotReady>
        <div v-else class="ps-1 pe-1">
            <PageTitle>Create Location <Help /></PageTitle>
            <div v-if="error" class="alert alert-danger" v-html="error"></div>
            <div v-if="success" class="alert alert-success" v-html="success"></div>
            <form @submit.prevent.stop="Save" class="text-start">
                <fieldset :disabled="disabled">
                    <div class="form-group mt-2 mb-2 text-start">
                        <label>Location</label>    
                        <input autocomplete="off" autosuggest="off" class="form-control" v-model="location['Location No_']" readonly>
                    </div>
                    <div class="form-group mt-2 mb-2"  data-intro="Select location route number">
                        <label>Route</label>    
                        <select class="form-control" v-model="location['Route No_']" id="Route No_">
                            <option v-for="(route,indx) in routes" :key="indx">{{route}}</option>
                        </select>
                    </div>
                    <div class="form-group mt-2 mb-2"  data-intro="Specify primary item list code for location">
                        <label>Inactive</label>    
                        <div class="form-check form-switch">
                            <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Status]" type="checkbox" id="Status"  true-value="1" false-value="0" v-model="location['Status']">
                        </div>
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label>Name</label>    
                        <input autocomplete="off" autosuggest="off" class="form-control" name="location[Name]" id="Name" v-model="location['Name']" maxlength="30">
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label>Customer POS</label>    
                        <input autocomplete="off" autosuggest="off" class="form-control" name="location[POS]" id="POS" v-model="location['POS']" maxlength="20" @input="location['POS']=(location['POS']).toUpperCase()">
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label>Item List Code</label>    
                        <select class="form-control" name="location[Item List Code]" id="ItemListCode" v-model="location['Item List Code']">
                            <option v-for="(lc,indx) in itemListCodes" :key="indx" :value="lc['item list code']">{{lc['item list code']==''?'Master':lc['item list code']}}</option>
                        </select>
                    </div>
                    <div class="form-group mt-2 mb-2" v-if="$root.isKfEmployee()">
                        <label>One item per tray</label>    
                        <div class="form-check form-switch">
                            <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[OneItemPerTray]" type="checkbox" id="OneItemPerTray" true-value="1" false-value="0" v-model="location['OneItemPerTray']">
                        </div>
                    </div>
                    <div class="form-group mt-2 mb-2">
                        <label>Notes</label>    
                        <textarea class="form-control" name="location[Notes]" id="Notes" v-model="location.Notes" maxlength="250"></textarea>
                    </div>
                    
                    <div class="row" data-intro="Select days location is allowed to place orders." v-if="$root.isKfEmployee()||$store.state.localStorage.user['Location Days']">
                        <div class="col ">
                            <label>Sun:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Sunday Menu]" type="checkbox" id="Sunday Menu" true-value="1" false-value="0" v-model="location['Sunday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Mon:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Monday Menu]" type="checkbox" id="Monday Menu" true-value="1" false-value="0" v-model="location['Monday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Tue:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Tuesday Menu]" type="checkbox" id="Tuesday Menu" true-value="1" false-value="0" v-model="location['Tuesday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Wed:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Wednesday Menu]" type="checkbox" id="Wednesday Menu" true-value="1" false-value="0" v-model="location['Wednesday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Thu:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Thursday Menu]" type="checkbox" id="Thursday Menu" true-value="1" false-value="0" v-model="location['Thursday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Fri:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Friday Menu]" type="checkbox" id="Friday Menu" true-value="1" false-value="0" v-model="location['Friday Menu']">
                            </div>
                        </div>
                        <div class="col ">
                            <label>Sat:</label>
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input" name="location[Saturday Menu]" type="checkbox" id="Saturday Menu" true-value="1" false-value="0" v-model="location['Saturday Menu']">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary me-2" type="submit">Create</button>
                        <a href="" @click.prevent.stop="$router.back" class="btn btn-secondary">Back</a>
                    </div>
                </fieldset>
            </form>
        </div>
    </PageContent>
  </template>
  
  <script>
  import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Help from '@/components/Help.vue';
import axios from 'axios';

  export default {
    name: 'CustLocationsEdit',
    components: {PageContent, PageTitle, NotReady, Help},
    data(){
        return {
            _:_,
            ready:false,
            disabled:false,
            location:{},
            routes:[],
            success:'',
            error:'',
        }
    },
    async mounted(){
        let res;
        res = await axios.get(`${this.$store.state.api}/custlocation_columns/`);
        res.forEach(column=>{
            this.location[column] = '';
        })
        this.location['Customer No_'] = this.$root.getCustomerNumber();
        this.location['Monday Menu'] = 1;
        this.location['Tuesday Menu'] = 1;
        this.location['Wednesday Menu'] = 1;
        this.location['Thursday Menu'] = 1;
        this.location['Friday Menu'] = 1;
        this.location['Saturday Menu'] = 0;
        this.location['Sunday Menu'] = 0;
        this.location['Status'] = 0;
        delete this.location.id;
        res = await axios.get(`${this.$store.state.api}/custroutes/?lccustno=${this.$root.getCustomerNumber()}`);
        this.routes = res.map(r=>r['Route No_']);
        if(this.routes.length) this.location['Route No_'] = this.routes[0];

        res = await axios.get(`${this.$store.state.api}/itemlistcombobox?lccustno=${this.$root.getCustomerNumber()}`);
        this.itemListCodes = _.uniqBy(res, 'item list code');
    
        res = await axios.get(`${this.$store.state.api}/nextlocationnum/?lccustno=${this.$root.getCustomerNumber()}`);
        this.location['Location No_'] = _.padStart(parseInt(res.max)+1, `${5+String(parseInt(res.max)).length}`, '0').slice(-6);

        this.ready = true;
    },
    methods:{
        async Save(){
            this.success = '';
            this.error = '';
            this.disabled=true;
            let res;
            let formData = new FormData();
            formData.append('location', JSON.stringify(this.location));
            res = await axios.post(`${this.$store.state.api}/custlocations?lccustno=${this.$root.getCustomerNumber()}&location_no=${this.location['Location No_']}`, formData);
            if(res.message == 'error'){
                this.disabled = false;
            }
            else{
                this.$router.replace('/locations');
            }
            
            window.scrollTo(0,0);
        },
    },
  }
  </script>
  