<template>
    <PageContent>
        <PageTitle>Print Menu</PageTitle>
        <div class="container">
            <div class="row">
                <div class=" col-xl-6 offset-xl-3 border p-3">
                    <form @submit.prevent.stop="createReport">
                        <div class="form-group">
                            <label for="">Menu Date</label>
                            <input type="text" name="txtsdate" v-model="txtsdate" class="form-control datepicker" required>
                        </div>
                        <div class="form-group">
                            <label for="">Location</label>
                            <select v-model="locfilter" class="form-control">
                                <option value="">All Locations</option>
                                <option v-for="(location,i) in locations" :key="i" :value="location['Location No_']">{{location['Location No_']}} {{location['Name']}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="WasteHist">Include History \ Waste</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" name="WasteHist" type="checkbox" id="WasteHist" true-value="ON" false-value="OFF" v-model="WasteHist">
                            </div>
                        </div>
                        <div class="mt-3">
                            <input type="hidden" v-model="retry">
                            <a class="btn btn-secondary" :href="`${$store.state.api}/pdfs/MenuReport.php?lccustno=${$root.getCustomerNumber()}&txtsDate=${txtsdate}&WasteHist=${WasteHist}&locfilter=${locfilter}&retry=1${(shipTo!=null?'&ShipTo='+shipTo:'')}`" target="_blank">Submit</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
    </PageContent>
    
  </template>
  <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/FlaU1LzVojc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
  export default {
    name: 'ReportsMenu',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            disabled:false,
            locations:[],
            locfilter:'',
            WasteHist:'OFF',
            retry:1,
            txtsdate:this.$root.moment().format('MM/DD/YYYY'),
            shipTo:localStorage.getItem('shipTo')
        }
    },
    async mounted(){

        let req, res;
        res = await axios.get(`${this.$store.state.api}/custlocations?&lccustno=${this.$root.getCustomerNumber()}&show_inactive=`);
        this.locations = res;
        this.ready = true;
        await Sleep(10);
        $( ".datepicker" ).datepicker({
            onSelect:async (date,target)=>{
                this[target.input.attr('name')] = date;
            }
        });


        
    },
    methods:{
        async createReport(){

            // let req, res;
            // res = await axios.get(`${this.$store.state.api}/reports/menu?&lccustno=${this.$root.getCustomerNumber()}&querytype=${this.LOCFILTER}&retry=${this.retry}&WasteHist=${this.WasteHist}&txtsDate=${this.txtsdate}`);
            // console.log(res)
        },
    },
  }
  </script>
  <style lang="scss" scoped>
    
  </style>