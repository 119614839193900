<template>
    <PageTitle>No: {{$route.params.document_no}}, Rt/Loc: {{$route.params.route_no}}-{{$route.params.location_no}}, <span v-if="header">{{header.lclocationname}}, Date: {{header.ldordrdte}} {{$root.moment(header.ldordrdte,'MM/DD/YYYY').format('ddd')}}</span></PageTitle>
    <div class="row mb-1">
        <div class="col-md-7  text-center text-md-start">
            <a href="#" class="btn btn-secondary btn-sm me-1 ms-1 d-block d-md-inline-block mb-1 mb-lg-0" @click.prevent.stop="generateOrderCSV" v-if="!header.ll_inprod"  data-intro="Export CSV to fill out quantities for CSV upload.">Export Order CSV</a>
            <label class="btn btn-secondary btn-sm me-1 ms-1 d-block d-md-inline-block mb-1 mb-lg-0" for="uploadCSV" v-if="!header.ll_inprod" data-intro="Import CSV with quantities entered">Import Order CSV</label>
            <input autocomplete="off" autosuggest="off" type="file" id="uploadCSV" name="uploadCSV" class="d-none"  @change="uploadCSV($event); $event.target.value=''" v-if="!header.ll_inprod" />
        </div>
        <div class="col-md-5  text-center text-md-end">
            <a href="" @click.prevent.stop="$router.back" class="btn btn-secondary btn-sm d-block d-sm-inline-block ms-1 me-1">Back</a>
            <div class="dropdown  d-block d-sm-inline-block text-end mt-1 ">
                <button class="btn btn-secondary btn-sm d-block d-md-inline-block dropdown-toggle ms-1 me-1 d-block" type="button" id="printOrder" data-bs-toggle="dropdown" aria-expanded="false" data-intro="View PDF">
                    Print
                </button>
                <ul class="dropdown-menu" aria-labelledby="printList">
                    <li v-if="!$route.query.SummaryType||$route.query.SummaryType=='LOCATION'"><a target="_blank"  class="dropdown-item" :href="`${$store.state.api}/pdfs/order.php?lccustno=${$root.getCustomerNumber()}&DocumentNo=${this.$route.params.document_no}&route_no=${this.$route.params.route_no}&location_no=${$route.params.location_no}&lclocationname=${encodeURIComponent(this.header.lclocationname)}&date=${this.header.ldordrdte}&token=${$store.state.localStorage.token}&lcitemlistcode=${header.lcitemlistcode}`">PDF</a></li>
                    <li v-if="!$route.query.SummaryType||$route.query.SummaryType=='LOCATION'"><a target="_blank"  class="dropdown-item" :href="`${$store.state.api}/pdfs/orderAll.php?lccustno=${$root.getCustomerNumber()}&DocumentNo=${this.$route.params.document_no}&route_no=${this.$route.params.route_no}&location_no=${$route.params.location_no}&lclocationname=${encodeURIComponent(this.header.lclocationname)}&date=${this.header.ldordrdte}&token=${$store.state.localStorage.token}&lcitemlistcode=${header.lcitemlistcode}`">PDF (All Items)</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="bg-light border p-2 ps-3 pe-3" data-intro="Specify used on day, item list code, and edit view options like scrolling tables, item popups, grouped items, and history.">
        <div class="row align-items-end">
            <div class="col-xxl-3 col-xl-6 col-md-6 ps-2 pe-1 pb-xxl-0 pb-1">
                <label>Used on:</label>
                <select class="form-control" v-model="header.lcusedonday" :disabled="header.ll_inprod" @change="updateHeader">
                    <option value="MON">Monday</option>
                    <option value="TUE">Tuesday</option>
                    <option value="WED">Wednesday</option>
                    <option value="THU">Thursday</option>
                    <option value="FRI">Friday</option>
                    <option value="SAT">Saturday</option>
                    <option value="SUN">Sunday</option>
                </select>
            </div>
            <div class="col-xxl-4 col-xl-6 col-md-6  ps-1 pe-2 pb-xxl-0 pb-1">
                <label>Item List:</label>
                <select class="form-control" v-model="header.lcitemlistcode" @change="updateHeader"  :disabled="header.ll_inprod||!$store.state.localStorage.user['item List Change']">
                    <option  :selected="header.lcitemlistcode==''" value="">Master</option>
                    <option v-for="(code, i) in itemListCodes" :key="i" :value="code['item list code']">{{code['item list code'].trim()==''?'Master':code['item list code']}}</option>
                </select>
            </div>
            <div class="col-xxl-5 col-12 mt-1 mt-xl-0 ">
                <div class="dropdown d-inline-block mt-lg-0 mb-lg-0 mt-2">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="printList" data-bs-toggle="dropdown" aria-expanded="false">
                        Options
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="printList">
                        <li v-if="!header.ll_inprod" class="dropdown-item"><a href="#" class="text-decoration-none" @click.prevent.stop="cancelOrder">Cancel Order</a></li>
                        <li v-if="!header.ll_inprod&&$root.Impersonating()"><hr class="dropdown-divider"></li>
                        <li v-if="ready&$root.Impersonating()">
                            <modal size="sm" color="" buttonText="Edit Header" tag="a" :modalTarget="`editHeader`" title="" classes="dropdown-item">
                                <div class="modal fade modal-lg" :id="`editHeader`">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100">Order Header</h4>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                            </div>
                                            <div class="modal-body text-start">
                                                <form @submit.prevent.stop="">
                                                    <div class="form-group">
                                                        <label>Label Customer:</label>
                                                        <select v-model="header.lc_lblcust" :disabled="header.ll_inprod" class="form-control">
                                                            <option v-for="customer in customers" :value="customer.No_">{{customer.Name}} ({{customer['No_']}})</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Ship Customer No:</label>
                                                        <select v-model="header.lc_shipcust" :disabled="header.ll_inprod"  @change="getCustRoutes" class="form-control">
                                                            <option v-for="customer in shipToCusts" :value="customer.No_">{{customer.Name}} ({{customer['No_']}})</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group" >
                                                        <label>Ship-To:</label>
                                                        <select v-model="header.lc_shipto" :disabled="header.ll_inprod||!shipTos.length" class="form-control">
                                                            <option value=""></option>
                                                            <option v-for="shipTo in shipTos" :value="shipTo['ship-to code']">{{ shipTo['ship-to code'] }}</option>
                                                            <option value=""></option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Route:</label>
                                                        <select v-model="header.lcroute" :disabled="header.ll_inprod||!routes.length" class="form-control">
                                                            <option v-for="route in routes" :value="route['Route No_']">{{route['Route No_']}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Delivery:</label>
                                                        <input autocomplete="off" autosuggest="off" type="text" class="form-control" :value="`${header.ld_dlvdte} Rt: ${header.lc_delirt}-${header.lc_deliseq}`" readonly>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Make:</label>
                                                        <input autocomplete="off" autosuggest="off" type="text" class="form-control" :value="`${header.ld_makedte} Cycle: ${header.lc_makecycle} Calc: ${header.lc_makecalc}`" readonly>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Added:</label>
                                                        <input autocomplete="off" autosuggest="off" type="text" class="form-control" :value="`${header.ltadded} By: ${header.lcaddedby}`" readonly>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Modified:</label>
                                                        <input autocomplete="off" autosuggest="off" type="text" class="form-control" :value="`${header.ltmodified} By: ${header.lcmodifiedby}`" readonly>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Platform:</label>
                                                        <input autocomplete="off" autosuggest="off" type="text" class="form-control" :value="header.lcplatform" readonly>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  @click="header = {...headerClone}">Close</button>
                                                <button type="button" id="confirm" class="btn btn-primary"  data-bs-dismiss="modal" @click="updateHeader">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </modal>
                        </li>
                        
                        <li v-if="ready">

                            <modal size="sm" color="" buttonText="Edit Notes" tag="a" :modalTarget="`editLocationNotes`" title="" classes="dropdown-item">
                                <div class="modal fade modal-lg" :id="`editLocationNotes`">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100">Location Notes</h4>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                            </div>
                                            <div class="modal-body text-start">
                                                <textarea class="form-control" rows="8" v-model="header.lclocationnotes"></textarea>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  @click="header = {...headerClone}">Close</button>
                                                <button type="button" id="confirm" class="btn btn-primary"  data-bs-dismiss="modal" @click="updateLocationNotes">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </modal>
                        </li>
                        <li v-if="ready&&$root.Impersonating()&&!header.ll_inprod">
                            <modal size="sm" color="" buttonText="Add Item" tag="a" :modalTarget="`addItem`" title="" classes="dropdown-item">
                                <div class="modal fade modal-lg" :id="`addItem`">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100">Add Item</h4>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                            </div>
                                            <div class="modal-body text-start">
                                                <form @submit.prevent.stop="SalesOrderLineSave">
                                                    <div class="row">
                                                        <div class="offset-lg-2 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Item</label>
                                                                <input autocomplete="off" autosuggest="off" type="text" name="txtAdditem1" v-model="txtAdditem1" class="form-control" maxlength="6">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="form-group">
                                                                <label>Qty</label>
                                                                <input autocomplete="off" autosuggest="off" type="text" name="txtAddqty1" v-model="txtAddqty1" class="form-control" maxlegnth="6">
                                                            </div>
                                                        </div>
                                                        <div class="col-8 offset-lg-2 mt-2">
                                                            <button class="btn btn-secondary" type="submit" :disabled="(txtAdditem1==''||txtAddqty1=='')">Add</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent.stop="$emit('changeHistoryDisplay')">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </modal>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li class="dropdown-item">
                            Scroll Table 
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input  ps-4 pe-3 pt-3 pb-1" name="txtinactive" type="checkbox"  v-model="$store.state.localStorage.user['Order Entry Scroll Items']" :true-value="1" :false-value="0">
                            </div>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li class="dropdown-item">
                            Show Popup Item Info 
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input  ps-4 pe-3 pt-3 pb-1" name="txtinactive" type="checkbox"  v-model="$store.state.localStorage.user['Order Entry Use Popovers']" :true-value="1" :false-value="0" @change="$emit('togglePopovers')">
                            </div>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li class="dropdown-item">
                            Group Items 
                            <div class="form-check form-switch">
                                <input autocomplete="off" autosuggest="off" class="form-check-input  ps-4 pe-3 pt-3 pb-1" name="txtinactive" type="checkbox"  v-model="$store.state.localStorage.user['Order Entry Group Items']" :true-value="1" :false-value="0">
                            </div>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li class="dropdown-item">History</li>
                        <li class="dropdown-item d-flex justify-content-between">Daily <input autocomplete="off" autosuggest="off" v-model="$store.state.localStorage.user['Order Entry History Display']" name="lchistorytype" value="Daily" type="radio" @change="updateHeader" /></li>
                        <li class="dropdown-item d-flex justify-content-between">Weekly <input autocomplete="off" autosuggest="off" v-model="$store.state.localStorage.user['Order Entry History Display']" name="lchistorytype" value="Weekly" type="radio" @change="updateHeader" /></li>
                        <li class="dropdown-item d-flex justify-content-between">Last <input autocomplete="off" autosuggest="off" v-model="$store.state.localStorage.user['Order Entry History Display']" name="lchistorytype" value="Last" type="radio" @change="updateHeader" /></li>
                    </ul>
                </div>
                <button class="btn btn-success ms-1 mt-lg-0 mb-lg-0   btn-sm" tabindex="0" @click.prevent.stop="Done" v-if="!header.ll_inprod">Done</button>
                <ColumnChooser data-intro="Toggle the table columns to show or hide them." classes="d-inline-block" />

            </div>
        </div>
    </div>
</template>

<script>
    import Modal from '@/components/Modal.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ColumnChooser from '@/components/Orders/ColumnChooser.vue';
    import axios from 'axios';
    import csv from 'jquery-csv';
    import VueSimpleAlert from 'vue3-simple-alert';

    export default{
        name:'Header',
        props:['header', 'itemListCodes', 'items', 'updateRequests','headerClone'],
        components:{Modal, PageTitle, ColumnChooser},
        watch:{
            "header.lc_shipcust":async function(newVal){
                await this.getShipTos()
            },
            "$store.state.localStorage.user":{
                deep:true,
                handler(newVal){
                    localStorage.user = JSON.stringify(newVal);
                }
            }
        },
        data(){
            return {
                _:_,
                ready:false,
                UsedOnDay:'MON',
                customers:[],
                shipToCusts:[],
                shipTos:[],
                routes:[],
                txtAdditem1:'',
                txtAddqty1:'',
            }
        },
        async mounted(){
            let req, res;
            await this.getLabelCustomers();
            await this.getCustRoutes();
            this.getShipTos();
            this.ready = true;
            $('#printList').on('hidden.bs.dropdown', function () {
                
            })
        },
        emits:{
            togglePopovers:null,
            changeHistoryDisplay:null, 
            updateHeader:null
        },
        methods:{
            async Done(){
                let items = (this.items.filter(item=>+item.qty>0));
                let formData = new FormData();
                formData.append('items', JSON.stringify(items));
                formData.append('header', JSON.stringify(this.header));
                formData.append('document_no', this.$route.params.document_no);
                await axios.post(`${this.$store.state.api}/send_email_to_user`, formData);
                this.$router.back();
                this.$forceUpdate();
            },
            async cancelOrder(){
                let req, res;
                res = await axios.get(`${this.$store.state.api}/orderedit?lcorderno=${this.$route.params.document_no}&lcbuttonsubmit=Cancel`);
                
                this.$router.back();
            },
            async SalesOrderLineSave(){
                let req, res;
                res = await axios.get(`${this.$store.state.api}/salesorderlinesave?ordrno=${this.header.lcordrno['Nextordernumber']}&itemno=${this.txtAdditem1}&qty=${this.txtAddqty1}`);
                // if('message' in res && res.message == 'success'){
                    this.txtAdditem1 = '';
                    this.txtAddqty1 = '';
                    $('input[name="txtAdditem1"]').focus();
                // }
            },
            async getLabelCustomers(){
                return new Promise(async resolve=>{
                    if(!this.$root.Impersonating()) return;
                    let req, res;
                    res = await axios.get(`${this.$store.state.api}/labelcustomers?lccustno=${this.$root.getCustomerNumber()}`);
                    this.customers = res.customers;
                    this.shipToCusts = res.shipToCusts;
                    resolve();
                });
            },
            async getCustRoutes(){
                return new Promise(async resolve=>{
                    let req, res;

                    this.routes = [];
                    res = await axios.get(`${this.$store.state.api}/custroutes?lccustno=${this.$root.getCustomerNumber()}`);
                    this.routes = res;
                    if(!this.routes.length){
                        this.header.lcroute = '';
                    }
                    resolve();
                });
            },
            async getShipTos(){
                return new Promise(async resolve=>{
                    let req, res;
                    this.shipTos = [];
                    res = await axios.get(`${this.$store.state.api}/shiptos?&lccustno=${this.header.lc_shipcust}`);
                    this.shipTos = _.toArray(res);
                    if(!this.shipTos.length){
                        this.header.lc_shipto = '';
                    }
                    resolve();
                });
            },
            async uploadCSV(e){
                let reader = new FileReader();
                
                reader.onload = async e=>{
                    let req, res;
                    let error = false;
                    let result = csv.toArrays(e.target.result);
                    let itemsToUpdate = [];
                    let addItem = true;
                    for(let i = 0; i < result.length; i++){
                        if(i==0) continue;
                        if(result[i][2].trim()!=''){
                            let confirmQuantity = false;
                            if(typeof result[i][2] == 'string'){
                                if(!result[i][2].startsWith('#')){
                                    if(+result[i][2] > 300){
                                        confirmQuantity = true;
                                    }
                                }
                                else{
                                    result[i][2] = parseInt(result[i][2].replace('#', ''));
                                }
                            }
                            if(confirmQuantity){
                                try{
                                    addItem = await VueSimpleAlert.confirm(`Are you sure you want to order more than a quantity of 300 for ${result[i][1]}?`, `Quantity Alert`);
                                } catch(err){
                                    addItem = false;

                                }
                            }
                            let idx = (this.items.findIndex(item=>item['itemno']==result[i][0].trim()));
                            if(idx!=undefined){
                                
                                if(addItem){
                                    this.items[idx].qty = (isNaN(+result[i][2])?0:result[i][2]);
                                    itemsToUpdate.push(this.items[idx]);
                                }
                                else {
                                    // if(!addItem) result[i][2] = '';
                                }
                            }
                        }
                    }
                    if(itemsToUpdate.length){
                        let formData = new FormData();
                        formData.append('itemsToUpdate', JSON.stringify(itemsToUpdate));
                        res = await axios.post(`${this.$store.state.api}/salesorderlinessave?&ordrno=${this.$route.params.document_no}&lcbuttonsubmit=Update`, formData);
                    }
                }
                reader.readAsBinaryString(e.target.files[0])
            },
            generateOrderCSV(){
                let csvText = [[]];
                csvText[0].push('Item No');
                csvText[0].push('Item Description');
                csvText[0].push('Quantity');
                if(this.$store.state.localStorage.user['Order_Vis_PriceCode']) csvText[0].push('Price Code');
                if(this.$store.state.localStorage.user['Order_Vis_Healthy']) csvText[0].push('Healthy');
                if(this.$store.state.localStorage.user['Order_Vis_MSRP']) csvText[0].push('MSRP');
                if(this.$store.state.localStorage.user['Order_Vis_UpcCode']) csvText[0].push('UPC Code');
                if(this.$store.state.localStorage.user['Order_Vis_RemoveFromMenu']) csvText[0].push('Removed from Menu');
                if(this.$store.state.localStorage.user['Order_Vis_UserDef']) csvText[0].push('User Defined');
                if(this.$store.state.localStorage.user['Order_Vis_TraySize']) csvText[0].push('Tray Size');
                csvText[0].push('Life');
                if(this.$store.state.localStorage.user['Order_Vis_PullCode']) csvText[0].push('Label Date');
                if(this.$store.state.localStorage.user['Order_Vis_MSRPCode']) csvText[0].push('MSRP Code');
                if(this.$store.state.localStorage.user['Order_Vis_BaseCost']) csvText[0].push('Base Cost');
                if(this.$store.state.localStorage.user['Order_Vis_Extended']) csvText[0].push('Extend');
                if(this.$store.state.localStorage.user['Order_Vis_HistTot']) csvText[0].push('Hist Ttl');
                if(this.$store.state.localStorage.user['Order_Vis_Hist1']) csvText[0].push('H1');
                if(this.$store.state.localStorage.user['Order_Vis_Hist2']) csvText[0].push('H2');
                if(this.$store.state.localStorage.user['Order_Vis_Hist3']) csvText[0].push('H3');
                if(this.$store.state.localStorage.user['Order_Vis_Hist4']) csvText[0].push('H4');
                if(this.$store.state.localStorage.user['Order_Vis_Hist5']) csvText[0].push('H5');
                if(this.$store.state.localStorage.user['Order_Vis_Hist6']) csvText[0].push('H6');
                if(this.$store.state.localStorage.user['Order_Vis_Hist7']) csvText[0].push('H7');
                if(this.$store.state.localStorage.user['Order_Vis_Hist8']) csvText[0].push('H8');
                if(this.$store.state.localStorage.user['Order_Vis_Hist9']) csvText[0].push('H9');
                if(this.$store.state.localStorage.user['Order_Vis_Hist10']) csvText[0].push('H10');
                if(this.$store.state.localStorage.user['Order_Vis_GlutenFree']) csvText[0].push('Gluten Free');
                if(this.$store.state.localStorage.user['Order_Vis_Vegetarian']) csvText[0].push('Vegetarian');
                if(this.$store.state.localStorage.user['Order_Vis_Vegan']) csvText[0].push('Vegan');
                if(this.$store.state.localStorage.user['Order_Vis_Lenten']) csvText[0].push('Lenten');

                for(let i =0; i < this.items.length; i++){
                    let item = this.items[i];
                    csvText.push([]);
                    csvText[csvText.length-1].push(item['itemno']);
                    csvText[csvText.length-1].push(item['itmdesc']);
                    csvText[csvText.length-1].push(item['qty']);
                    if(this.$store.state.localStorage.user['Order_Vis_PriceCode']) csvText[csvText.length-1].push(item['pricecode']);
                    if(this.$store.state.localStorage.user['Order_Vis_Healthy']) csvText[csvText.length-1].push(item['healthy']);
                    if(this.$store.state.localStorage.user['Order_Vis_MSRP']) csvText[csvText.length-1].push(item['msrp']).toFixed(2);
                    if(this.$store.state.localStorage.user['Order_Vis_UpcCode']) csvText[csvText.length-1].push(item['UPC']);
                    if(this.$store.state.localStorage.user['Order_Vis_RemoveFromMenu']) csvText[csvText.length-1].push(item['removed']);
                    if(this.$store.state.localStorage.user['Order_Vis_UserDef']) csvText[csvText.length-1].push(item['userdef']);
                    if(this.$store.state.localStorage.user['Order_Vis_TraySize']) csvText[csvText.length-1].push(item['tray']);
                    csvText[csvText.length-1].push(item['life']);
                    if(this.$store.state.localStorage.user['Order_Vis_PullCode']) csvText[csvText.length-1].push(item['pullcode']);
                    if(this.$store.state.localStorage.user['Order_Vis_MSRPCode']) csvText[csvText.length-1].push(item['msrpcode']);
                    if(this.$store.state.localStorage.user['Order_Vis_BaseCost']) csvText[csvText.length-1].push(item['basecost']).toFixed(2);
                    if(this.$store.state.localStorage.user['Order_Vis_Extended']) csvText[csvText.length-1].push(item['EXTENDED']).toFixed(2);
                    if(this.$store.state.localStorage.user['Order_Vis_HistTot']) csvText[csvText.length-1].push(item['ttl']);
                    if(this.$store.state.localStorage.user['Order_Vis_Hist1']) csvText[csvText.length-1].push(item['H1'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist2']) csvText[csvText.length-1].push(item['H2'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist3']) csvText[csvText.length-1].push(item['H3'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist4']) csvText[csvText.length-1].push(item['H4'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist5']) csvText[csvText.length-1].push(item['H5'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist6']) csvText[csvText.length-1].push(item['H6'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist7']) csvText[csvText.length-1].push(item['H7'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist8']) csvText[csvText.length-1].push(item['H8'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist9']) csvText[csvText.length-1].push(item['H9'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_Hist10']) csvText[csvText.length-1].push(item['H10'].replace('/',' \\ '));
                    if(this.$store.state.localStorage.user['Order_Vis_GlutenFree']) csvText[csvText.length-1].push(item['glutenfree']);
                    if(this.$store.state.localStorage.user['Order_Vis_Vegetarian']) csvText[csvText.length-1].push(item['vegetarian']);
                    if(this.$store.state.localStorage.user['Order_Vis_Vegan']) csvText[csvText.length-1].push(item['vegan']);
                    if(this.$store.state.localStorage.user['Order_Vis_Lenten']) csvText[csvText.length-1].push(item['lenten']);
                    
                }  
                csvText = csv.fromArrays(csvText);
                let blob = new Blob([csvText], {type:'text/csv'});
                let url = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.class = 'd-none';
                a.download = `${this.$route.params.document_no}-${this.$route.params.route_no}-${this.$route.params.location_no}-${this.header.lclocationname}.csv`;
                a.click();
            },
            async updateHeader(){
                // console.log({...this.header, ...{lcordrno:this.header.lcordrno.Nextordernumber}});
                this.$emit('updateHeader', `${this.$store.state.api}/orderedit?lccustno=${this.$root.getCustomerNumber()}&Location=${this.$route.params.location_no}&lcroute=${this.$route.params.route_no}&OrdrDte=${this.$route.query.FirstDate}&from=SALESORDER&lcorderno=${this.$route.params.document_no}&lcusedonday=${this.header.lcusedonday}&lcbuttonsubmit=Update&header=${new URLSearchParams({...this.header, ...{lcordrno:this.header.lcordrno.Nextordernumber}}).toString()}`)
            },
            async updateLocationNotes(){
                let req, res;
                let data = {
                    'Customer No_':this.header.lccustno,
                    'Location No_':this.header['lclocation'],
                    'Notes': this.header['lclocationnotes'],
                    'Route No_':this.header.lcroute,
                };
                let formData = new FormData();
                formData.append('data', JSON.stringify(data));
                res = await axios.post(`${this.$store.state.api}/updatelocationnotes?`,formData);
            },
        },
    }
</script>
<style lang="scss" scoped>
    
</style>