<template>
    <div :class="`page-content d-flexp-3 text-start min-vh-100 overflow-hidden ${classes}`">
        <slot></slot>
    </div>
</template>
  
  <script>
  export default {
    name: 'PageContent',
    components:{},
    props: ['classes'],
    watch:{
    },
    data(){
      return {
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    
  </style>
  