<template>
    <PageContent classes="dashboard">
        <PageTitle>Dashboard</PageTitle>
        <NotReady v-if="!ready" />
        <div v-else class="container">
          <div class="row">
            <div class="col" v-html="content"></div>
          </div>
        </div>
    </PageContent>
  </template>
  
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
    // import '@/assets/css/block-library.scss'
  // import '@/assets/css/coblocks.scss'


  export default {
    name: 'Dashboard',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            content:''
        }
    },
    async mounted(){
        let req = await fetch(`https://kitchenfreshfoods.com/wp-json/wp/v2/pages/850`);
        let res = await req.json();
        this.content = res.content.rendered.replace(/\n+/gi, '');
        // let req = await fetch('https://kitchenfreshfoods.com/wp-json/wp/v2/block-renderer/core/paragraph/styles', {
        //     headers: {
        //         'Authorization': 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3d3dy5raXRjaGVuZnJlc2hmb29kcy5jb20iLCJpYXQiOjE2ODA4ODE4NDMsIm5iZiI6MTY4MDg4MTg0MywiZXhwIjoxNjgxNDg2NjQzLCJkYXRhIjp7InVzZXIiOnsiaWQiOiI0In19fQ.N4mV3HjZXOYrlUMJzlsNYaOqxquwSfC5zyWgsRfn7vQ'
        //     }
        // })
        // let res = await req.json();
        // console.log(res)
        this.ready = true;
    },
    methods:{
    },
  }
  </script>
  <style lang="scss" >
    .dashboard{
      *{
        line-height:1.4;
      }
      .container{
        max-width: 1000px !important;
      }
      img{
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        max-height: 100%;
      }
    }
  </style>
  