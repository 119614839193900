<template>
    <PageContent classes="p-5">
        <PageTitle>Enterprise Ordering</PageTitle>
        <div class="text-center" v-if="$root.getCustomerValue('No_')=='125600'">
            <router-link to="/enterprise-upload" class=" ms-1 me-1 btn btn-primary">CSV</router-link>
            <router-link to="/enterprise-upload-xml-family-express" class=" ms-1 me-1 btn btn-light">XML</router-link>
            <hr>
        </div>
        <div class="alert alert-danger" v-if="error" v-html="error"></div>
        <div class="alert alert-danger" v-if="badCustomers" v-html="badCustomers"></div>
        <div v-if="!ordersDone">
            <div v-if="!Object.keys(csvData).length">
               
                <form>
                    <div class="form-group">
                        <label for="">Upload XLSX</label>
                        <input type="file" class="form-control" @change.prevent.stop="uploadFile" accept=".xlsx">
                    </div>
                </form>
                <div class="d-flex justify-content-start align-items-center" v-if="parsing">
                    Parsing, please wait...
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <!-- {{ csvData }} -->
                <h5>Order Confirmation</h5>
                <div class="alert alert-info">Please look over the following information to verify your order.</div>
                <div class="alert alert-info" v-if="startIdx>0&&maxIdx>0">{{ (startIdx) }} out of {{ maxIdx }} orders processed</div>
                <div v-for="(customers, index1) in csvData" class="">
                    <div class="mt-2 mb-1 bg-dark text-white p-1"><span>Customer {{ customers.customer }}</span></div>
                    <div v-for="(order, order_date) in customers.orders" class="border p-2 m-2 order-review-table" :data-customer="`${customers.customer}`" :data-date="`${order_date}`" :id="`roundys-order-${customers.customer}-${(btoa(order_date)).replace('==','')}`">
                        <div class="mt-2 mb-1 p-1"><span><strong>Order Date: {{ order_date }}</strong></span></div>
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Item Number</th>
                                    <th>Quantity</th>
                                    <th>Used on Day</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(items) in order">
                                    <td>{{ items['Item Number'] }}</td>
                                    <td>{{ items.Quantity }}</td>
                                    <td>{{ items['Used on Day'] }}</td>
                                    <td>{{ items['Location Number'] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
               
                <div class="alert alert-info" v-if="startIdx>0&&maxIdx>0">{{ (startIdx) }} out of {{ maxIdx }} orders processed</div>
                <br>
                <button  class="btn btn-primary"  @click.prevent.stop="sendOrdersByDate" id="confirmButton">CONFIRM</button>
            </div>
        </div>
        <div v-else>
            <div v-for="order in ordersResponse" class="mb-4 p-3 border bg-white shadow">
                <!-- {{ order.response.invalid_dates[order.date] }} -->
                <h2 class="fs-5"><b>{{ order.date }}</b></h2>
                <h3 class="fs-6"><b>Customer</b>:{{ order.customer }}</h3>
                <hr />
                
                <div v-if="order.response.invalid_dates.hasOwnProperty(order.date)">
                    <div class="alert alert-info">{{ order.response.invalid_dates[order.date] }}</div>
                </div>
                <div v-else>
                    
                    <h3 class="fs-6"><b>Order(s)</b></h3>
                    <div v-if="Object.keys(order.response.locations_items_ordered).length">
                        <div v-for="(items, location) in order.response.locations_items_ordered">
                            <h4 class="fs-6 mb-0 " v-if="items.length"><b>Used on Day:</b> {{ $root.moment().day(items[0].used_on_day).format('dddd') }}</h4>
                            <h4 class="fs-6" ><b>Location</b>:{{ location }}<br><small><b>Ordered Location Items</b>:</small></h4>
                            <table class="table table-sm table-bordered table-striped" style="font-size: 0.85rem;">
                                <thead>
                                    <tr>
                                        <th style="padding: 2px 4px; width: 100px;">Item Number</th>
                                        <th style="padding: 2px 4px;">Item Desc</th>
                                        <th style="padding: 2px 4px;">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items">
                                        <td style="padding: 2px 4px;">{{ item.item }}</td>
                                        <td style="padding: 2px 4px;" v-html="item.desc"></td>
                                        <td style="padding: 2px 4px;">{{ item.qty }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="text-danger" v-for="(msg,location) in order.response.locations_cant_order" v-if="Object.keys(order.response.locations_cant_order).length"><b>{{ location }}</b>: {{ msg }}</div>

                    <div v-if="Object.keys(order.response.locations_items_cant_order).length" class=" mt-2 mb-2">
                        <h3 class="fs-6"><b>Invalid Item(s)</b></h3>
                        <div class="text-danger" v-for="(items,location) in order.response.locations_items_cant_order" >
                            <b>{{ location }}</b>: 
                            <div v-for="item in items"> - {{ item.item }}</div>
                        </div>
                    </div>

                    <div v-if="order.response.invalid_locations.length" class="mt-2 mb-2">
                        <h3 class="fs-6"><b>Invalid Location(s)</b></h3>
                        <div class="text-danger" v-for="location in order.response.invalid_locations"> - {{ location }}</div>
                    </div>
                </div>
                

            </div>
            <hr />
        </div>
    </PageContent>
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import VueSimpleAlert from "vue3-simple-alert";
  export default {
    name: 'UploadCSV',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            parsing:false,
            disabled:false,
            ready:false,
            error:'',
            headerError:'',
            csvData:[],
            ordersDone:false,
            ordersResponse:[],
            startIdx:0,
            maxIdx:0,
            badCustomers:``,
            days:['Monday','Tuesday','Wendesday','Thursday','Friday']
        }
    },
    async mounted(){
    },
    methods:{
        btoa:function(string){
            return btoa(string);
        },
        async uploadFile(e){
            this.error = '';
            let file = e.target.files[0];
            let fileType = file.type;
            this.badCustomers = ``;
            if(fileType!='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                this.error  = 'A XSLX file is required';
                return;
            }
            let formData = new FormData();
            formData.append('xlsx', file);
            let req, res;
            this.parsing = true;
            req = await fetch(`${this.$store.state.api}/uploadxlsx`, {
                method:'POST',
                body:formData
            });
            res = await req.json();
            let results  = [];
            let result = false;
            console.log(res)
            if(res.badCustomers.length) this.badCustomers = `${res.badCustomers.length} bad customers found on rows: <ul><li>${(res.badCustomers.map(bc=>`<b>${bc.row}:</b> Loc: ${bc.value['Location Number']}`)).join('</li><li>')}</li></ul>`;

            for(let item of res['data']){
                if(+item['Quantity']>300) {
                    try{
                        result = await VueSimpleAlert.confirm(`Are you sure you want to order more than a quantity of 300 for item number ${item['Item Number']} for location ${item['Location Number']} on ${item['Date']}?`, `Quantity Alert`);
                    }
                    catch(err){
                        result = false;
                    }
                }
                else{
                    result = true;
                }
                if(result) results.push(item);
            }
            res = _.groupBy(_.orderBy(results, ['Date','Location Number','Item Number'],['asc','asc','asc']), 'Customer No');

            this.csvData = (_.map(res, (items, customer)=>{
                items = _.groupBy(items, 'Date');
                return {customer, ...{orders:items}}
            }))
            this.parsing = false;

        },
        
        
        async sendOrdersByDate(){
            let req, res;
            if($('#confirmButton').length) $('#confirmButton').remove();
            if($('#confirmButton').length) $('#confirmButton').remove();
            let $orders = $('[id^="roundys-order-"]');
            for(let order of $orders){
                let $orderContainer = $(order);
                let date = $orderContainer.attr('data-date');
                let customer = $orderContainer.attr('data-customer');
                $orderContainer.addClass('disabled');
                $orderContainer.append(`<div class="loading-box"><div class="mb-3">Uploading Order for <b>${date}</b> Customer: <b>${customer}</b></div><div class="ms-3 spinner-border fs-5" role="status"><span class="visually-hidden">Loading...</span></div></div>`);
                let orderItems = (this.csvData.find(data=>data.customer==customer).orders[date]);
                let orderItemsByLocation = (_.groupBy(orderItems, 'Location Number'));
                for(let location in orderItemsByLocation){
                    // console.log(location, orderItemsByLocation[location])
                    let formData = new FormData();
                    formData.append('locations', JSON.stringify({[location]:orderItemsByLocation[location].map(i=>({qty:i['Quantity'],item:i['Item Number']}))}));
                    req = await fetch(`${this.$store.state.api}/enterprise_order?date=${moment(date).format('MM/DD/YYYY')}&custno=${customer}&lcusedonday=`, {
                        body:formData, method:'POST', headers:{
                            Authorization:`Basic ${btoa(`${this.$store.state.localStorage.user.APIToken}:${this.$store.state.localStorage.user.APISecret}`)}`
                        }
                    });
                    res = await req.json();
                    $orderContainer.find('.loading-box').html(`<div class="mb-3">Order for <b>${date}</b> Location <b>${location}<b> Complete!</div><div><i class="text-primary fs-3 fa-solid fa-check"></i></div>`);

                    this.ordersResponse.push({date:date, response:res, customer:customer});
                }
            }
            this.ordersDone = true;
            this.ordersResponse = _.sortBy(this.ordersResponse, obj => {
                const response = obj.response;
                const hasValues = 
                    response.invalid_locations.length > 0 ||
                    Object.keys(response.locations_cant_order).length > 0 ||
                    response.locations_items_cant_order.length > 0 ||
                    response.invalid_dates.length > 0;

                return !hasValues;
            });
            // res = await req.text();
            document.querySelector(`#main-app-container > .row > [class*=col]:nth-of-type(2)`).scrollTo(0,0);

        },
        detectDelimiter(csvData) {
            // Split the first row of the CSV data to check for different delimiters
            const firstRow = csvData.split(/\r\n|\n/)[0];
            const delimiters = [',', ';', '\t']; // Add other possible delimiters if needed

            // Count the occurrences of each delimiter in the first row
            const delimiterCounts = delimiters.map(delimiter => ({
                delimiter,
                count: (firstRow.match(new RegExp(delimiter, 'g')) || []).length
            }));

            // Sort the delimiters by their occurrence count in descending order
            delimiterCounts.sort((a, b) => b.count - a.count);

            // Return the most likely delimiter (comma if a tie or no other delimiter found)
            return delimiterCounts.length > 0 ? delimiterCounts[0].delimiter : ',';
        },
        isDateValid(date){
            let parsedDate = moment(date, 'MM/DD/YYYY').isValid()||moment(date, 'M/D/YYYY').isValid()||moment(date, 'M/DD/YYYY').isValid();
            return parsedDate;
        }
    },
  }
  </script>
  <style lang="scss" >
    .order-review-table{
        position:relative;
        &.disabled{
            position:relative;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display:block;
                z-index:10;
                background-color:rgb(#fff, 0.75);
            }
            
        }
        [data-order-day-location]{
            position:relative;
            min-height: 220px;
        }
        .loading-box{
                background-color:var(--bs-white);
                display:block;
                z-index:11;
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translateX(-50%)  translateY(20%);
                box-shadow: 0 0 5px rgba(#000,0.75);
                border-radius: 8px;
                padding: 1rem;
                text-align:center;
            }
    }
  </style>