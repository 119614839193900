<template>
    <PageContent classes="">
        <NotReady v-if="!ready" />
        <div class="login bg-light d-flex align-items-lg-center justify-content-center pt-5 pb-5 w-100" v-else>
            <div class="container ">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-lg-6 offset-lg-3">
                        <div class="border bg-white shadow border rounded text-start p-lg-5 p-2 ps-3 pe-3">
                            <div class="text-center"><img alt="Kitchen Fresh Foods" src="@/assets/kf-foods-logo-mobile.png" id="logo" class="mw-100 mh-100" /></div>
                            <hr>
                            <div class="alert alert-danger" v-if="error">{{error}}</div>
                            <form method="post" @submit.prevent="login" autocomplete="off" autosuggest="off" >
                                <fieldset :disabled="disabled">
                                    <div class="form-group mt-3 mb-2">
                                        <label for="">Customer Number</label>
                                        <input autocomplete="off" autosuggest="off" type="text" v-model="customer_no" class="form-control">
                                    </div>
                                    <div class="form-group mt-3 mb-2">
                                        <label for="">Username</label>
                                        <input autocomplete="off" autosuggest="off" type="text" v-model="user_code" class="form-control">
                                    </div>
                                    <div class="form-group mb-3 mt-2">
                                        <label for="">Password</label>
                                        <input autocomplete="off" autosuggest="off" type="password" v-model="password" class="form-control">
                                    </div>
                                    <div class="mt-1">
                                        <button type="submit" class="btn btn-primary">Login</button>
                                    </div>
                                </fieldset>
                            </form>
                            <NotReady v-if="!ready" />
                            <div class="text-center  mt-2" id="schedule" v-else v-html="content"></div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </PageContent>
    
  </template>
  
  <script>
  // @ is an alias to /src

    import NotReady from '@/components/NotReady.vue';
    import PageContent from '@/components/PageContent.vue';
    import axios from 'axios';

  export default {
    name: 'Login',
    components: {
        NotReady,PageContent
    },
    data(){
        return {
            ready:false,
            disabled:false,
            content:  '',
            // customer_no:'',
            // user_code:'CALEB',
            // password:'test' ,
            // customer_no:'107520',
            // user_code:'505',
            // password:'beef',
            customer_no:'',
            user_code:'',
            password:'',
            error:''
        }
    },
    async mounted(){
        // console.log(this.$store.state.api)

        axios.get(`${this.$store.state.api}/checkstatus`);
        let req = await fetch(`https://kitchenfreshfoods.com/wp-json/wp/v2/pages/1062`);
        let res = await req.json();
        this.content = res.content.rendered.replace(/\n+/gi, '');
        this.ready = true;

    },
    methods:{
        login:async function(e){
            let req,res;
            this.disabled = true;
            this.error='';
            let formData = new FormData();
            formData.append('customer_no',this.customer_no);
            req = await fetch(`${this.$store.state.api}/login?`, {
                method:'POST',
                body:formData,
                headers:{
                    'Authorization':`Basic ${btoa(this.user_code+':'+this.password)}`
                },
            });
            res = await req.json();
            this.disabled = false;
            if(res['message']=='success'){
                if(res.user!=null){
                    res.user['Created On'] = res.user['Created On'].date;
                    res.user['Expires On'] = res.user['Expires On'].date;
                    res.user['Last Logon'] = res.user['Last Logon'].date;
                    localStorage.user = JSON.stringify(res.user);
                    this.$store.state.localStorage.user = res.user;
                    localStorage.token = res.token;
                    localStorage.sessionId = res.id;
                }
                if(res.customer!=null){
                    localStorage.customer = JSON.stringify(res.customer); 
                    localStorage.token = res.token;
                    this.$store.state.localStorage.customer = res.customer;
                }

                this.$router.replace('/dashboard/');
            }
            else if(res['message'] == 'error'){
                this.error = res.messageTxt;
                this.disabled = false;
            }
        }
    },
  }
  </script>
  <style lang="scss" scoped>
    @media (max-width: 992px){
        .page-content{
            margin-top: -65px;
        }
        .login{
            min-height: calc(100vh) !important;
            height: calc(100vh + 65px) !important;
            padding-top: 1rem !important;
            #logo{
                width: 150px;
                height: 100px;
                object-fit:scale-down;
                object-position: 50% 50%;
            }
            #schedule{
                // transform: scale(0.7);
                // transform-origin: 50% 0;
                font-size: 0.8rem;
            }
        }
    }
    @media (min-width: 992px){

            .login{
                #logo{
                    width: 250px;
                    height: 100px;
                    object-fit:scale-down;
                    object-position: 50% 50%;
                }
            }
            
    }
    
    
    .page-content{
        overflow-y:scroll !important;
        >.login{
            min-height: calc(100vh);
        }
    }
  </style>