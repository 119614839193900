<template>
    <i class="fa-solid fa-question" title="Help" @click="triggerHelp"></i>
</template>

<script>
    export default{
        name:'Help',
        props:['target'],
        data(){
            return {
            }
        },
        async mounted(){
        },
        methods:{
            async triggerHelp(){
                if(this.target!=undefined){ 
                    console.log(this.target)
                    if(this.target.startsWith('nav#slideout-nav')){
                        $('nav#slideout-nav').css('transform','none');
                        await Sleep(250);
                        var intro = introJs(this.target);
                        intro.start();
                        intro.onbeforeexit(()=>{
                            $('nav#slideout-nav').css('transform','');

                        });
                    }
                    else{
                        var intro = introJs(this.target);
                        intro.start();
                    }
                }
                else introJs('.page-content').start();

            }
        }
    }
</script>
<style lang="scss" scoped>
    i{
        height: 35px;
        width: 35px;
        vertical-align:middle;
        display:inline-flex;
        align-items:center;
        justify-content:center;
        float:right;
        font-size: 1.25rem;
        background-color:#224092;
        color:#fff;
        border-radius: 50%;
        opacity: 0.5;
        transition: all 0.25s;
        cursor:pointer;
        &:hover{
            opacity: 1;
        }
    }
</style>