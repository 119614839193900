<template>
    <slot name="header"></slot>
</template>
  
  <script>
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import TableCell from '@/components/Table/TableCell.vue';
  export default {
    name: 'TableHead',
    components: {Table, TableRow, TableCell},
    props: ['classes', 'style'],
    watch:{
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    
  </style>
  