import { createStore } from 'vuex'

export default createStore({
  state: {
    account_status:`<div style=" margin-left: 8px; font-size: 10px; width: 14px; height: 14px; top: 3px;" class="spinner-border position-absolute end-5 " role="status">
    <span class="visually-hidden">Loading...</span>
  </div>`,
    shipTos:[],
    open_invoices: [],
    shipTo:(localStorage.getItem('shipTo')==undefined||localStorage.getItem('shipTo')==''?'':localStorage.getItem('shipTo')),
    url:(location.origin.startsWith('https://orders.kffoods')?'https://orders.kffoods.com':'https://dev.kffoods.com'),
    // api:'https://orders.kffoods.com/api',
    // api:'https://dev.kffoods.com/api',
    api:(location.origin.startsWith('https://orders.kffoods')?'https://orders.kffoods.com/api':'https://dev.kffoods.com/api'),
    is_dev:(location.origin.startsWith('https://orders.kffoods')?false:true),
    localStorage:{
      user:(localStorage.getItem('user')==null?undefined:tryJSONParse(localStorage.getItem("user"))), 
      customer:(localStorage.getItem('customer')==null?undefined:tryJSONParse(localStorage.getItem("customer"))),
      token:(localStorage.getItem('token')==null?undefined:
      tryJSONParse(localStorage.getItem("token")))
    }, 
    sessionStorage:{
      customer:(sessionStorage.getItem('customer')==null?undefined:tryJSONParse(sessionStorage.getItem("customer")))
    },
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
