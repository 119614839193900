<template>
    <PageContent classes="dashboard">
        <PageTitle>Training Videos</PageTitle>
        <NotReady v-if="!ready" />
        <div v-else v-html="content"></div>
    </PageContent>
  </template>
  
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
    // import '@/assets/css/block-library.scss'
  // import '@/assets/css/coblocks.scss'


  export default {
    name: 'Dashboard',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            content:''
        }
    },
    async mounted(){
        let req = await fetch(`https://kitchenfreshfoods.com/wp-json/wp/v2/pages/962`);
        let res = await req.json();
        this.content = res.content.rendered.replace(/\n+/gi, '');
        // let req = await fetch('https://kitchenfreshfoods.com/wp-json/wp/v2/block-renderer/core/paragraph/styles', {
        //     headers: {
        //         'Authorization': 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3d3dy5raXRjaGVuZnJlc2hmb29kcy5jb20iLCJpYXQiOjE2ODA4ODE4NDMsIm5iZiI6MTY4MDg4MTg0MywiZXhwIjoxNjgxNDg2NjQzLCJkYXRhIjp7InVzZXIiOnsiaWQiOiI0In19fQ.N4mV3HjZXOYrlUMJzlsNYaOqxquwSfC5zyWgsRfn7vQ'
        //     }
        // })
        // let res = await req.json();
        // console.log(res)
        this.ready = true;
    },
    methods:{
    },
  }
  </script>
  <style lang="scss">
    @import '../assets/css/block-library.scss';
    @import '../assets/css/coblocks.scss';
    *{
      line-height:1.4;
    }
    .is-style-{
      &success{
        background-color: #d0eac4;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 1rem;
        color: #154a28;
      }
      &error{
        background-color: #ffdede;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 1rem;
        color: #8b343c;
      }
      &warning{
        background-color: #fbe7dd;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 1rem;
        color: #8a4b30;
      }
      &info{
        background-color: #d6efee;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 1rem;
        color: #094264;
      }
    }
  </style>
  