<template>
    <div id="item-maint-column-chooser" class="dropdown d-inline-block">
        <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="columnChooser" data-bs-toggle="dropdown" aria-expanded="false">
            Column Chooser
        </button>
        <fieldset>
            <ul class="dropdown-menu text-start" aria-labelledby="columnChooser">
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="image" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Image']" :true-value="1" :false-value="0">
                        <label for="image"> Image</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="description" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Description']" :true-value="1" :false-value="0">
                        <label for="description"> Description</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="price_code" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_PriceCode']" :true-value="1" :false-value="0">
                        <label for="price_code"> Price Code</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$store.state.localStorage.user['Admin']">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="msrp" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_MSRP']" :true-value="1" :false-value="0">
                        <label for="msrp"> MSRP</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="user_defined" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_UserDef']" :true-value="1" :false-value="0">
                        <label for="user_defined"> User Defined</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="shelflife" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_ShelfLife']" :true-value="1" :false-value="0">
                        <label for="shelflife"> Shelf Life</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="healthy" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Healthy']" :true-value="1" :false-value="0">
                        <label for="healthy"> Healthy</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$root.isKfEmployee()">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="restricted" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Restricted']" :true-value="1" :false-value="0">
                        <label for="restricted"> Restricted</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="show_on_menu" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_ShowOnMenu']" :true-value="1" :false-value="0">
                        <label for="show_on_menu"> Show on Menu</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$root.isKfEmployee()">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="authorized" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Authorized']" :true-value="1" :false-value="0">
                        <label for="authorized"> Authorized</label>
                    </div>
                </li>
                <li class="dropdown-item" v-if="$store.state.localStorage.user['Admin']">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="base_cost" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_BaseCost']" :true-value="1" :false-value="0">
                        <label for="base_cost"> Base Cost</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="vegetarian" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Vegetarian']" :true-value="1" :false-value="0">
                        <label for="vegetarian"> Vegetarian</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="vegan" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Vegan']" :true-value="1" :false-value="0">
                        <label for="vegan"> Vegan</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="gluten_free" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_GlutenFree']" :true-value="1" :false-value="0">
                        <label for="gluten_free"> Gluten Free</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="lenten" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Lenten']" :true-value="1" :false-value="0">
                        <label for="lenten"> Lenten</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="pork" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_Pork']" :true-value="1" :false-value="0">
                        <label for="pork"> No Pork</label>
                    </div>
                </li>
                <li class="dropdown-item">
                    <div class="form-check form-switch">
                        <input autocomplete="off" autosuggest="off" id="upc_code" class="form-check-input  ps-4 pe-3 pt-3 pb-1" role="switch" type="checkbox" v-model="$store.state.localStorage.user['ItmLst_Vis_UpcCode']" :true-value="1" :false-value="0">
                        <label for="upc_code"> UPC Code</label>
                    </div>
                </li>
            </ul>
        </fieldset>
        
    </div>
  </template>
  <script>

  import { Field, Form, ErrorMessage, defineRule } from 'vee-validate';
  defineRule('required', (value, [target], ctx) => {
    if(!value) return 'Value is required';
    return true;
  });

  export default {
    name: 'ColumnChooser',
    components:{Field ,Form, ErrorMessage},
    props: [],
    watch:{
    },
    computed:{
    },
    data(){
        return {
        }
    },
    async mounted(){
        $('#item-maint-column-chooser.dropdown .dropdown-menu').on('click',  (e) => {
            e.stopPropagation();
        });
    },
    methods:{
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .field-error{
      font-size: 0.75rem;
    }
  </style>
  