<template>
    <PageContent>
        <PageTitle>Kitchen Fresh Users <Help /></PageTitle>
        <NotReady v-if="!ready" />
        
        <div class="container-fluid text-start" v-else>
            <div class="row">
                <div class="col">
                    <div class="text-end mb-3">
                        <a :href="`/users/create`" class="btn btn-primary ms-1">New User</a>
                    </div>
                    <input type="text" v-model="searchText" class="form-control d-block d-lg-none  mb-2" placeholder="Search">
                    <ScrollingTable :scroll="1"  data-title="Users"  :style="`position:sticky; top: 0; left: 0`">
                        <template v-slot:table-header>
                            <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                                <thead>
                                    <tr data-intro="Use the arrow to sort each column alphabetically or numerically.">
                                        <th width="60" class="text-white" style="background-color: var(--bs-gray-700);">
                                            <small>User Name</small>
                                            <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='User Code'" :class="`${(filter.orderBy=='User Code'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='User Code'?'':'rotate')}`">
                                        </th>
                                        <th width="60" class="text-white" style="background-color: var(--bs-gray-700);">
                                            <small>Name</small>
                                            <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Name'" :class="`${(filter.orderBy=='Name'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Name'?'':'rotate')}`">
                                        </th>
                                        <th width="60" class="text-white" style="background-color: var(--bs-gray-700);">
                                            <small>Route</small>
                                            <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Mapped Route No Order'" :class="`${(filter.orderBy=='Mapped Route No Order'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Mapped Route No Order'?'':'rotate')}`">
                                        </th>
                                        <th width="60" class="text-white" style="background-color: var(--bs-gray-700);"></th>
                                    </tr>
                                    <tr data-intro="Use these inputs and toggles to filter the results of the table.">
                                        <th style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['User Code']"></th>
                                        <th style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Name']"></th>
                                        <th style="background-color:var(--bs-gray-500)"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['Mapped Route No']"></th>
                                        <th style="background-color:var(--bs-gray-500)"></th>
                                    </tr>
                                </thead>
                            </table>
                        </template>
                        <template v-slot:table-body>
                            
                            <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                                <tbody>
                                    <tr v-for="(user, i) in _.filter(_.orderBy(users, filter.orderBy, filter.order), r=>{
                                        return  new RegExp(search['Name'].toLowerCase().trim(), 'gi').test(r['Name'].toLowerCase())&&
                                                new RegExp(search['User Code'].toLowerCase().trim(), 'gi').test(r['User Code'].toLowerCase())&&
                                                r['Mapped Route No'].toLowerCase().startsWith(search['Mapped Route No'].toLowerCase().trim())
                                        })"
                                    >
                                        <td width="60" class="text-start">
                                            {{ user['User Code'] }}
                                            <div>
                                                <span class="badge bg-secondary me-1" v-if="user['Admin']">Admin</span>
                                                <span class="badge bg-secondary me-1" v-if="user['Status']==0||user['Status']==1">Active</span>
                                            </div>
                                        </td>
                                        <td width="60" class="text-start">{{user['Name']}}</td>
                                        <td width="60" class="text-start">{{user['Mapped Route No']}}</td>
                                        <td width="60" class="text-end ">
                                            <a :href="`/users/${user['User Code']}/edit`" class="me-1  mt-1 mb-1 btn btn-sm btn-primary">Edit</a>
                                            <a @click.prevent.stop="deleteUser(user)" class="me-1  mt-1 mb-1 btn btn-sm btn-danger">Delete</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </ScrollingTable>
                    
                </div>
            </div>
        </div>
    </PageContent>
  </template>
  
  <script>
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ScrollingTable from '@/components/Table/ScrollingTable.vue';
    import Help from '@/components/Help.vue';
    import VueSimpleAlert from 'vue3-simple-alert';
import axios from 'axios';

  export default {
    name: 'Users',
    components: {PageContent, PageTitle,NotReady,ScrollingTable, Help},
    data(){
        return {
            _:_,
            ready:false,
            disabled:false,
            customer:{},
            allUsers:[],
            users:[],
            searchText:'',
            filter:{
                orderBy:'User Code',
                order:'asc'
            },
            search:{
                searchString:['true'],
                'User Code':'',
                'Name':'',
                'Mapped Route No':'',
            },
            pagination:{
                search:'',
                pages:0,
                per_page:20,
                page:0,
            },
        }
    },
    
    async mounted(){
        await this.getUsers();
        this.pagination.page = 1;
        this.ready = true;
      $('#main-app-container > .row > [class*="col"]:nth-of-type(2)').on('scroll', (elm)=>{
            if(elm.target.scrollTop > (elm.target.scrollHeight - $(elm.target).height()-50)){
                this.loadMore();
            }
        });
    },
    computed:{
        users:function(){
            return _.filter(_.orderBy(this.allUsers,this.filter.orderBy, this.filter.order), c=>{
                let searchText = this.searchText.replace(/\./gi, '').toLowerCase();
                let usercode = c['User Code'].replace(/\./gi, '').toLowerCase().trim();
                let name = c['Name'].replace(/\./gi, '').toLowerCase().trim();
                let mappedrouteno = (c['Mapped Route No']).toString().trim();
                return usercode.startsWith(searchText)||name.startsWith(searchText)||mappedrouteno.startsWith(searchText);
            });
        }
    },
    methods:{
        atob:function(string){ return atob(string); },
        btoa:function(string){ return btoa(string); },
        async getUsers(){
            return new Promise(async resolve=>{
                let res = await axios.get(`${this.$store.state.api}/users?lccustno=`);
                this.allUsers = res;
                this.allUsers = this.allUsers.map(u=>({...u, ...{'Mapped Route No Order':+u['Mapped Route No']}}));
                this.pagination.page = 0;
                resolve();
            })
        },
        async deleteUser(user){
            let result;
            try{
                result = await VueSimpleAlert.confirm(`Are you sure you want to delete the user: ${user.Name}?`, `Delete Date`);
            } catch(err){
                result = false;
            }
            if(!result) return;

            user['Deleted']='1';
            this.ready = false;
            let req, res;
            let formData = new FormData();
            formData.append('user', JSON.stringify(user));
            res = await axios.post(`${this.$store.state.api}/delete_user?`, formData);

            if(res.message=='success'){
                await this.getUsers();
                this.ready = true;
            }
            else if(res.message=='error') this.ready = true;

        },
    },
  }
  </script>
<style lang="scss" scoped>
.is-responsive{
    @media (max-width:  992px){
      [data-title="Users"]{
        .table-body-parent{
          table.table{
            // border: 5px solid red !important
            display:block;
            > tbody{
              display:block;
              tr{
                display:block;
                margin-bottom: 1rem;
                td{
                  display:block;
                  width: 100%;
                  &:nth-of-type(1){
                    &:before{
                      content:  'Customer Name: ';
                      font-weight:bold;
                    }
                  }
                  &:nth-of-type(2){
                    &:before{
                      content:  'Name: ';
                      font-weight:bold;
                    }
                  }
                  &:nth-of-type(3){
                    &:before{
                      content:  'Route: ';
                      font-weight:bold;
                    }
                    &:empty{
                        display:none;
                    }
                  }
                  &:last-child{
                    .btn{
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
}

</style>