<template>
    <header id="mobile-header" v-if="$store.state.localStorage.user" class="bg-white shadow d-flex align-items-center ps-2 pe-2 border-bottom d-lg-none">
      <div class="w-50  d-flex align-items-center justify-content-start pt-1 pb-1"><img alt="Kitchen Fresh Foods" src="@/assets/kf-foods-logo-mobile.png" class="mw-100 mh-100" /></div>
      <div class="w-50 d-flex align-items-center justify-content-end">
        <label for="slideOut-nav-trigger">
            <svg xmlns="http://www.w3.org/2000/svg" style="" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
        </label>
      </div>
    </header>
    <input autocomplete="off" autosuggest="off" type="checkbox" class="d-none" name="slideOut-nav" id="slideOut-nav-trigger">
    <label for="slideOut-nav-trigger" id="slideout-nav-bg"></label>
    <nav id="slideout-nav" class="bg-white border-left shadow position-fixed top-0 bottom-0 end-0">
        <div>
            <div class="text-start"><b>Logged in as:</b> {{ $store.state.localStorage.user['User Code'] }}</div>
            <hr class="mt-4 mb-4">
            <About><template scope="{formData}" /></About>
            <hr class="mt-4 mb-4">
            <Links />
            <span class="position-relative d-inline-block"><Help :target="`nav#slideout-nav > div > nav`" /></span>
        </div>
    </nav>
  </template>

  
  <script>
  import About from '@/components/SideBar/About.vue'
  import Links from '@/components/SideBar/Links.vue'
  import Help from '@/components/Help.vue'

  export default {
    name: 'MobileHeader',
    components: {
        About,Links,Help
    },
    props: {
      customer:{}
    },
    async mounted(){
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    #mobile-header{
        height: 65px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index:9;
        
        > div{
            height: 100%;
            &:nth-of-type(1){
                text-align:left;
                img{
                    max-width: 100%;
                    max-height: 100%;
                    width: 150px;
                    height: 100%;
                    object-fit:scale-down;
                    object-position: 0 50%;
                }
            }
            &:nth-of-type(2){
                text-align:right;
                > label{
                    display:inline-block;
                    padding: 8px;
                    cursor:pointer;
                }
                svg{
                    cursor:pointer;
                    height: 25px;
                    width: 25px;
                    path{
                        fill:#000;
                    }
                }
            }
        }
    }
    #slideout-nav{
        z-index:199;
        width: 85%;
        max-width: 360px;
        transform: translateX(100%);
        opacity: 0;
        transition: all 0.25s;
        overflow-y:scroll;
        overflow-x:hidden;
        > div{
            > nav{
            }
        }
    }
    #slideout-nav-bg{
        background-color:rgba(#000, 0.25);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index:85;
        touch-action:none;
        pointer-events: none;
        opacity: 0;
        transition: all 0.25s;
    }
    input[type="checkbox"][id="slideOut-nav-trigger"]{
        &:checked{
            & + #slideout-nav-bg{
                opacity: 1;
                touch-action: initial;
                pointer-events: initial;
                & +  #slideout-nav{
                    transform: translateX(0%);
                    opacity: 1;
                }
            }
            // & + #slideout-nav{
                // transform: translateX(0%);
                // opacity: 1;
            // }
        }
    }
  </style>
  