<template>
    <PageContent class="mainmenu min-vh-100  d-flexp-3">
        <PageTitle>Order Add / Cut</PageTitle>
        <NotReady v-if="!ready" />
        <div v-else>
            <h5>Default</h5>
            <fieldset :disabled="disabled">
                <ScrollingTable :scroll="false" data-title="Default">
                    <template v-slot:table-header>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                            <thead>
                                <tr>
                                    <th class="text-white" width="200" style="background-color: var(--bs-gray-700);">Day of Wk</th>
                                    <th class="text-white" width="200" style="background-color: var(--bs-gray-700);">Menu<br>Cut Off</th>
                                    <th class="text-white" width="200" style="background-color: var(--bs-gray-700);">Menu<br>Cut Time</th>
                                    <th class="text-white" width="100" style="background-color: var(--bs-gray-700);"></th>
                                </tr>
                            </thead>
                        </table>
                    </template>
                    <template  v-slot:table-body>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                            <tbody>
                                <tr v-for="(cut_date, i) in default_cut_dates" :key="i">
                                    <td class="align-middle input-group-sm" width="200">
                                        <select class="form-control" v-model="cut_date.DOW" disabled="disabled">
                                            <option value="1">Sunday</option>
                                            <option value="2">Monday</option>
                                            <option value="3">Tuesday</option>
                                            <option value="4">Wednesday</option>
                                            <option value="5">Thursday</option>
                                            <option value="6">Friday</option>
                                            <option value="7">Saturday</option>
                                        </select>
                                    </td>
                                    <td class="align-middle input-group-sm" width="200">
                                        <select class="form-control" v-model="cut_date['Menu Cut Day']"  :disabled="!cut_date.editable">
                                            <option>-2</option>
                                            <option>-3</option>
                                            <option>-4</option>
                                            <option>-5</option>
                                            <option>-6</option>
                                            <option>-7</option>
                                            <option>-8</option>
                                            <option>-9</option>
                                        </select>
                                    </td>
                                    <td class="align-middle input-group-sm" width="200"><input autocomplete="off" autosuggest="off" type="text" class="form-control timepicker-default"  :disabled="!cut_date.editable" v-model="cut_date['Menu Cut Time']" :id="`menu_cut_time_${i}`" /></td>
                                    <td class="align-middle input-group-sm" width="100">
                                        <a href="#" class="btn btn-secondary btn-sm mb-0" v-if="!cut_date.editable" @click.prevent.stop="editDefaultTime(cut_date,i)">Modify</a>
                                        <button @click.prevent.stop="Save(cut_date, null, default_cut_datesBackup[i].DOW)" v-else class="btn btn-secondary btn-sm">Save</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </ScrollingTable>
                <br>
                <h5>Overrides</h5>
                <ScrollingTable :style="`position:sticky; top: 0; left: 0`" :scroll="true"  data-title="Overrides">
                    <template v-slot:table-header>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                            <thead class="position-relative">
                                <tr>
                                    <th class="text-white" width="100" style="background-color: var(--bs-gray-700);">Order Date</th>
                                    <th class="text-white" width="100" style="background-color: var(--bs-gray-700);">Day of Wk</th>
                                    <th class="text-white" width="100" style="background-color: var(--bs-gray-700);">Menu<br>Cut Off</th>
                                    <th class="text-white" width="100" style="background-color: var(--bs-gray-700);">Menu<br>Cut Time</th>
                                    <th class="text-white" width="100" style="background-color: var(--bs-gray-700);"></th>
                                </tr>
                            </thead>
                        </table>
                    </template>
                    <template v-slot:table-body>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                            <tbody>
                                <tr v-for="(cut_date, i) in custom_cut_dates" :key="i">
                                    <td class="align-middle input-group-sm" width="100">
                                        {{cut_date['Document Date']}}
                                    </td>
                                    <td class="align-middle input-group-sm" width="100">
                                        {{['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'][parseInt(cut_date.DOW)-1]}}
                                    </td>
                                    <td class="align-middle input-group-sm" width="100">
                                        {{cut_date['Menu Cut Day']}}
                                    </td>
                                    <td class="align-middle input-group-sm" width="100">
                                        {{cut_date['Menu Cut Time']}}
                                    </td>
                                    <td class="align-middle input-group-sm" width="100">
                                        <a href="#" class="btn btn-gray btn-sm mb-0" @click.prevent.stop="deleteItem($event, cut_date)"><i class="fa-solid fa-times text-danger"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </ScrollingTable>
                <br>
                <h5>Add Cut Date</h5>
                <ScrollingTable  :scroll="false" data-title="AddCutDate">
                    <template v-slot:table-header>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                            <thead>
                                <tr>
                                    <th width="200" class="text-white" style="background-color: var(--bs-gray-700);">Order Date</th>
                                    <th width="200" class="text-white" style="background-color: var(--bs-gray-700);">Day of Wk</th>
                                    <th width="200" class="text-white" style="background-color: var(--bs-gray-700);">Menu<br>Cut Off</th>
                                    <th width="200" class="text-white" style="background-color: var(--bs-gray-700);">Menu<br>Cut Time</th>
                                    <th width="200" class="text-white" style="background-color: var(--bs-gray-700);"></th>
                                </tr>
                            </thead>
                        </table>
                    </template>

                    <template v-slot:table-body>
                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                            <tbody>
                                <tr>
                                    <td width="200" class="align-middle input-group-sm">
                                        <input autocomplete="off" autosuggest="off" type="text" :id="`document_date_create_day`"  class="form-control datepicker-new" v-model="create_cut_day['Document Date']" />
                                    </td>
                                    <td width="200"  class="align-middle input-group-sm">
                                        {{$root.moment(create_cut_day['Document Date']).format('dddd')}}
                                    </td>
                                    <td  width="200" class="align-middle input-group-sm">
                                        <select class="form-control"  v-model="create_cut_day['Menu Cut Day']">
                                            <option value="-2">-2</option>
                                            <option value="-3">-3</option>
                                            <option value="-4">-4</option>
                                            <option value="-5">-5</option>
                                            <option value="-6">-6</option>
                                            <option value="-7">-7</option>
                                            <option value="-8">-8</option>
                                            <option value="-9">-9</option>
                                        </select>
                                    </td>
                                    <td width="200"  class="align-middle input-group-sm">
                                        <input autocomplete="off" autosuggest="off" type="text" :id="`menu_cut_time_new`" class="form-control timepicker-new" v-model="create_cut_day['Menu Cut Time']" />
                                    </td>
                                    <td width="200"  class="align-middle input-group-sm">
                                        <a href="#" class="btn btn-secondary btn-sm mb-0" @click.prevent.stop="addCutTime">Add</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </ScrollingTable>
            </fieldset>
        </div>
    </PageContent>
  </template>
  
  <script>
  import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import TableHead from '@/components/Table/TableHead.vue';
    import TableCell from '@/components/Table/TableCell.vue';
    import ScrollingTable from '@/components/Table/ScrollingTable.vue';
    import Modal from '@/components/Modal.vue';
    import VueSimpleAlert from 'vue3-simple-alert';
import axios from 'axios';

  export default {
    name: 'OrderAddCut',
    components: {PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead, Modal, ScrollingTable},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            ready:false,
            disabled:false,
            default_cut_dates:[],
            default_cut_datesBackup:[],
            custom_cut_dates:[],
            custom_cut_datesBackup:[],
            days_of_week:['Monday, Tuesday','Wednesday','Thursday','Friday'],
            create_cut_day:{ 
                "Document Date": this.$root.moment().format('MM/DD/YYYY'),
                "DOW": 2, 
                "Add User": `${this.$store.state.localStorage.user['User Code']}`, 
                "Add Date": this.$root.moment().format('MM/DD/YYYY'), 
                "Add Time": this.$root.moment().format('HH:mm'), 
                "Modify User": `${this.$store.state.localStorage.user['User Code']}`, 
                "Modify Date": '01/01/1900', 
                "Modify Time": "", 
                "No-Work Day": 0, 
                "Menu Cut Day": "-2", 
                "Menu Cut Time": "00:00" 
            },
        }
    },
    async mounted(){
        await this.getCutTimes();

        this.ready = true;
        await Sleep(10);
        await this.setSelectors();
    },
    methods:{
        async editDefaultTime(cut_date, i){
            this.default_cut_dates[i].editable = true;

        },
        async setSelectors(){
            return new Promise(async resolve=>{
                $( ".datepicker-new" ).datepicker({
                    minDate:new Date(),
                    onSelect:async (date,target)=>{
                        this.create_cut_day['Document Date'] = this.$root.moment(date).format('MM/DD/YYYY');
                        this.create_cut_day['DOW'] = (parseInt(moment(date).format('d'))+1);
                        this.create_cut_day['Add Date'] = this.$root.moment().format('MM/DD/YYYY');
                        this.create_cut_day['Add Time'] = this.$root.moment().format('HH:mm');
                        this.create_cut_day['Modify Date'] = this.$root.moment().format('MM/DD/YYYY');
                        this.create_cut_day['Modify Time'] = this.$root.moment().format('HH:mm');
                        this.create_cut_day['Modify User'] = this.$store.state.localStorage.user['User Code'];

                    },
                    beforeShowDay:(date)=>{
                        let customDates = this.custom_cut_dates.map(d=>d['Document Date']);
                        let dateFound = (customDates.find(cd=>cd==this.$root.moment(date).format('MM/DD/YYYY')));
                        if(dateFound==undefined){
                            return [true];
                        }
                        return [false];
                    }
                });
                $( ".datepicker" ).datepicker({
                    minDate:new Date(),
                    onSelect:async (date,target)=>{
                        let index = parseInt((target.input.attr('id').match(/\d+/gi))[0]);
                        if(this.custom_cut_dates[index]==undefined) return;

                        let oldDate = this.custom_cut_datesBackup[index]['Document Date'];
                        let DOW = this.custom_cut_datesBackup[index]['DOW'];


                        this.custom_cut_dates[index]['Document Date'] = date;
                        this.custom_cut_dates[index].DOW = (parseInt(moment(date).format('d'))+1);
                        await this.Save(this.custom_cut_dates[index], oldDate, DOW);
                    }
                });
                $( ".timepicker-default" ).timepicker({
                    timeFormat:'H:i',
                    dynamic:false
                });
                $( ".timepicker-new" ).timepicker({
                    timeFormat:'H:i',
                    dynamic:false
                });
                $( ".timepicker-default" ).on('change',async (e)=>{
                    if(!e.target.hasAttribute('id')) return;
                    let index = parseInt((e.target.id.match(/\d+/gi))[0]);
                    if(this.default_cut_dates[index]==undefined) return;
                    this.default_cut_dates[index]['Menu Cut Time'] = e.target.value;
                    // await this.Save(this.custom_cut_dates[index]);
                    e.stopPropagation();
                })
                
                $( ".timepicker-new" ).on('change',async (e)=>{
                    this.create_cut_day['Menu Cut Time'] = e.target.value;
                })
                resolve();
            })
        },
        async getCutTimes(){
            return new Promise(async resolve=>{
                let req,  res;
                res = await axios.get(`${this.$store.state.api}/orderaddcut?func=DISPLAY`);

                this.default_cut_dates = res.default;
                this.default_cut_dates = this.default_cut_dates.map(cut_date=>({...cut_date, ...{
                    'Modify Date': this.$root.moment(cut_date['Modify Date'].date).format('MM/DD/YYYY'),
                    'Add Date': this.$root.moment(cut_date['Add Date'].date).format('MM/DD/YYYY'),
                    'Menu Cut Day': Number(cut_date['Menu Cut Day']).toFixed(0).toString(),
                    'editable':false,
                    'Document Date': this.$root.moment(cut_date['Document Date'].date).format('MM/DD/YYYY'),
                }}));
                this.custom_cut_dates = res.custom;
                this.custom_cut_dates = this.custom_cut_dates.map(cut_date=>({...cut_date, ...{
                    'Document Date': this.$root.moment(cut_date['Document Date'].date).format('MM/DD/YYYY'),
                    'Modify Date': this.$root.moment(cut_date['Modify Date'].date).format('MM/DD/YYYY'),
                    'Menu Cut Day': Number(cut_date['Menu Cut Day']).toFixed(0).toString(),
                    'Add Date': this.$root.moment(cut_date['Add Date'].date).format('MM/DD/YYYY'),
                }}));

                this.default_cut_datesBackup = res.default;
                this.custom_cut_datesBackup = res.custom;

                this.custom_cut_datesBackup = this.custom_cut_datesBackup.map(cut_date=>({...cut_date, ...{
                    'Document Date': this.$root.moment(cut_date['Document Date'].date).format('MM/DD/YYYY'),
                }}));

                resolve();
            });
        },
        btoa(str){
            return window.btoa(str).replaceAll('=','');
        },
        async deleteItem(e,cut_date){
            let result;
            try{
                result = await VueSimpleAlert.confirm(`Are you sure you want to delete the add cut for ${cut_date['Document Date']}?`, `Delete Date`);
            } catch(err){
                result = false;
            }
            if(!result) return;
            this.ready = false;

            let req, res;
            res = await axios.delete(`${this.$store.state.api}/orderaddcut?func=DELETE&document_date=${cut_date['Document Date']}&dow=${cut_date['DOW']}`);
            
            await this.getCutTimes();
            this.ready = true;
            await Sleep(10);
            await this.setSelectors();
        },
        async addCutTime(cut_date){
            this.ready = false;

            let req, res;
            let formData = new FormData();
            formData.append('cut_date', JSON.stringify(this.create_cut_day));
            res = await axios.post(`${this.$store.state.api}/orderaddcut?method=INSERT&token=${localStorage.token}`, formData);
            await this.getCutTimes();
            this.disabled = false;
            this.ready = true;
            await Sleep(10);
            await this.setSelectors();

        },  
        Save:_.throttle(async function(cut_date, oldDate = null, oldDOW= null, method='UPDATE'){
            let req, res;
            let formData = new FormData();
            cut_date = {...cut_date, ...{'Modify Date':this.$root.moment().format('MM/DD/YYYY'), 'Modify Time':this.$root.moment().format('HH:mm:ss'), 'Modify User': this.$store.state.localStorage.user['User Code']}};

            delete cut_date.editable;
            formData.append('cut_date',  JSON.stringify(cut_date));
            res = await axios.post(`${this.$store.state.api}/orderaddcut?${(oldDate!=null?`date=${oldDate}&method=${method}`:`&method=${method}`)}&${(oldDOW!=null?`dow=${oldDOW}`:'')}`,formData);
            this.default_cut_dates.forEach((cut_time,i)=>{
                this.default_cut_dates[i].editable = false;
            });
            if(method!='UPDATE'){
                this.ready = false;

                await this.getCutTimes();
                this.ready = true;

                await Sleep(10);
                await this.setSelectors();
            }
            

        }, 350, {leading:true, trailing:true}),
    },
  }
  </script>
  <style lang="scss" scoped>
    // @media (max-width: 768px){
    //     [data-title="Default"]{
    //         .table-body-parent{
    //             table.table{
    //                 // border: 5px solid red !important
    //                 display:block;
    //                 > tbody{
    //                     display:block;
    //                     tr{
    //                         display:block;
    //                         margin-bottom: 1rem;
    //                         td{
    //                             display:block;
    //                             width: 100%;
    //                             &:nth-of-type(1){
    //                                 &:before{
    //                                     content:  'Day of Week: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(2){
    //                                 &:before{
    //                                     content:  'Menu Cut Off: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(3){
    //                                 &:before{
    //                                     content:  'Menu Cut Time: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:last-child{
    //                                 .btn{
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     [data-title="Overrides"]{
    //         .table-body-parent{
    //             table.table{
    //                 // border: 5px solid red !important
    //                 display:block;
    //                 > tbody{
    //                     display:block;
    //                     tr{
    //                         display:block;
    //                         margin-bottom: 1rem;
    //                         position:relative;
    //                         td{
    //                             display:block;
    //                             width: 100%;
    //                             &:nth-of-type(1){
    //                                 &:before{
    //                                     content:  'Order Date: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(2){
    //                                 &:before{
    //                                     content:  'Day of Wk: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(3){
    //                                 &:before{
    //                                     content:  'Menu Cut Off: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(4){
    //                                 &:before{
    //                                     content:  'Menu Cut Time: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:last-child{
    //                                 position: absolute;
    //                                 right: 0;
    //                                 top: 0;
    //                                 width: auto;
    //                                 background-color: var(--bs-danger);
    //                                 color:#fff !important;
    //                                 border-radius: 50%;
    //                                 width: 25px !important;
    //                                 height: 25px !important;
    //                                 text-align:center;
    //                                 line-height: 25px;
    //                                 padding: 0 !important;
    //                                 // transform: translateX(10px) translateY(10px);
    //                                 a{
    //                                     padding: 0 !important;
    //                                     margin: 0 !important;
    //                                     line-height: 1;
    //                                 }
    //                                 i{
    //                                     color:#fff !important;
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     [data-title="AddCutDate"]{
    //         .table-body-parent{
    //             table.table{
    //                 // border: 5px solid red !important
    //                 display:block;
    //                 > tbody{
    //                     display:block;
    //                     tr{
    //                         display:block;
    //                         margin-bottom: 1rem;
    //                         td{
    //                             display:block;
    //                             width: 100%;
    //                             &:nth-of-type(1){
    //                                 &:before{
    //                                     content:  'Order Date: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(2){
    //                                 &:before{
    //                                     content:  'Day of Wk: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(3){
    //                                 &:before{
    //                                     content:  'Menu Cut Off: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:nth-of-type(4){
    //                                 &:before{
    //                                     content:  'Menu Cut Time: ';
    //                                     font-weight:bold;
    //                                 }
    //                             }
    //                             &:last-child{
    //                                 .btn{
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
        
    // }
  </style>