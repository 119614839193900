<template>
    <Table :style="`max-width: 460px; overflow:hidden !important;`" :classes="`min-w-auto text-center`">
        <thead>
            <TableRow>
                <th class="text-white" style="background-color: var(--bs-gray-700);"><b>Total Pieces / Est. Trays</b></th>
                <th class="text-white" style="background-color: var(--bs-gray-700);" v-if="$store.state.localStorage.user['Order_Vis_Extended']&&$store.state.localStorage.user['Admin']"><b>Total Extended Cost</b></th>
            </TableRow>
        </thead>
        <tbody>
            <TableRow>
                <td class=" input-group-sm"><b>{{ calculatedItemQuantities.qty }} / {{ calculatedItemQuantities.trays }}</b></td>
                <td class=" input-group-sm" v-if="$store.state.localStorage.user['Order_Vis_Extended']&&$store.state.localStorage.user['Admin']"><b>{{ extendedCost.toFixed(2) }}</b></td>
            </TableRow>
        </tbody>
    </Table>

    <div class="mt-3 mb-3">
        <button class="btn btn-success ms-1 d-block d-lg-inline-block" tabindex="0" @click.prevent.stop="$router.back" v-if="!header.ll_inprod">Done</button>
    </div>
</template>
  <script>
    import Header from '@/components/Orders/Header.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import TableHead from '@/components/Table/TableHead.vue';
    import TableCell from '@/components/Table/TableCell.vue';
  export default {
    name: 'History',
    components: {PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead, Header},
    props: ['items','header', 'itemListCodes'],
    watch:{
        'items':{
            deep:true,
            handler(newVal){
                let items = [...(newVal.filter(i=>+i.qty > 0))];
                items = items.map(item=>{
                    item.perc_tray = (+item.qty / +item.tray);
                    return item;
                })
                this.calculatedItemQuantities.qty = _.sumBy(items, i=>+i.qty);
                this.calculatedItemQuantities.trays = _.sum(_.map(items, i=>(+i.qty/+i.tray))).toFixed(1);
                this.extendedCost = _.sumBy(items, i=>+i.EXTENDED);
                
            }
        }
    },
    computed:{
    },
    data(){
        return {
          _:_,
          calculatedItemQuantities:{qty:0,trays:0},
          extendedCost:0 
        }
    },
    async mounted(){
        let items = (this.items.filter(i=>+i.qty > 0))
        this.calculatedItemQuantities.qty = _.sumBy(items, i=>+i.qty);
        this.calculatedItemQuantities.trays = _.sum(_.map(items, i=>(+i.qty/+i.tray))).toFixed(1);
        this.extendedCost = _.sumBy(items, i=>+i.EXTENDED);
    },
    methods:{
     
    },
  }
  </script>
  <style scoped lang="scss">
   
  </style>
  