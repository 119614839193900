<template>
    <PageContent>
        <div class="text-muted text-center fs-2 mt-5 mb-5">Page Not Found</div>
    </PageContent>
    
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  export default {
    name: 'NotFound',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            ready:false,
            disabled:false,
        }
    },
    async mounted(){
        this.ready = true;
    },
    methods:{
       
    },
  }
  </script>
  <style lang="scss" scoped>
    
  </style>