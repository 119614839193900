<template>
    <div>
        <h4 v-if="$store.state.localStorage.customer==undefined&&$store.state.sessionStorage.customer==undefined">Admin</h4>
        <div v-else>
            <div class="text-start"><b>No:</b> {{$root.getCustomerValue('No_')}}</div>
            <div class="text-start"><b>Name:</b> {{$root.getCustomerValue('Name')}}</div>
            <div class="text-start"><b>City:</b> {{$root.getCustomerValue('City')}}</div>
            <div class="w-100 text-start position-relative"><b>Account Status:</b> <span v-html="$store.state.account_status"></span></div>
            <hr v-if="Object.values($store.state.shipTos)">
            <div v-if="Object.values($store.state.shipTos)" class="text-start mt-2">
                <label><b>Ship-To</b></label>
                <select class="form-control" v-model="$store.state.shipTo" @change="goToShipTo">
                    <option value="">ALL SHIP-TOS</option>
                    <option value="LOCAL">{{ $root.getCustomerValue('City')}}</option>
                    <option v-for="shipTo in $store.state.shipTos">{{ shipTo['ship-to code'] }}</option>
                </select>
            </div>
        </div>
    </div>
    
  </template>
  
  <script>
  export default {
    name: 'About',
    components:{},
    props: {
    },
    watch:{
    },
    data(){
        return {
            customer:{Name:'',city:''},
            account_status:'Good',
            shipTos:[],
        }
    },
    async mounted(){},
    methods:{
        goToShipTo(){
            localStorage.setItem('shipTo', this.$store.state.shipTo);
            this.$router.go(0);
        }
    },
  }
  </script>