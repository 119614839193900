<template>
    <PageContent classes="p-5">
        <PageTitle>Enterprise Ordering</PageTitle>
        <div class="text-center" v-if="$root.getCustomerValue('No_')=='125600'">
            <router-link to="/enterprise-upload" class=" ms-1 me-1 btn btn-primary">CSV</router-link>
            <router-link to="/enterprise-upload-xml-family-express" class=" ms-1 me-1 btn btn-light">XML</router-link>
            <hr>
        </div>
        <div class="alert alert-danger" v-if="error" v-html="error"></div>
        <div v-if="!ordersDone">
            <div v-if="!Object.keys(csvData).length">
                <a :href="`${$store.state.api}/csvs/Enterprise-Order-File-csv.csv`" class="btn btn-primary">Download Export File Template</a>
                <br><br>
                <p>CSV format: Date, Location Number, Item Number, Quantity, Used on Day</p>
                <table class="table table-striped  table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Date<br><i class="text-muted fw-normal"><small>required</small></i></th>
                            <th>Location Number<br><i class="text-muted fw-normal"><small>required</small></i></th>
                            <th>Item Number<br><i class="text-muted fw-normal"><small>required</small></i></th>
                            <th>Quantity<br><i class="text-muted fw-normal"><small>required</small></i></th>
                            <th>Used on Day<br><i class="text-muted fw-normal"><small>optional</small></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            <td>MM/DD/YYYY</td>
                            <td>000001</td>
                            <td>420</td>
                            <td>55</td>
                            <td>Monday</td>
                        </tr>
                        <tr>
                            <td>MM/DD/YYYY</td>
                            <td>000001</td>
                            <td>422</td>
                            <td>20</td>
                            <td>Tuesday</td>
                        </tr>
                        <tr>
                            <td>MM/DD/YYYY</td>
                            <td>000001</td>
                            <td>425</td>
                            <td>40</td>
                            <td>Wednesday</td>
                        </tr>
                        <tr>
                            <td>MM/DD/YYYY</td>
                            <td>000002</td>
                            <td>420</td>
                            <td>55</td>
                            <td>Thursday</td>
                        </tr>
                        <tr>
                            <td>MM/DD/YYYY</td>
                            <td>000002</td>
                            <td>422</td>
                            <td>20</td>
                            <td>Friday</td>
                        </tr>
                    </tbody>
                </table>
                <form>
                    <div class="form-group">
                        <label for="">Upload CSV</label>
                        <input type="file" class="form-control" @change.prevent.stop="uploadFile" accept=".csv">
                    </div>
                </form>
            </div>
            <div v-else>
                <!-- {{ csvData }} -->
                <h5>Order Confirmation</h5>
                <div class="alert alert-info">Please look over the following information to verify your order.</div>
                <div class="alert alert-info" v-if="startIdx>0&&maxIdx>0">{{ (startIdx) }} out of {{ maxIdx }} orders processed</div>
                <div v-for="(row,key, index) in csvData" :class="`order-review-table border p-2 mb-3`" :data-order-day="`${key}`" :id="`date_${btoa(key).replace('==','')}`">
                    <!-- <div class="mb-2 text-center fs-5"><strong></div> -->
                    <div v-for="(order, day,index2) in row">
                        <div class="mt-2 mb-1 bg-dark text-white p-1"><span>Order #{{ (index2+1) }}</span> <span class="float-end">{{ key }}</span></div>
                        <div class="mb-2" v-for="(items, location) in order"  :data-order-day-location="`${key}-${day}-${location}`" :id="`date_${btoa(key).replace('==','')}_${location}`">
                            <div class="mb-1"><strong>Location:</strong> {{ location }}</div>
                            <table class="table table-sm table-bordered table-striped mb-2">
                                <thead>
                                    <tr>
                                        <th>Item Number</th>
                                        <th>Quantity</th>
                                        <th>Used on Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    <tr v-for="item in items">
                                        <td>{{ item['Item Number'] }}</td>
                                        <td>{{ item['Quantity'] }}</td>
                                        <td>
                                            <span v-if="item['Used on Day']==''">{{ $root.moment.moment(item['Used on Day']).format('dddd') }}</span>
                                            <span v-else>{{ item['Used on Day'] }}</span>
                                        </td>
                                    </tr>
                                
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="alert alert-info" v-if="startIdx>0&&maxIdx>0">{{ (startIdx) }} out of {{ maxIdx }} orders processed</div>
                <br>
                <button  class="btn btn-primary"  @click.prevent.stop="sendOrdersByDate" id="confirmButton">CONFIRM</button>
            </div>
        </div>
        <div v-else>
            <!-- {{ ordersResponse }} -->
            <div v-for="order in ordersResponse" class="mb-4 p-3 border bg-white shadow">
                <!-- {{ order.response.invalid_dates[order.date] }} -->
                <h2 class="fs-5"><b>{{ order.date }}</b></h2>
                <hr />
                
                <div v-if="order.response.invalid_dates.hasOwnProperty(order.date)">
                    <div class="alert alert-info">{{ order.response.invalid_dates[order.date] }}</div>
                </div>
                <div v-else>
                    
                    <h3 class="fs-6"><b>Order(s)</b></h3>
                    <div v-if="Object.keys(order.response.locations_items_ordered).length">
                        <div v-for="(items, location) in order.response.locations_items_ordered">
                            <h4 class="fs-6 mb-0 " v-if="items.length"><b>Used on Day:</b> {{ $root.moment().day(items[0].used_on_day).format('dddd') }}</h4>
                            <h4 class="fs-6" ><b>Location</b>:{{ location }}<br><small><b>Ordered Location Items</b>:</small></h4>
                            <table class="table table-sm table-bordered table-striped" style="font-size: 0.85rem;">
                                <thead>
                                    <tr>
                                        <th style="padding: 2px 4px; width: 100px;">Item Number</th>
                                        <th style="padding: 2px 4px;">Item Desc</th>
                                        <th style="padding: 2px 4px;">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items">
                                        <td style="padding: 2px 4px;">{{ item.item }}</td>
                                        <td style="padding: 2px 4px;" v-html="item.desc"></td>
                                        <td style="padding: 2px 4px;">{{ item.qty }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="text-danger" v-for="(msg,location) in order.response.locations_cant_order" v-if="Object.keys(order.response.locations_cant_order).length"><b>{{ location }}</b>: {{ msg }}</div>

                    <div v-if="Object.keys(order.response.locations_items_cant_order).length" class=" mt-2 mb-2">
                        <h3 class="fs-6"><b>Invalid Item(s)</b></h3>
                        <div class="text-danger" v-for="(items,location) in order.response.locations_items_cant_order" >
                            <b>{{ location }}</b>: 
                            <div v-for="item in items"> - {{ item.item }}</div>
                        </div>
                    </div>

                    <div v-if="order.response.invalid_locations.length" class="mt-2 mb-2">
                        <h3 class="fs-6"><b>Invalid Location(s)</b></h3>
                        <div class="text-danger" v-for="location in order.response.invalid_locations"> - {{ location }}</div>
                    </div>
                </div>
                

            </div>
            <hr />
        </div>
    </PageContent>
    
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import VueSimpleAlert from "vue3-simple-alert";
import axios from 'axios';
import store from '@/store';
  export default {
    name: 'UploadCSV',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            // UserForm, CustomerForm, UserForm
            disabled:false,
            ready:false,
            error:'',
            headerError:'',
            csvData:[],
            ordersDone:false,
            ordersResponse:[],
            startIdx:0,
            maxIdx:0,
            days:['Monday','Tuesday','Wendesday','Thursday','Friday']
        }
    },
    async mounted(){
    },
    methods:{
        btoa:function(string){
            return btoa(string);
        },
        async uploadFile(e){
            this.error = '';
            let file = e.target.files[0];
            let fileType = file.fileType;
            if(file.type!='text/csv'){
                this.error  = 'A CSV file is required';
                return;
            }
            let reader = new FileReader();
            reader.onload = e=>{
                let delimiter = (this.detectDelimiter(e.target.result))
                if(delimiter != ','){
                    this.error = 'Comma Delimiter is required';
                    return;
                }
                this.csvData = [];
                let date = '';
                let location='';
                let array_keys = [];
                this.error = '';
                Papa.parse(e.target.result, {
                    worker:true,
                    header:true,
                    step:(results)=>{
                        if(this.error.length==0){
                            if(results.meta.fields.indexOf('Date')==-1) this.error += '<div>Date column header is required</div>';
                            if(results.meta.fields.indexOf('Location Number')==-1) this.error += '<div>Location Number column header is required</div>';
                            if(results.meta.fields.indexOf('Item Number')==-1) this.error += '<div>Item Number header is required</div>';
                            if(results.meta.fields.indexOf('Quantity')==-1) this.error += '<div>Quantity header is required</div>';

                            if(this.error.length==0){
                                if(results.data['Date'].trim()!='') date = results.data['Date'];
                                
                                if(this.isDateValid(date)){
                                    results.data['Date'] = date;
                                    if(results.data['Date'].trim()!=''){
                                        if(results.data['Location Number']!='') location = _.padStart(results.data['Location Number'],6,'0');
                                        results.data['Location Number'] = location;
                                    }
                                    else{
                                    }
                                    this.csvData.push(results);
                                }
                            }
                            else document.querySelector('input[type="file"]').value='';

                        }
                    },
                    complete:(results)=>{
                        this.csvData = _.map(this.csvData, d=>d.data);
                        // console.log(this.csvData);
                        this.buildOrder();
                    }
                });
            }
            reader.readAsText(file);
        },
        async buildOrder(){
            this.csvData = this.csvData.filter(d=>d['Location Number'].trim()!='000000'&&d['Item Number']!=undefined&&d['Item Number'].trim()!=''&&d['Quantity']!=undefined&&d['Quantity'].trim()!='');
            this.csvData = this.csvData.map(data=>{
                if(data.hasOwnProperty('Used on Day')&&data['Used on Day']=='') data['Used on Day'] = moment(data['Date']).format('dddd');
                else if(data.hasOwnProperty('Used on Day')&&data['Used on Day']!='')  data['Used on Day'] = moment().day(data['Used on Day']).format('dddd');
                else data['Used on Day']= moment(data['Date']).format('dddd')
                return data;
            });

            this.csvData = _.groupBy(_.orderBy(this.csvData, ['Date','Location Number','Item Number'],['asc','asc','asc']), 'Date');
            for(let day in this.csvData){
                for(let item of this.csvData[day]){
                    let result;
                    if(+item.Quantity>300){
                        try{
                            result = await VueSimpleAlert.confirm(`Are you sure you want to order more than a quantity of 300 for item number ${item['Item Number']} for location ${item['Location Number']} on ${item['Date']}?`, `Quantity Alert`);
                        }
                        catch(err){
                            result = false;
                        }
                    }
                    else result = true;

                    if(!result){
                        this.csvData[day].splice(this.csvData[day].findIndex(i=>i['Item Number']==item['Item Number']), 1);
                    }
                    else{
                        //check for duplciate item here
                        // console.log(this.csvData[day])
                        // this.error = 'test';
                    }
                }
            }
            for(let date in this.csvData){
                this.csvData[date] = _.groupBy(this.csvData[date], 'Used on Day');
                for(let usedOnDay in this.csvData[date]){

                    this.csvData[date][usedOnDay] = _.groupBy(this.csvData[date][usedOnDay], 'Location Number');
                    // console.log(this.csvData[date][usedOnDay])
                    // for(let location_no of Object.keys(this.csvData[date][usedOnDay])){
                    //     // console.log()
                    //     let groupedItems = (_.groupBy(this.csvData[date][usedOnDay][location_no],'Item Number'));
                    //     let itemsWithMoreThanOne = _.filter(groupedItems, g=>g.length>1);
                    //     // console.log(_.flatten(_.values(groupedItems)))
                    //     // console.log(itemsWithMoreThanOne[0][0]['Item Number'])
                        
                    //     await VueSimpleAlert.alert(`Item Number: ${itemsWithMoreThanOne[0][0]['Item Number']}, Location: ${location_no}, Date: ${date}`,`Duplicate Item(s)`);
                    //     this.csvData[date][usedOnDay][location_no] = (_.uniqBy(this.csvData[date][usedOnDay][location_no], 'Item Number'));
                    // }

                }   
            }
        },
        
        async sendOrdersByDate(){
            let req, res;
            if($('#confirmButton').length) $('#confirmButton').remove();
            this.maxIdx = ((_.flatten(_.flatten(_.map(_.values(this.csvData), c=>_.map(_.values(c), l=>_.values(l))))))).length;
            for(let date in this.csvData){
                let dayOrder = this.csvData[date];
                for(let usedOnDay in dayOrder){
                    let usedOnDayOrder = dayOrder[usedOnDay];
                    for(let location in usedOnDayOrder){
                        let items = usedOnDayOrder[location];
                       
                        let $orderContainer = $(`.order-review-table [data-order-day-location="${date}-${usedOnDay}-${location}"]`);
                        
                        // document.querySelector(`#main-app-container > .row > [class*=col]:nth-of-type(2) [data-order-day-location="${date}-${location}"]`).scrollIntoView({behavior:'smooth'});
                        $orderContainer.addClass('disabled');
                        $orderContainer.append(`<div class="loading-box"><div class="mb-3">Uploading Order for <b>${date}</b> Location: <b>${location}</b></div><div class="ms-3 spinner-border fs-5" role="status"><span class="visually-hidden">Loading...</span></div></div>`);
                        
                        // console.log(JSON.stringify({[location]:usedOnDayOrder[location].map(i=>({qty:i['Quantity'],item:i['Item Number']}))}));
                        // return;
                        let formData = new FormData();
                        formData.append('locations', JSON.stringify({[location]:usedOnDayOrder[location].map(i=>({qty:i['Quantity'],item:i['Item Number']}))}));
                    
                        req = await fetch(`${this.$store.state.api}/enterprise_order?date=${date}&custno=${this.$root.getCustomerNumber()}&lcusedonday=${usedOnDay}`, {
                            body:formData, method:'POST', headers:{
                                Authorization:`Basic ${btoa(`${this.$store.state.localStorage.user.APIToken}:${this.$store.state.localStorage.user.APISecret}`)}`
                            }
                        });
                        this.startIdx += 1;
                        res = await req.json();
                        
                        $orderContainer.find('.loading-box').html(`<div class="mb-3">Order for <b>${date}</b> Location <b>${location}<b> Complete!</div><div><i class="text-primary fs-3 fa-solid fa-check"></i></div>`);
                        this.ordersResponse.push({date:date, response:res});
                        
                    }
                }
                
            }
            this.ordersDone = true;
            req = await fetch(`${this.$store.state.api}/send_enterprise_order_confirmation_email?custno=${this.$root.getCustomerNumber()}`, {method:'POST', headers:{
                    Authorization:`Basic ${btoa(`${this.$store.state.localStorage.user.APIToken}:${this.$store.state.localStorage.user.APISecret}`)}`
                }
            });
            res = await req.text();
            document.querySelector(`#main-app-container > .row > [class*=col]:nth-of-type(2)`).scrollTo(0,0);
        },
        detectDelimiter(csvData) {
            // Split the first row of the CSV data to check for different delimiters
            const firstRow = csvData.split(/\r\n|\n/)[0];
            const delimiters = [',', ';', '\t']; // Add other possible delimiters if needed

            // Count the occurrences of each delimiter in the first row
            const delimiterCounts = delimiters.map(delimiter => ({
                delimiter,
                count: (firstRow.match(new RegExp(delimiter, 'g')) || []).length
            }));

            // Sort the delimiters by their occurrence count in descending order
            delimiterCounts.sort((a, b) => b.count - a.count);

            // Return the most likely delimiter (comma if a tie or no other delimiter found)
            return delimiterCounts.length > 0 ? delimiterCounts[0].delimiter : ',';
        },
        isDateValid(date){
            let parsedDate = moment(date, 'MM/DD/YYYY').isValid()||moment(date, 'M/D/YYYY').isValid()||moment(date, 'M/DD/YYYY').isValid();
            return parsedDate;
        }
    },
  }
  </script>
  <style lang="scss" >
    .order-review-table{
        .disabled{
            position:relative;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display:block;
                z-index:10;
                background-color:rgb(#fff, 0.75);
            }
            
        }
        [data-order-day-location]{
            position:relative;
            min-height: 220px;
        }
        .loading-box{
                background-color:var(--bs-white);
                display:block;
                z-index:11;
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translateX(-50%)  translateY(20%);
                box-shadow: 0 0 5px rgba(#000,0.75);
                border-radius: 8px;
                padding: 1rem;
                text-align:center;
            }
    }
  </style>