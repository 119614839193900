<template>
    <PageContent>
        <PageTitle>
            Change Password
            <Help />
        </PageTitle>
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-12 mt-5">
                <div class="alert alert-danger text-start" v-if="error">{{error}}</div>
                <div class="alert alert-success text-start" v-if="success">{{success}}</div>
                <form method="post"  @submit.prevent="savepasswordform" class="text-start">
                    <fieldset :disabled="disabled">
                        <div class="form-group" data-intro="Input current password" data-title="Current Password">
                            <label>Current Password:</label>
                            <input autocomplete="off" autosuggest="off" type="password" name="password" v-model="password" class="form-control">
                        </div>
                        <div class="form-group"  data-intro="Create new password" data-title="New Password">
                            <label>New Password:</label>
                            <input autocomplete="off" autosuggest="off" type="password" name="txtNewPassword" v-model="txtNewPassword" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>New Confirmed:</label>
                            <input autocomplete="off" autosuggest="off" type="password"  name="txtNewPasswordConfirmed" v-model="txtNewPasswordConfirmed" class="form-control"  data-intro="Verify new password" data-title="Verify Password">
                        </div>
                        <div class=" text-start mt-3">
                            <button type="submit" class="btn btn-primary text-uppercase me-2">Save</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </PageContent>
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import Help from '@/components/Help.vue';
import axios from 'axios';

  export default {
    name: 'Password',
    components: {PageContent, PageTitle,  NotReady, Help},
    data(){
        return {
            ready:false,
            disabled:false,
            success:``,
            error:``,
            password:'',
            txtNewPassword:'',
            txtNewPasswordConfirmed:'',
        }
    },
    async mounted(){
    },
    methods:{
        async savepasswordform(){
            let req, res;
            this.error = '';
            let formData = new FormData();
            formData.append('Id', this.$store.state.localStorage.user.Id);
            formData.append('password', this.password);
            formData.append('txtNewPassword', this.txtNewPassword);
            formData.append('txtNewPasswordConfirmed', this.txtNewPasswordConfirmed);
            
                if(this.$root._.isEmpty(this.txtNewPassword)) this.error = `New Password can not be blank`;
                else if(this.txtNewPassword!=this.txtNewPasswordConfirmed){
                    this.error = ``;
                    this.$wkToast(`New password and confirmed password does not match.`, {
                        className: ['alert', 'alert-danger', 'bg-danger'],
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        duration: 3000,
                        transition: 'slide-right'
                    });
                }
                else{
                    res = await axios.post(`${this.$store.state.api}/save_user`, formData);
                    if(res.message!='error'){
                        localStorage.token = res.token;
                        this.password = '';
                        this.txtNewPassword = '';
                        this.txtNewPasswordConfirmed = '';
                    }
                }
        }
    },
  }
  </script>
  <style lang="scss" scoped>
  </style>