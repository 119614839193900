<template>
    <PageContent>
        <PageTitle>Open Invoices</PageTitle>
        <NotReady v-if="!ready" />
        <div v-else>
            <h4 class="text-start" @test="test">Account Status: {{account_status}}</h4>
            <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                <thead>
                    <tr>
                        <th colspan="6" class="text-white" style="background-color: var(--bs-gray-800); width: 100%;">Open Invoices</th>
                    </tr>
                    <tr>
                        <th class="text-white" style="background-color: var(--bs-gray-700); width:calc(100% / 6);">Due Date</th>
                        <th class="text-white" style="background-color: var(--bs-gray-700); width:calc(100% / 6);">Invoice Date</th>
                        <th class="text-white" style="background-color: var(--bs-gray-700); width:calc(100% / 6);">Document No.</th>
                        <th class="text-white" style="background-color: var(--bs-gray-700); width:calc(100% / 6);">Amount</th>
                        <th class="text-white" style="background-color: var(--bs-gray-700); width:calc(100% / 6);">Balance</th>
                        <th class="text-white" style="background-color: var(--bs-gray-700); width:calc(100% / 6);">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(invoice,i) in open_invoices" :key="i">
                        <td>{{$root.moment(invoice['due date'].date).format('MM/DD/YYYY')}}</td>
                        <td>{{$root.moment(invoice['posting date'].date).format('MM/DD/YYYY')}}</td>
                        <td>{{(invoice['document no_'])}}</td>
                        <td>${{parseFloat(invoice['sales (lcy)']).toFixed(2)}}</td>
                        <td>${{parseFloat(invoice['balance']).toFixed(2)}}</td>
                        <td>{{($root.moment(invoice['due date'].date).isAfter($root.moment())?'Current':'Past Due')}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </PageContent>
  </template>
  
  <script>
    import axios from 'axios';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import TableHead from '@/components/Table/TableHead.vue';
    import TableCell from '@/components/Table/TableCell.vue';

  export default {
    name: 'ReportsOpenInvoices',
    components: {PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead},
    data(){
        return {
            ready:false,
            open_invoices:[],
            account_status:'Good'
        }
    },
    async mounted(){
        let res = await axios.get(`${this.$store.state.api}/open_invoices?lccustno=${this.$root.getCustomerNumber()}`);
        this.open_invoices = res;
        if(this.open_invoices.length) this.account_status = 'Past Due';
        this.ready = true;
    },
    methods:{
    },
  }
  </script>
  