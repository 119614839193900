<template>
    <aside class="sidebar bg-white shadow  rounded p-4 d-none d-lg-block ">
        <div class="text-center"><img id="logo" alt="Kitchen Fresh Foods" src="../assets/kf-foods-logo.png" class="mw-100" /></div>
        <hr class="d-none d-lg-block">
        <div class="">
            <div class="text-start"><b>Logged in as:</b> {{ $store.state.localStorage.user['User Code'] }}</div>
            <hr class="mt-4 mb-4">
            <About><template scope="{formData}" /></About>
            <hr class="mt-4 mb-4">
            <Links />
            <span class="position-relative d-inline-block"><Help :target="`aside`" /></span>
            
        </div>
    </aside>
  </template>
  
  <script>
  import About from '@/components/SideBar/About.vue'
  import Links from '@/components/SideBar/Links.vue'
  import Help from '@/components/Help.vue'

  export default {
    name: 'SideBar',
    components:{About,Links,Help},
    props: {
    },
    watch:{
    },
    data(){
        return {
            formData:{},
        }
    },
    async mounted(){
        this.$root.ee.once('changeCustomer', async data=>{
            

            this.customer = await this.getUserOrCustomer();
            let open_invoices = await this.getInvoices();
            await this.$root.determinAccountStatus(open_invoices);
        });
        
    },
    methods:{
        
    },
  }
  </script>
  <style scoped lang="scss">
    aside.sidebar{
        overflow-y:scroll;
        #logo{
            width: 175px;
            height: 175px;
            object-fit:scale-down;
            object-position: 50% 50%;
        }
        #menu-toggler{
            cursor:pointer;
            > div{
                display:flex;
                align-items:center;
                > div{
                    width: 50%;
                    flex-basis:50%;
                    line-height: 1;
                    &:nth-of-type(1){
                        text-align:left;
                    }
                    &:nth-of-type(2){
                        text-align:right;
                    }
                }
            }
        }
        svg{
            width: 30px;
            height: 30px;
            path{
                // fill:#3A3B80;
                fill:#000;
            }
        }
        @media (max-width: 992px){
            nav{
                display:none;
            }
        }
        @media (min-width: 992px){
            display:block !important;
        }
    }
    .table-wrapper{
        width: 100%;
        overflow-x:scroll;
        overflow-y:scroll;
        height:650px;
        table{
            *{
            }
        }
    }
    
  </style>
  