<template>
    <!-- <slot name="modal-trigger"></slot>
    <teleport to="body" :disabled="!data.open">
        <div class="modal fade modal-lg" :id="`editItem${data['Item No_']}`">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <slot name="modal-header"></slot>
                    </div>
                    <div class="modal-body text-start">
                        <slot name="modal-body"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="modal-footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport> -->
    <div class="">
        <button v-if="tag=='button'||tag==undefined" :class="`btn btn-${color} btn-${size}`" data-toggle="modal" :data-target="`#${modalTarget}`" @click.prevent.stop="openModal(modalTarget)" v-html="buttonText"></button>
        <a v-if="tag=='a'" :class="`${classes}`" data-toggle="modal" href="#" :data-target="`#${modalTarget}`" @click.prevent.stop="openModal(modalTarget)" v-html="buttonText"></a>
        <teleport to="body" :disabled="!open">
            <slot></slot>
        </teleport>
    </div>
</template>
  
  <script>
  export default {
    name: 'Modal',
    props:['modalTarget','buttonText', 'size','color','data', 'tag', 'classes'],
    data(){
        return {
            open:false
        }
    },
    emits:{
        closeOpen:null,
        confirm:null
    },
    watch:{
        // 'data.open':function(val){
        //     let $modal = $(`#editItem${this.data['Item No_']}`);
        //     $modal.modal({backdrop: 'static', keyboard: false, show:true,});
        //     $modal.on('hidden.bs.modal', (e)=>{
        //             // this.open = false;
        //             this.$emit('closeOpen');
        //             e.stopPropagation();
        //         })
        //     if(val){
        //         $modal.modal('show');
        //     }
        //     else{
                
        //     }
        // }
    },
    mounted(){

        // if(this.tag==undefined) this.tag = 'button';
    },
    methods:{
        async openModal(target){
            this.open = true;
            let $modal = $(`#${target}`);
            $modal.modal({backdrop: 'static', keyboard: false, show:true,});
            $modal.modal('show');

            $modal.on('hidden.bs.modal', (e)=>{
                this.open = false;
                e.stopPropagation();
            })
            $modal.find('#confirm').on('click', ()=>{
                this.$emit('confirm');
            });
            await Sleep(10);

            // $( ".datepicker" ).datepicker();
            // $( ".timepicker" ).timepicker({
            //     timeFormat:'H:i',
            //     dynamic:false,
            // });
            // $( ".timepicker" ).on('change', (e)=>{
            // })
            
        },
    },
  }
  </script>
  
  <style scoped lang="scss">
  
  </style>
  